import React, { useContext, useState } from "react";

import { Typography } from "@mui/material";
import {
  Container,
  RowBox,
  ProgressIndicator,
  CountryPick,
} from "../components/ui";


//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import { BlockedUserTable } from "../components/tables";
import { useAlert } from "../components/alert";

export default function BlockedUserList(props) {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [country, setCountry] = useState();
  const [suspendState, setSuspendState] = useState();
  const alert = useAlert();

  const queryClient = useQueryClient();
  const { fetchBlockReport, suspendUser } = useContext(APIContext);
  const { isLoading, data } = useQuery(
    ["blockReport", { page, country, sort }],
    () => fetchBlockReport(page, country, sort),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const suspendMutation = useMutation(
    ({ userId, status }) => suspendUser(userId, status),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["blockReport", { page }]);
        toast.success("Successfully saved changes");
      },
    }
  );

  const suspendHandler = (user, status) => {
    alert(
      "Confirmation",
      `Are you sure to ${status ? "suspend" : "unsuspend"} user ${
        user.fullname
      } ?`,
      () => {
        suspendMutation.mutate({
          userId: user?.id,
          status: suspendState,
        });
      }
    );
  };

  return (
    <Container sx={{ p: 2 }}>
      <RowBox sx={{ mb: 2 }}>
        <Typography variant="h4Black">{`Total Blocked Users: ${
          data?.totalResults || 0
        }`}</Typography>
        <CountryPick
          value={country}
          onChange={(c) => {
            setCountry(c);
          }}
          fullWidth={false}
          sx={{ minWidth: 150, ml: 4, mr: 1 }}
        />
        <Typography
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => setCountry(null)}
        >
          Clear Filters
        </Typography>
      </RowBox>
      <BlockedUserTable
        data={data}
        page={page}
        onSuspend={suspendHandler}
        onPageChange={setPage}
        onSort={(s) => {
          setSort(s);
          setPage(0);
        }}
      />
      {(isLoading || suspendMutation.isLoading) && <ProgressIndicator />}
    </Container>
  );
}
