import React, { useContext, useState } from "react";
import {
  Button,
  IconButton,
  Typography,
  Box,
  Grid,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router";

//API related imports
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { groupSchema } from "../utils/validation";

import {
  RowBox,
  ColBox,
  FileButton,
  ProgressIndicator,
  Container,
  ImageBox,
  PageTitle,
  InputField,
  SelectController,
} from "../components/ui";
import { ProfileImage } from "../components/app-ui";
import { useCrop } from "../components/alert";

export default function CreateGroup() {
  const [profileImg, setProfileImg] = useState();
  const cropImage = useCrop();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { createGroup } = useContext(APIContext);

  const createMutation = useMutation((data) => createGroup(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["group"]);
      toast.success("Successfully created group");
      navigate(-1);
    },
  });

  const profileChangeHandler = (image) => {
    cropImage(image, (cropped) => {
      setProfileImg(cropped);
    });
  };

  const removeProfileHandler = () => {
    setProfileImg(null);
  };

  const methods = useForm({
    resolver: yupResolver(groupSchema),
  });

  const submitHandler = (values) => {
    createMutation.mutate({
      ...values,
      profile: profileImg,
      isPrivate: values?.groupType === "private",
    });
  };

  const Label = styled(Typography)(() => ({
    textAlign: "end",
    marginRight: 20,
  }));

  const Row = (props) => (
    <>
      <Grid item md={2} sm={2} xs={2}>
        {props?.children?.[0]}
      </Grid>
      <Grid item md={6} sm={10} xs={10} sx={{ pb: 3 }}>
        {props?.children?.[1]}
      </Grid>
      <Grid item md={4}>
        {props?.children?.[2]}
      </Grid>
    </>
  );

  const Profile = () => (
    <ColBox center sx={{ mt: 5 }}>
      <Box sx={{ position: "relative" }}>
        <ProfileImage variant="detail" image={profileImg} />
        {profileImg && (
          <IconButton
            sx={{ position: "absolute", top: -16, right: -8 }}
            onClick={removeProfileHandler}
          >
            <ImageBox src={require("../assets/icons/cancel.png")} />
          </IconButton>
        )}
      </Box>
      <FileButton onSelect={profileChangeHandler}>
        <Typography sx={{ cursor: "pointer", textDecoration: "underline" }}>
          Change group image
        </Typography>
      </FileButton>
    </ColBox>
  );

  return (
    <Container sx={{ mt: 1.25 }}>
      <PageTitle title="Create new Group" back path="/group" />
      <FormProvider {...methods}>
        <Grid container>
          <Row>
            <></>
            <Profile />
          </Row>

          <Row>
            <Label>Group Name</Label>
            <InputField name="name" />
          </Row>
          <Row>
            <Label>Group Description</Label>
            <InputField name="description" multiline rows={4} />
          </Row>

          <Row>
            <Label>Group Type</Label>
            <SelectController
              options={[
                { name: "Public", value: "public" },
                { name: "Private", value: "private" },
              ]}
              name="groupType"
              placeholder="Select Group Visibility"
            />
          </Row>

          <Row>
            <></>
            <Button
              variant="contained"
              sx={{ mt: 8, mb: 6, px: 5 }}
              onClick={methods.handleSubmit(submitHandler)}
            >
              Create Group
            </Button>
          </Row>
        </Grid>
      </FormProvider>
      {createMutation.isLoading && <ProgressIndicator />}
    </Container>
  );
}
