import React, { useContext, useState } from "react";

import { Typography } from "@mui/material";
import { PostModal } from "../components/app-ui";
import { Container, ProgressIndicator } from "../components/ui";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import { ReportPostTable } from "../components/tables";
import { ReportFilterContext } from "../services/context";
import { useAlert } from "../components/alert";

export default function ReportPostList(props) {
  const type = "post";
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [report, setReport] = useState();
  const [shouldShowPost, showPost] = useState();
  const category = useContext(ReportFilterContext);
  const alert = useAlert()

  const queryClient = useQueryClient();
  const { fetchReports, updateReport, deletePost } = useContext(APIContext);
  const { isLoading, data } = useQuery(
    ["report", { page, type, category, sort }],
    () => fetchReports(page, type, category, sort),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const updateMutation = useMutation(
    ({ id, ...data }) => updateReport(id, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["report", { type }]);
      },
    }
  );

  const deletePostMutation = useMutation((report) => deletePost(report?.post?.id), {
    onSuccess: (data, report) => {
      console.log("Deleted Post Id", report?.post?.id)
      toast.success("Successfully deleted post");
      queryClient.invalidateQueries(["comments", { post: report?.post?.id}])
      updateMutation.mutate({
        id: report?.id,
        status: "deleted",
      });
    },
  });

  const viewHandler = (report) => {
    setReport(report);
    showPost(true);
  };

  const allowHandler = (report) => {
    alert("Confirmation", "Are you sure to allow this content?", () => {
      updateMutation.mutate({
        id: report?.id,
        status: "allowed",
      });
    })
  };

  const deleteHandler = (report) => {
    alert("Confirmation", "Are you sure to delete this content?", () => {
      deletePostMutation.mutate(report);
    })
  };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Reports: ${
        data?.totalResults || 0
      }`}</Typography>
      <ReportPostTable
        data={data}
        onView={viewHandler}
        onAllow={allowHandler}
        onDelete={deleteHandler}
        page={page}
        onPageChange={setPage}
        onSort={(s) => {
          setSort(s);
          setPage(0);
        }}
      />
      <PostModal
        show={shouldShowPost}
        onClose={() => showPost(false)}
        post={report?.post}
        showDelete
        onDelete={deleteHandler}
      />
      {(isLoading ||
        updateMutation.isLoading ||
        deletePostMutation.isLoading) && <ProgressIndicator />}
    </Container>
  );
}
