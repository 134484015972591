import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { Container } from "../components/ui";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import {
  RowBox,
  ColBox,
  ProgressIndicator,
  SearchBar,
} from "../components/ui";

import { CommentTable } from "../components/tables";

import { PostModal } from "../components/app-ui";
import { useAlert } from "../components/alert";

export default function GroupComments() {
  const { groupId } = useParams();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [q, setQuery] = useState();
  const [post, setPost] = useState();
  const [shouldShowPost, showPost] = useState(false);
  const alert = useAlert();

  const { fetchGroupComments, deletePost } = useContext(APIContext);
  const { isLoading, data, isFetching } = useQuery(
    [
      "posts",
      { type: "group", content: "comment", id: groupId, page, q, sort },
    ],
    () => fetchGroupComments(groupId, page, q, sort),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const queryClient = useQueryClient();
  const deletePostMutation = useMutation((postId) => deletePost(postId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("posts");
      toast.success("Successfully deleted post");
    },
  });

  const deletePostHandler = (post) => {
    alert("Confirmation", "Are you sure to delete this post?", () => {
      deletePostMutation.mutate(post?.id);
    });
  };

  const viewHandler = (comment) => {
    setPost(comment?.post);
    showPost(true);
  };

  return (
    <Container sx={{ height: "calc(100% - 33px)" }}>
      <RowBox sx={{ pl: 2, my: 1, boxSizing: "border-box" }}>
        <Typography
          variant="h4Black"
          sx={{ my: 2, mr: "auto" }}
        >{`Total comments: ${data?.totalResults || 0}`}</Typography>
        <SearchBar value={q} onChange={setQuery} />
      </RowBox>
      <CommentTable
        data={data}
        onView={viewHandler}
        onDelete={deletePostHandler}
        onSort={(s) => {
          setSort(s);
          setPage(0);
        }}
      />
      <PostModal
        show={shouldShowPost}
        onClose={() => showPost(false)}
        post={post}
        showDelete
        onDelete={deletePostHandler}
      />
      {(isLoading || deletePostMutation.isLoading) && <ProgressIndicator />}
    </Container>
  );
}
