import { Typography } from "@mui/material";
import { Container } from "../components/ui";

export default function Dashboard() {
  return (
    <Container sx={{mt: 1.25, p: 2}}>
      <Typography variant="h2">Dashboard</Typography>
    </Container>
  );
}
