import React, { useContext, useState } from "react";
import { Container, ProgressIndicator } from "../../components/ui";
import { Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { SellerTable } from "../../components/tables";
import { SellerContext } from "../../services/context";

export default function SellerList(props) {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const { status } = props;
  const { q, country, state, type } = useContext(SellerContext);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchSellers } = useContext(APIContext);
  const { isLoading, data: sellers } = useQuery(
    ["sellers", { page, q, country, state, status, type }],
    () => fetchSellers({ page, q, country, state, status, type }),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const viewHandler = (seller) => {
    navigate(`/seller-detail/${seller?.id}`, { state: seller });
  };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Sellers: ${
        sellers?.totalResults || 0
      }`}</Typography>
      <SellerTable
        data={sellers}
        onView={viewHandler}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}