import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router";

import { Box, Button } from "@mui/material";

import {
  RowBox,
  ColBox,
  SearchBar,
  SplitTabs,
  Container,
} from "../components/ui";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { GroupSearchContext } from "../services/context";

export default function Group() {
  const [q, setQuery] = useState();
  const navigate = useNavigate();
  const { fetchGroups } = useContext(APIContext);

  const { data: allGroups } = useQuery(
    ["groups", { page: 0, type: "all" }],
    fetchGroups,
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const { data: publicGroups } = useQuery(
    ["groups", { page: 0, type: "public" }],
    () => fetchGroups({ type: "public" }),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const { data: privateGroups } = useQuery(
    ["groups", { page: 0, type: "private" }],
    () => fetchGroups({ type: "private" }),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const menu = [
    {
      name: `All Groups (${allGroups?.totalResults || 0})`,
      link: "all",
    },
    {
      name: `Public Groups (${publicGroups?.totalResults || 0})`,
      link: "public",
    },
    {
      name: `Hidden Groups (${privateGroups?.totalResults || 0})`,
      link: "hidden",
    },
  ];

  const newGroupHandler = () => {
    navigate("/group-new");
  };

  return (
    <Container sx={{ mt: 1.25 }}>
      <RowBox
        sx={{
          pr: 2,
          pt: 0,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
      >
        <SplitTabs
          menu={menu}
          hsx={{
            width: "auto",
            borderBottom: "none",
          }}
        />
        <Button
          variant="contained"
          onClick={newGroupHandler}
          sx={{ ml: "auto" }}
        >
          Add New Group
        </Button>
        <SearchBar
          placeholder="Search Users"
          sx={{ mx: 2 }}
          value={q}
          onChange={setQuery}
        />
      </RowBox>
      <GroupSearchContext.Provider value={q?.length > 2 ? q : null}>
        <Outlet />
      </GroupSearchContext.Provider>
    </Container>
  );
}
