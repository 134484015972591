import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Hidden,
  AppBar,
  Toolbar,
  List,
  ListItem,
  IconButton,
  Icon,
  Divider,
  styled,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  Autocomplete,
} from "@mui/material";

import { NavLink } from "react-router-dom";

import {
  ImageBox,
  ImageCarousel,
  ImageGrid,
  MultiLineInput,
  MultiLineInputControl,
  FileSelect,
  RowBox,
  ColBox,
  Clock,
  SelectController,
  AutocompleteController,
  InputAutoComplete,
  SearchBar,
  FileButton,
  VideoPlayer,
  Error,
  AutoCompleteInputText,
} from "./ui";

//API related imports
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import {
  dateString,
  dateTimeString,
  flattenComments,
  formatCounts,
  timeAgo,
  timeFormat,
  typeFromMedia,
} from "../utils/utils";

/* Components related to Header/Side Bar and Page Structure - BEGIN */

import GridViewIcon from "@mui/icons-material/GridView";
import InsightsIcon from "@mui/icons-material/Insights";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import GroupsIcon from "@mui/icons-material/Groups";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import FeaturedPlayListOutlinedIcon from "@mui/icons-material/FeaturedPlayListOutlined";
import PeopleIcon from "@mui/icons-material/People";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";

import { Controller, useFormContext } from "react-hook-form";

const menuItems = [
  { name: "Dashboard", link: "/dashboard", icon: GridViewIcon },
  { name: "Activity", link: "/activity", icon: InsightsIcon },
  { name: "Media", link: "/media", icon: PhotoLibraryIcon },
  { name: "Admin Post", link: "/post", icon: EmailOutlinedIcon },
  { name: "Groups", link: "/group", icon: GroupsIcon },
  { name: "Moderation", link: "/report", icon: GppGoodOutlinedIcon },
  { name: "User Management", link: "/user", icon: Person2OutlinedIcon },
  {
    name: "Services",
    link: "/ecommerce/services",
    icon: require("../assets/icons/services.png"),
  },
  {
    name: "Orders",
    link: "/ecommerce/orders",
    icon: ListAltOutlinedIcon,
  },
  {
    name: "Categories",
    link: "/ecommerce/category",
    icon: FeaturedPlayListOutlinedIcon,
  },
  {
    name: "Sellers",
    link: "/ecommerce/seller",
    icon: PeopleIcon,
  },
  {
    name: "Customers",
    link: "/ecommerce/customer",
    icon: AccessibilityIcon,
  },
  {
    name: "Reviews",
    link: "/ecommerce/review",
    icon: GradeOutlinedIcon,
  },
  {
    name: "Chat",
    link: "/chat",
    icon: ChatOutlinedIcon,
  },
  {
    name: "Settings",
    link: "/settings",
    icon: SettingsOutlinedIcon,
  },
];

export const LogoHeader = (props) => {
  const logo = require("../assets/icons/logo.png");
  return (
    <RowBox sx={props?.sx} center>
      <ImageBox src={logo} sx={{ width: 45, height: 45, mr: 1 }} />
      <Box>
        <Typography variant="caption">LOGO NAME</Typography>
        <Typography variant="subtitle2">TAGLINE HERE</Typography>
      </Box>
    </RowBox>
  );
};

export const GetAppFooter = (props) => {
  const appIcon = require("../assets/icons/app-store.png");
  const playIcon = require("../assets/icons/play-store.png");
  return (
    <Box sx={props?.sx}>
      <Typography variant="body1" sx={{ textAlign: "center", mb: 2.5 }}>
        Get the app
      </Typography>
      <RowBox center>
        <ImageBox src={appIcon} sx={{ height: 38, mr: 1 }} />
        <ImageBox src={playIcon} sx={{ height: 38, ml: 1 }} />
      </RowBox>
    </Box>
  );
};

export const ProfileImage = (props) => {
  const { image } = props;
  //TODO: Default Image, different sizes
  const theme = useTheme();
  const variant = props?.variant || "post";
  const profile = require("../assets/icons/default-user.jpg");
  const plus = require("../assets/icons/plus-circle.png");

  const height = {
    header: 30,
    post: 48,
    profile: 60,
    detail: 120,
    banner: 140,
    free: "100%",
  };

  const width = {
    header: 30,
    post: 48,
    profile: 60,
    detail: 94,
    banner: 140,
    free: "100%",
  };

  const imgWidth = width[variant];
  const imgHeight = height[variant];

  const url =
    image instanceof File
      ? URL.createObjectURL(image)
      : !!image
      ? image
      : profile;

  return (
    <ColBox sx={{ position: "relative" }}>
      <ImageBox
        src={url}
        sx={{
          height: imgHeight,
          width: imgWidth,
          mr: 1,
          border: `1px solid ${theme.palette.info.light}`,
          borderRadius: props?.rounded ? imgHeight / 2 : props?.curved || 1,
          ...props?.sx,
        }}
        onClick={props?.onClick}
      />
      {props?.editable && (
        <ImageBox
          src={plus}
          sx={{
            width: imgWidth / 4,
            height: imgHeight / 4,
            position: "absolute",
            zIndex: 2,
            bottom: imgWidth / 8,
            right: imgWidth / 8,
          }}
        />
      )}
    </ColBox>
  );
};

export const Badge = () => {
  return <ImageBox src={require("../assets/icons/badge.png")} width={20} />;
};

export const ProfileNavBar = (props) => {
  const user = props?.user;
  return (
    <Box sx={props?.sx}>
      <RowBox>
        <ProfileImage variant="header" rounded image={user?.profileUrl} />
        <Box>
          <Typography variant="h5">{user?.firstname}</Typography>
          <Typography variant="h5">{user?.lastname}</Typography>
        </Box>
        <ArrowDropDownIcon sx={{ ml: 1.5 }} />
      </RowBox>
    </Box>
  );
};

export const ProfileCounts = (props) => {
  const { user } = props;
  return (
    <Box>
      <Button variant="text">{`${formatCounts(user?.postCount)} Posts`}</Button>
      {/* TODO: Handle myprofile and other user profile */}
      <Button variant="text">{`${formatCounts(
        user?.followerCount
      )} Followers`}</Button>
      <Button variant="text">{`${formatCounts(
        user?.followingCount
      )} Following`}</Button>
    </Box>
  );
};

export const GroupCounts = (props) => {
  const { group } = props;
  return (
    <Box>
      <Button variant="text">{`${formatCounts(
        group?.postCount
      )} Posts`}</Button>
      <Button variant="text">{`${formatCounts(
        group?.memberCount
      )} Followers`}</Button>
    </Box>
  );
};

export const UserProfileHeader = (props) => {
  const theme = useTheme();
  const { user } = props;

  const FullName = ({ user }) => (
    <Typography variant="h4Black">{`${user.firstname} ${user.lastname}`}</Typography>
  );

  return (
    <Box sx={props.sx}>
      <RowBox sx={{ p: 2.25, alignItems: "flex-start" }}>
        <ProfileImage image={user.profileUrl} rounded />
        <Box sx={{ width: "100%" }}>
          <RowBox sx={{ justifyContent: "space-between" }}>
            <FullName user={user} />
            <ProfileCounts user={user} />
          </RowBox>
          <Typography variant="h6" sx={{ mt: -1 }}>
            {user?.profession}
          </Typography>
          <Typography variant="h6">{`${user?.state}, ${user?.country}`}</Typography>
          {user?.bio && (
            <Typography variant="body2">{`${user?.bio}`}</Typography>
          )}
        </Box>
      </RowBox>
    </Box>
  );
};

export const UserProfile = (props) => {
  const theme = useTheme();
  const { user } = props;
  if (!user) return;

  const FullName = ({ user }) => (
    <Typography variant="h5">{`${user?.firstname} ${user?.lastname}`}</Typography>
  );

  return (
    <Box
      sx={[
        props?.onClick && {
          cursor: "pointer",
          "&:hover": { backgroundColor: theme.palette.background.main },
        },
        props.sx,
      ]}
      onClick={props?.onClick}
    >
      <RowBox>
        <ProfileImage image={user?.profileUrl} />
        <ColBox>
          <FullName user={user} />
          <Typography variant="body1">{user?.username}</Typography>
          {user?.state && user?.country && (
            <Typography variant="h5">{`${user?.state}, ${user?.country}`}</Typography>
          )}
        </ColBox>
      </RowBox>
    </Box>
  );
};

export const GroupProfile = (props) => {
  const { group } = props;
  return (
    <RowBox>
      <ProfileImage
        variant="post"
        image={group?.profileUrl}
        onClick={() => props?.onClick(group)}
      />
      <Typography variant="h5">{group?.name}</Typography>
    </RowBox>
  );
};

export const GroupProfileHeader = (props) => {
  const theme = useTheme();
  const { group } = props;

  return (
    <Box sx={props.sx}>
      <RowBox sx={{ p: 2.25, alignItems: "flex-start" }}>
        <ProfileImage image={group.profileUrl} rounded />
        <Box sx={{ width: "100%" }}>
          <RowBox sx={{ justifyContent: "space-between" }}>
            <Typography variant="h4Black">{group.name}</Typography>
            <GroupCounts group={group} />
          </RowBox>
          {group?.description && (
            <Typography variant="body2">{`${group?.description}`}</Typography>
          )}
        </Box>
      </RowBox>
    </Box>
  );
};

export const NavBar = (props) => {
  const theme = useTheme();
  const chat = require("../assets/icons/chat.png");
  const notice = require("../assets/icons/notification.png");
  return (
    <AppBar color="neutral" position="sticky">
      <Toolbar>
        <Grid container sx={{ alignItems: "center", pl: 1, pr: 1 }}>
          <Hidden mdDown>
            <Grid item sm={2}>
              <LogoHeader sx={{ justifyContent: "flex-start" }} />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={8} md={6}>
            <RowBox>
              <Hidden mdUp>
                <IconButton onClick={props?.onMenu}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </RowBox>
          </Grid>
          <Hidden smDown>
            <Grid item sm={4}>
              <RowBox sx={{ justifyContent: "flex-end" }}>
                <Clock sx={{ mr: 2 }} />
                <ProfileNavBar user={props?.user} />
              </RowBox>
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export const SideBar = (props) => {
  const theme = useTheme();

  const Container = styled("nav")(() => ({
    height: "100%",
    boxSizing: "border-box",
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: 10,
  }));

  const StyledList = styled(List)(({ theme }) => ({
    backgroundColor: theme.palette.neutral.main,
    borderRadius: 10,
    paddingLeft: 8,
    paddingRight: 8,
    height: "100%",
    boxSizing: "border-box",
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    "&:hover": { backgroundColor: theme.palette.info.light },
    paddingTop: 12,
    paddingBottom: 12,
  }));

  const StyledImage = styled(ImageBox)(({ theme }) => ({
    width: 18,
    height: 18,
    marginRight: 10,
    marginLeft: 5,
  }));

  return (
    <Container sx={props?.sx}>
      <StyledList onClick={props?.onSelect}>
        {menuItems.map((i) => {
          const Icon = i.icon;
          return (
            <Box key={i.name}>
              <StyledListItem>
                <NavLink
                  to={i.link}
                  key={i.name}
                  style={{ textDecoration: "none" }}
                >
                  {({ isActive }) => (
                    <RowBox>
                      {typeof i.icon === "object" ? (
                        <Icon
                          sx={{
                            color: isActive ? "black" : theme.palette.info.dark,
                            mr: 1,
                          }}
                        />
                      ) : (
                        <StyledImage src={i.icon} />
                      )}
                      <Typography variant={isActive ? "h3" : "h4"}>
                        {i.name}
                      </Typography>
                    </RowBox>
                  )}
                </NavLink>
              </StyledListItem>
              <Divider />
            </Box>
          );
        })}
      </StyledList>
    </Container>
  );
};

/* Components related to Header/Side Bar and Page Structure - END */

/* Components related to Post - BEGIN */

export const PostProfile = (props) => {
  const { post } = props;
  return (
    <RowBox>
      <ProfileImage
        variant="post"
        image={post?.author?.profileUrl}
        onClick={() => props?.onClick(post?.author)}
      />
      <Box>
        <Typography variant="h5">{`${post?.author?.firstname} ${post?.author?.lastname}`}</Typography>
        <Typography variant="body2">{timeAgo(post?.createdAt)}</Typography>
      </Box>
    </RowBox>
  );
};

export const GroupPostProfile = (props) => {
  const { post } = props;
  return (
    <RowBox>
      <ProfileImage
        variant="post"
        image={post?.group?.profileUrl}
        onClick={() => props?.onClick(post?.group)}
      />
      <Box>
        <Typography variant="h5">{post?.group?.name}</Typography>
        <RowBox>
          <Typography variant="body2">{`${post?.author?.firstname} ${post?.author?.lastname}`}</Typography>
          <Typography variant="body2" sx={{ ml: 1.25 }}>
            {timeAgo(post?.createdAt)}
          </Typography>
        </RowBox>
      </Box>
    </RowBox>
  );
};

export const PostStats = (props) => {
  const { post } = props;
  return (
    <RowBox>
      <Typography variant="subtitle1" sx={{ mr: 2.5 }}>{`${formatCounts(
        post?.likeCount || 0
      )} likes`}</Typography>
      <Typography
        variant="subtitle1"
        onClick={() =>
          post?.commentCount > 0 ? props?.onCommentClick() : null
        }
        sx={[
          props?.onCommentClick &&
            post?.commentCount > 0 && { cursor: "pointer" },
        ]}
      >{`${formatCounts(post?.commentCount || 0)} comments`}</Typography>
    </RowBox>
  );
};

export const PostActions = (props) => {
  const { post } = props;
  const likeIcon = require("../assets/icons/heart.png");
  const likedIcon = require("../assets/icons/liked_heart.png");
  const commentIcon = require("../assets/icons/comment.png");
  const bookmarkIcon = require("../assets/icons/bookmark.png");
  const bookmarkedIcon = require("../assets/icons/bookmarked.png");

  const StyledImage = styled("img")(({ theme }) => ({
    width: 20,
    height: 20,
    marginRight: 20,
    objectFit: "contain",
  }));

  return (
    <RowBox sx={props?.sx}>
      <StyledImage src={likeIcon} />
      <StyledImage src={commentIcon} />
      <StyledImage src={bookmarkIcon} />
    </RowBox>
  );
};

export const PostItem = (props) => {
  const { post } = props;
  return (
    <Box
      sx={{
        mb: 1,
        borderRadius: 2,
        p: 3,
        backgroundColor: "white",
        ...(props?.topless && {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }),
        width: "100%",
        boxSizing: "border-box",
        ...props?.sx,
      }}
    >
      {props?.group ? (
        <GroupPostProfile post={post} onClick={props?.onProfileClick} />
      ) : (
        <PostProfile post={post} onClick={props?.onProfileClick} />
      )}
      <Typography variant="body1" sx={{ mt: 1.5, mb: 1.5 }}>
        {post.content}
      </Typography>
      {post?.media?.length > 0 && post?.media?.[0]?.mediaType !== "video" && (
        <ImageCarousel images={post?.media} sx={{ mb: 2 }} />
      )}
      {post?.media?.length > 0 && post?.media?.[0]?.mediaType === "video" && (
        <VideoPlayer video={post?.media?.[0]} sx={{ mb: 2 }} />
      )}
      <PostStats post={post} onCommentClick={props?.onCommentClick} />
      {/* <PostActions sx={{ mt: 1 }} /> */}
    </Box>
  );
};

export const GroupSelectController = (props) => {
  const { control, defaultValue } = useFormContext();
  const { name, hideError, placeholder, ...params } = props;

  const [q, setQuery] = useState();

  const { fetchGroups } = useContext(APIContext);
  const { data: groups } = useQuery(
    ["groups", { q, type: "all" }],
    () => fetchGroups({ q, limit: 100 }),
    {
      select: (data) =>
        data?.data?.results?.map((g) => ({
          label: g.name,
          id: g.id,
        })),
    }
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Autocomplete
            disablePortal
            value={value}
            onChange={(e, value) => onChange(value)}
            renderInput={(params) => (
              <AutoCompleteInputText {...params} placeholder={placeholder} />
            )}
            onInputChange={(e, v, reason) => {
              if (reason === "input" || reason === "clear") setQuery(v);
            }}
            options={groups || []}
            {...params}
          />
          {!hideError && <Error error={error} />}
        </>
      )}
    />
  );
};

export const PostMediaSelect = (props) => {
  const { control, defaultValue } = useFormContext();
  const galleryIcon = require("../assets/icons/gallery.png");
  const videoIcon = require("../assets/icons/video.png");

  const StyledImage = styled("img")(({ theme }) => ({
    width: 24,
    height: 24,
    objectFit: "contain",
  }));

  /* We can have either 4 images or a single video */
  const selectHandler = (media, prev, callback) => {
    if (!media) return;

    if (!prev || prev.length <= 0) {
      return callback([media]);
    }

    const prevMediaType = typeFromMedia(prev[0]);
    const mediaType = typeFromMedia(media);

    if (mediaType === "video") {
      return callback([media]);
    }

    if (mediaType === "image") {
      if (prevMediaType === "video") {
        callback([media]);
      } else {
        callback([...prev, media].slice(-4));
      }
    }
  };

  return (
    <ColBox sx={props?.wsx}>
      <Controller
        control={control}
        name="media"
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <RowBox sx={{}}>
            <FileButton
              onSelect={(image) => selectHandler(image, value, onChange)}
              type="image/*"
            >
              <IconButton>
                <StyledImage src={galleryIcon} />
              </IconButton>
            </FileButton>
            <FileButton
              onSelect={(video) => selectHandler(video, value, onChange)}
              type="video/*"
              single
            >
              <IconButton>
                <StyledImage src={videoIcon} />
              </IconButton>
            </FileButton>
          </RowBox>
        )}
      />
    </ColBox>
  );
};

export const CreatePost = (props) => {
  const { isEdit } = props;

  const methods = useFormContext();
  const media = methods.watch("media");
  const postTo = methods.watch("postTo");

  const images = media?.filter((m) => typeFromMedia(m) === "image");
  const video = media?.filter((m) => typeFromMedia(m) === "video");

  const postOptions = [
    { name: "Timeline", value: "timeline" },
    { name: "Group", value: "group" },
  ];

  const deleteHandler = (index) => {
    const newImages = media?.filter((el, i) => i !== index);
    methods.setValue("media", newImages, { shouldDirty: true });
  };

  const videoDeleteHandler = () => {
    methods.setValue("media", [], { shouldDirty: true });
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={7} xs={12}>
        <RowBox>
          <RowBox sx={{ width: "auto", flexShrink: 0, mr: 2 }}>
            <Typography variant="h4Black" sx={{ mr: 2 }}>
              Post To
            </Typography>
            <SelectController
              name="postTo"
              options={postOptions}
              placeholder="Select Option"
              fullWidth={false}
              wsx={{ width: "auto" }}
              hideError
              disabled={isEdit}
            />
          </RowBox>
          {postTo === "group" && (
            <RowBox>
              <Typography variant="h4Black" sx={{ mr: 2 }}>
                Group
              </Typography>
              <GroupSelectController
                name="group"
                placeholder="Select Group"
                hideError
                fullWidth
                disabled={isEdit}
              />
            </RowBox>
          )}
        </RowBox>
        <RowBox sx={{ pt: 2 }}>
          <Typography variant="h4Black" sx={{ mr: "auto" }}>
            Post
          </Typography>
          <PostMediaSelect />
        </RowBox>
        <MultiLineInputControl
          placeholder="What's on your mind?"
          name="content"
          variant="outlined"
        />
        {video?.length > 0 && (
          <VideoPlayer
            video={video[0]}
            wsx={{ mt: 2 }}
            editable
            onDelete={() => deleteHandler(0)}
          />
        )}
        {images?.length > 0 && (
          <ImageGrid
            images={images}
            sx={{ my: 2, overflow: "visible" }}
            editable
            onDelete={deleteHandler}
          />
        )}
      </Grid>
      <Grid item sm={5} xs={12} sx={{ display: "flex", alignItems: "center" }}>
        <ColBox>
          <Typography variant="h4Black" sx={{ mb: 1 }}>
            Notes
          </Typography>
          <MultiLineInputControl
            placeholder="Enter Notes"
            variant="outlined"
            name="notes"
          />
        </ColBox>
      </Grid>
    </Grid>
  );
};

export const Comments = (props) => {
  const { comments, showDelete } = props;
  return (
    <List>
      {comments.map((c) => (
        <RowBox key={c?.id}>
          <ListItem
            key={c.id}
            divider
            sx={[c.commentLevel > 1 && { paddingLeft: 7 }]}
          >
            <PostItem post={c} />
            {showDelete && (
              <Button variant="outlined" onClick={() => props?.onDelete(c)}>
                Delete
              </Button>
            )}
          </ListItem>
        </RowBox>
      ))}
    </List>
  );
};

export const PostModal = (props) => {
  const { post, showDelete } = props;
  const theme = useTheme();
  const Delete = styled("img")(() => ({
    position: "absolute",
    right: 10,
    top: 10,
  }));

  const queryClient = useQueryClient();
  const { fetchPostComments } = useContext(APIContext);

  const { isLoading, data: comments } = useQuery(
    ["comments", { post: post?.id }],
    () => fetchPostComments(post?.id),
    {
      enabled: !!props?.show && !!post,
      select: (data) => flattenComments(data?.data),
    }
  );

  return (
    <Dialog open={props?.show} onClose={props?.onClose} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{ borderBottom: `1px solid ${theme.palette.background.main}` }}
      >
        <Typography variant="h4Black">Post</Typography>
        <Delete
          src={require("../assets/icons/cancel.png")}
          onClick={props?.onClose}
        />
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "70vh" }}>
        <RowBox>
          <PostItem post={post}></PostItem>
          {showDelete && (
            <Button variant="outlined" onClick={() => props?.onDelete(post)}>
              Delete
            </Button>
          )}
        </RowBox>

        {comments && (
          <Comments
            comments={comments}
            showDelete={showDelete}
            onDelete={props?.onDelete}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export const CommentModal = (props) => {
  const { post, showDelete } = props;
  const theme = useTheme();
  const Delete = styled("img")(() => ({
    position: "absolute",
    right: 10,
    top: 10,
  }));

  const queryClient = useQueryClient();
  const { fetchPostComments } = useContext(APIContext);

  const { isLoading, data: comments } = useQuery(
    ["comments", { post: post?.id }],
    () => fetchPostComments(post?.id),
    {
      enabled: !!props?.show && !!post,
      select: (data) => flattenComments(data?.data),
    }
  );

  return (
    <Dialog open={props?.show} onClose={props?.onClose} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{ borderBottom: `1px solid ${theme.palette.background.main}` }}
      >
        <Typography variant="h4Black">Comments</Typography>
        <Delete
          src={require("../assets/icons/cancel.png")}
          onClick={props?.onClose}
        />
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "70vh" }}>
        {comments && (
          <Comments
            comments={comments}
            showDelete={showDelete}
            onDelete={props?.onDelete}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

/* Components related to Post - END */

/* Components realed to Media - BEGIN */

export const PhotoPopup = (props) => {
  const { media } = props;
  const StyledImage = styled("img")(({ theme }) => ({
    height: "100",
    width: "100%",
    objectFit: "contain",
  }));

  const Delete = styled("img")(() => ({
    position: "absolute",
    right: 0,
    top: 0,
  }));

  return (
    <Dialog open={props?.show} onClose={props?.onClose}>
      {typeof media === "string" && <StyledImage src={media} />}
      {media?.mediaType === "image" && <StyledImage src={media?.url} />}
      {media?.mediaType === "video" && (
        <VideoPlayer video={media} vsx={{ maxHeight: "70vh" }} />
      )}
      <Delete
        src={require("../assets/icons/cancel.png")}
        onClick={props?.onClose}
      />
    </Dialog>
  );
};

/* Components realed to Media - END */

/* Components related to Groups - BEGIN */

export const GroupSummary = (props) => {
  const theme = useTheme();
  const { profileUrl, name, memberCount, postCount, createdAt } = props;

  return (
    <ColBox sx={{ px: 1, ...props?.sx }} center>
      <RowBox center sx={{ pt: 1 }}>
        <Box sx={{ position: "relative" }}>
          <ProfileImage variant="detail" image={profileUrl} />
          {profileUrl && (
            <IconButton
              sx={{ position: "absolute", top: -16, right: -8 }}
              onClick={props?.onRemoveProfile}
            >
              <ImageBox src={require("../assets/icons/cancel.png")} />
            </IconButton>
          )}
        </Box>
      </RowBox>
      <FileButton onSelect={props?.onProfileChange}>
        <Typography sx={{ cursor: "pointer", textDecoration: "underline" }}>
          Change group image
        </Typography>
      </FileButton>
      <Typography variant="h3" sx={{ my: 2 }}>
        {name}
      </Typography>
      <Typography variant="h4">{`Total Members: ${memberCount}`}</Typography>
      <Typography
        variant="h4"
        sx={{ my: 1.25 }}
      >{`Total Posts: ${postCount}`}</Typography>
      <Divider />
      <Typography variant="body1" sx={{ my: 1.25 }}>{`Created on: ${dateString(
        createdAt
      )}`}</Typography>
      <Divider />
      <Button variant="outlined" sx={{ mt: 4 }} onClick={props?.onDelete}>
        Delete Group
      </Button>
    </ColBox>
  );
};

export const GroupListItem = (props) => {
  const { group } = props;
  return (
    <RowBox sx={props?.sx}>
      <ProfileImage image={group?.profileUrl} onClick={props?.onClick} />
      <Typography
        variant="h4Black"
        sx={{ mr: "auto" }}
        onClick={props?.onClick}
      >
        {group.name}
      </Typography>
      <Button variant="outlined" onClick={props?.onJoin}>
        Join
      </Button>
    </RowBox>
  );
};

export const UserPicker = (props) => {
  const [q, setQuery] = useState();

  const { fetchAllUsers } = useContext(APIContext);
  const { data: users } = useQuery(
    ["users", { q: q, group: props?.group, page: 0 }],
    () => fetchAllUsers(q, props?.group),
    {
      select: (data) => data?.data,
    }
  );

  const userSelectHandler = (user) => {
    props?.onChange?.(user);
    props?.onClose();
    setQuery(null);
  };

  return (
    <Dialog open={props?.show} onClose={props?.onClose}>
      <DialogTitle>
        <Typography>Add new member to the group</Typography>
      </DialogTitle>
      <DialogContent sx={{ height: "70vh" }}>
        <SearchBar onChange={setQuery} />
        <List>
          {users?.results?.map((user) => (
            <ListItem key={user.id} sx={{ px: 0 }}>
              <UserProfile
                user={user}
                onClick={() => userSelectHandler(user)}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

/* Components related to Groups - END */

/* Components related to User - BEGIN */
export const UserSummary = (props) => {
  const theme = useTheme();
  const {
    profileUrl,
    fullname,
    username,
    state,
    country,
    role,
    lastSeen,
    createdAt,
    isSuspended,
  } = props;

  const Text = styled(Typography)(() => ({
    textAlign: "center",
  }));

  return (
    <ColBox sx={{ px: 1, ...props?.sx }} center>
      <RowBox center sx={{ pt: 1 }}>
        <ProfileImage variant="detail" image={profileUrl} />
      </RowBox>
      <Text
        sx={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={props?.onRemoveProfile}
      >
        Change to default profile Image
      </Text>
      <Text variant="h3" sx={{ mt: 3 }}>
        {fullname}
      </Text>
      <Text variant="h4" sx={{ color: theme.palette.info.main }}>
        {username}
      </Text>
      <Text variant="h4">{`${state}, ${country}`}</Text>
      <Text
        variant="h5Gray"
        sx={{ textAlign: "center", mt: 1, mb: 2 }}
      >{`Last Seen: ${dateTimeString(lastSeen)}`}</Text>

      <Divider />
      <Text variant="body1" sx={{ mt: 1 }}>{`Role: ${role}`}</Text>
      <Text
        variant="body1"
        sx={{ mb: 1, textAlign: "center" }}
      >{`Member since: ${dateString(createdAt)}`}</Text>
      <Divider />
      <Button variant="outlined" sx={{ mt: 2 }} onClick={props?.onNewPassword}>
        Set New Password
      </Button>
      <Button variant="outlined" sx={{ mt: 1 }} onClick={props?.onSuspend}>
        {isSuspended ? "Unsuspend" : "Suspend"}
      </Button>
      {/* <Button variant="outlined" sx={{ mt: 1 }} onClick={props?.onDelete}>
        Delete User
      </Button> */}
    </ColBox>
  );
};

export const CustomerSummary = (props) => {
  const theme = useTheme();
  const { user } = props;

  const Text = styled(Typography)(() => ({
    textAlign: "center",
  }));

  return (
    <ColBox sx={{ px: 1, pb: 3, ...props?.sx }} center>
      <RowBox center sx={{ pt: 1 }}>
        <ProfileImage variant="detail" image={user?.profileUrl} />
      </RowBox>
      <Text variant="h3" sx={{ mt: 3 }}>
        {user?.fullname}
      </Text>
      <Text variant="h4" sx={{ color: theme.palette.info.main }}>
        {user?.username}
      </Text>
      <Text variant="h4Black">{`${user?.state}, ${user?.country}`}</Text>
      <Text
        variant="h5"
        sx={{ textAlign: "center", my: 2 }}
      >{`Last Seen: ${dateTimeString(user?.lastSeen)}`}</Text>

      <Divider />
      <Text
        variant="body1"
        sx={{ my: 2, textAlign: "center" }}
      >{`Member since: ${dateString(user?.createdAt)}`}</Text>
      <Divider />
    </ColBox>
  );
};

/* Components related to User - END */

/* Components related to Message - START */
export const MessageDateHeader = (props) => {
  const theme = useTheme();

  return (
    <RowBox sx={{ justifyContent: "center" }}>
      <Box
        sx={{
          px: 4,
          py: 1.25,
          borderColor: theme.palette.info.main,
          borderWidth: 1,
          borderRadius: 14,
          borderStyle: "solid",
          alignSelf: "center",
          my: 2,
          boxShadow: 4,
          elevation: 2,
        }}
      >
        <Typography variant="body0" color={theme.palette.primary.main}>
          {props?.date}
        </Typography>
      </Box>
    </RowBox>
  );
};

export const Message = (props) => {
  const theme = useTheme();
  const { conversation, message } = props;
  const isSender = conversation.sender.id === message.sender.id;

  return (
    <RowBox
      sx={{
        flex: 1,
        py: 1.25,
        px: 2,
        justifyContent: isSender ? "flex-start" : "flex-end",
        alignItems: 'flex-start'
      }}
    >
      {isSender && (
        <ProfileImage
          variant="post"
          image={message?.sender?.profileThumb || message?.sender?.profileUrl}
        />
      )}
      <ColBox
        sx={{
          ml: 1.25,
          maxWidth: "60%",
          mr: 1.25,
        }}
      >
        {message?.content && (
          <Box
            sx={{
              borderColor: theme.palette.border.main,
              borderWidth: 1,
              borderStyle: "solid",
              elevation: 2,
              borderRadius: 14,
              backgroundColor: isSender ? theme.palette.neutral.light : "white",
              py: 1.25,
              px: 2,
            }}
          >
            {message?.content}
          </Box>
        )}

        {message?.media &&
          message?.media.length > 0 &&
          message?.contentType === "image" && (
            <ImageBox
              src={message?.media[0].preview || message?.media[0].url }
              sx={{ width: "80%", aspectRatio: 4 / 3 }}
            />
          )}

        <Typography
          variant="body2"
          sx={{
            alignSelf: isSender ? "flex-start" : "flex-end",
            mx: 0.4,
            mt: 0.4,
          }}
          color="grey4"
        >
          {timeFormat(message.createdAt)}
        </Typography>
      </ColBox>
      {!isSender && (
        <ProfileImage
          variant="post"
          image={message?.sender?.profileThumb || message?.sender?.profileUrl}
        />
      )}
    </RowBox>
  );
};

/* Components related to Message - End */
