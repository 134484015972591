import React, { useContext, useState } from "react";
import { Container, ProgressIndicator } from "../components/ui";
import { Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { GroupTable } from "../components/tables";
import { GroupSearchContext } from "../services/context";

export default function GroupList(props) {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const { type } = props;
  const q = useContext(GroupSearchContext);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchGroups } = useContext(APIContext);
  const { isLoading, data: groups } = useQuery(
    ["groups", { page, type, q, sort }],
    () => fetchGroups({page, type, q, sort}),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const viewHandler = (group) => {
    navigate(`/group-detail/${group?.id}`, { state: group });
  };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Groups: ${
        groups?.totalResults || 0
      }`}</Typography>
      <GroupTable
        data={groups}
        onView={viewHandler}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}
