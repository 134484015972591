import React, { useContext, useState } from "react";
import { Typography } from "@mui/material";

import { useNavigate, useParams, useLocation } from "react-router";

//API related imports
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";


import { Container, ProgressIndicator, RowBox, InputSelect, } from "../../components/ui";
import {
  MainCatSelect,
  CatSelect,
  SubCatSelect,
} from "../../components/ecommerce-ui";
import { SellerServicesTable } from "../../components/tables";

export default function SellerServices(props) {
  const [page, setPage] = useState(0);
  const { id } = useParams();
  const [availability, setAvailability] = useState();
  const [mainCategory, setMainCategory] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchAllProducts, fetchSeller } = useContext(APIContext);

  const { data: seller } = useQuery(["seller", { id }], () => fetchSeller(id), {
    select: (data) => data?.data,
  });

  const { isLoading, data: services } = useQuery(
    [
      "products",
      {
        page,
        seller: seller?.user?.id,
        availability,
        mainCategory,
        category,
        subCategory,
      },
    ],
    () =>
      fetchAllProducts({
        page,
        seller: seller?.user?.id,
        availability,
        mainCategory,
        category,
        subCategory,
      }),
    {
      keepPreviousData: true,
      enabled: !!seller,
      select: (data) => data?.data,
    }
  );

  const viewHandler = (product) => {
    navigate(`/service-detail/${product?.id}`, { state: product });
  };

  const clearFilterHandler = () => {
    setMainCategory()
    setCategory()
    setSubCategory()
    setAvailability()
  }

  return (
    <Container sx={{ p: 2 }}>
      <RowBox sx={{mb: 2}}>
        <Typography variant="h4Black" sx={{mr: 2}}>{`Total Services: ${
          services?.totalResults || 0
        }`}</Typography>
        <Typography>Filter By:</Typography>

        <InputSelect
          options={[
            { name: "Available", value: "available" },
            { name: "Unavailable", value: "unavailable" },
          ]}
          placeholder="Availability"
          value={availability}
          onChange={setAvailability}
          fullWidth={false}
          sx={{ mx: 2, minWidth: 150 }}
        />

        <MainCatSelect
          value={mainCategory}
          onChange={setMainCategory}
          fullWidth={false}
          sx={{ mr: 2, minWidth: 150 }}
        />

        {mainCategory && (
          <CatSelect
            value={category}
            mainCategory={mainCategory}
            onChange={setCategory}
            fullWidth={false}
            sx={{ mr: 2, minWidth: 150 }}
          />
        )}

        {category && (
          <SubCatSelect
            mainCategory={mainCategory}
            category={category}
            value={subCategory}
            onChange={setSubCategory}
            fullWidth={false}
            sx={{ mr: 2, minWidth: 150 }}
          />
        )}

        <Typography
          sx={{ cursor: "pointer", textDecoration: "underline", ml: 2 }}
          onClick={clearFilterHandler}
        >
          Clear Filters
        </Typography>
      </RowBox>
      <SellerServicesTable
        data={services}
        onView={viewHandler}
        onReview={viewHandler}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}
