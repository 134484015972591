import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router";

import { Box, Button, Typography } from "@mui/material";

import { RowBox, ColBox, SplitTabs, InputSelect, Container } from "../components/ui";
import { ReportFilterContext } from "../services/context";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { GroupSearchContext } from "../services/context";

import { reportCategories } from "../config";

export default function Report() {
  const [category, setCategory] = useState();
  const navigate = useNavigate();

  const { fetchReports, fetchBlockReport } = useContext(APIContext);
  const { data: postReports } = useQuery(
    ["report", { page: 0, type: "post" }],
    () => fetchReports(0, "post"),
    {
      select: (data) => data?.data,
    }
  );

  const { data: userReports } = useQuery(
    ["report", { page: 0, type: "user" }],
    () => fetchReports(0, "user"),
    {
      select: (data) => data?.data,
    }
  );

  const { data: blockReports } = useQuery(
    ["blockReport", { page: 0}],
    () => fetchBlockReport(0),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const menu = [
    {
      name: `Reported Content (${postReports?.totalResults || 0})`,
      link: "post",
    },
    {
      name: `Reported Users (${userReports?.totalResults || 0})`,
      link: "user",
    },
    {
      name: `Blocked Users (${blockReports?.totalResults || 0})`,
      link: "block",
    },
  ];

  const clearFilterHandler = () => {
    setCategory(null);
  };

  return (
    <Container sx={{ mt: 1.25 }}>
      <RowBox
        sx={{
          pr: 2,
          pt: 0,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
      >
        <SplitTabs
          menu={menu}
          hsx={{
            justifyContent: "flex-start",
            borderBottom: "none",
          }}
        />
        <RowBox center sx={{ alignSelf: "flex-start", pt: 3 }}>
          <InputSelect
            options={reportCategories}
            placeholder="Select Category"
            value={category}
            onChange={setCategory}
            fullWidth={false}
            sx={{ mx: 2, minWidth: 150 }}
          />

          <Typography
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={clearFilterHandler}
          >
            Clear Filters
          </Typography>
        </RowBox>
      </RowBox>
      <ReportFilterContext.Provider value={category}>
        <Outlet />
      </ReportFilterContext.Provider>
    </Container>
  );
}
