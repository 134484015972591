import React, { useState, useContext } from "react";
import { Typography } from "@mui/material";
import { Container, ProgressIndicator, RowBox } from "../components/ui";

import { useNavigate } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import { UserSearchContext } from "../services/context";
import { ConversationsTable, MessageTable } from "../components/tables";
import { useAlert } from "../components/alert";

export default function Conversations(props) {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [name, setName] = useState();
  const [conv, setConv] = useState();
  const [msgPage, setMsgPage] = useState(0);
  const alert = useAlert();

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchConversations, fetchMessages } = useContext(APIContext);
  const { isLoading, data: conversations } = useQuery(
    ["conversations", { page, sort, name }],
    () => fetchConversations(page, sort, name),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const { isLoading: isMsgLoading, data: messages } = useQuery(
    ["message", { conv, msgPage }],
    () => fetchMessages(conv.id, msgPage),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
      enabled: !!conv,
    }
  );

  console.log(conversations);

  console.log(messages);

  const viewHandler = (data) => {
    setConv(data);
  };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Chats: ${
        conversations?.totalResults || 0
      }`}</Typography>
      <RowBox sx={{ height: "100%" }}>
        <ConversationsTable
          data={conversations}
          onSelect={viewHandler}
          page={page}
          onPageChange={setPage}
          //onSort={(s) => {setSort(s); setPage(0)}}
        />
        <MessageTable
          conversation={conv}
          data={messages}
          page={msgPage}
          onPageChange={setMsgPage}
        />
      </RowBox>

      {isLoading && isMsgLoading && <ProgressIndicator />}
    </Container>
  );
}
