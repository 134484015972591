import {
  Box,
  Button,
  styled,
  TextField,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import {
  ColBox,
  RowBox,
  InputField,
  InputSelect,
  SelectController,
  CountryPicker,
  StatePicker,
  FileSelect,
  MultiLineInput,
  MultiLineInputControl,
} from "./ui";

import {
  AvailabilityPicker,
  DurationItem,
  LanguagePicker,
  MainCatSelectController,
  CatSelectController,
  SubCatSelectController,
  ProductFile,
  SinglePrice,
  PackageItem,
  WordLimitItem,
  ApplicationForm,
} from "./ecommerce-ui";
import { useFormContext } from "react-hook-form";
import { deliveryTimes, revisions, rushDeliveryTimes } from "../config";

export const LoginForm = (props) => {
  return (
    <Box component="form" noValidate autoComplete="off">
      <InputField
        label="Email Address"
        name="email"
        variant="filled"
      ></InputField>
      <InputField
        label="Password"
        name="password"
        type="password"
        sx={{ mt: 2.5 }}
        variant="filled"
      ></InputField>
    </Box>
  );
};

export const GroupForm = (props) => {
  const Label = styled(Typography)(() => ({
    flex: 0.2,
    textAlign: "end",
    marginRight: 20,
  }));

  return (
    <ColBox component="form" noValidate autoComplete="off" fullWidth>
      <RowBox sx={{ mt: 8 }}>
        <Label>Group Name</Label>
        <InputField name="name" wsx={{ maxWidth: 400, flex: 0.8 }} />
      </RowBox>
      <RowBox sx={{ mt: 2 }}>
        <Label>Group Description</Label>
        <InputField
          name="description"
          wsx={{ maxWidth: 400, flex: 0.8 }}
          multiline
          rows={4}
        />
      </RowBox>
      <RowBox sx={{ mt: 2 }}>
        <Label>Group Type</Label>
        <SelectController
          options={[
            { name: "Public", value: "public" },
            { name: "Private", value: "private" },
          ]}
          name="groupType"
          placeholder="Select Group Visibility"
          wsx={{ maxWidth: 400, flex: 0.8 }}
        />
      </RowBox>
    </ColBox>
  );
};

export const SellerRegisterForm = (props) => {
  const { type } = props;
  const Input = (props) => (
    <InputField variant="filled" {...props} wsx={props?.wsx || { mt: 2.75 }} />
  );

  const File = (props) => {
    const { buttonTitle, ...options } = props;
    return (
      <FileSelect single show wsx={{ mt: 2.75 }} {...options}>
        <Button variant="secondary">{props?.buttonTitle}</Button>
      </FileSelect>
    );
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <Typography variant="h3" sx={{ mt: 2.75, mb: 1.25 }}>
        Name
      </Typography>
      <RowBox sx={{ alignItems: "flex-start" }}>
        <Input
          name="firstname"
          placeholder="First Name"
          wsx={{ flex: 0.6, mr: 2.5 }}
        />
        <Input name="lastname" placeholder="Last Name" wsx={{ flex: 0.4 }} />
      </RowBox>
      <Typography variant="h3" sx={{ mt: 2.75, mb: 1.25 }}>
        Contact Number
      </Typography>
      <RowBox sx={{ alignItems: "flex-start" }}>
        <SelectController
          options={countryCodes}
          name="countryCode"
          defaultValue="91"
          variant="filled"
          sx={{ width: 80, marginRight: 1.25 }}
        />
        <Input
          name="phoneNo"
          placeholder="Enter your contact number"
          wsx={{ flex: 1 }}
        />
      </RowBox>
      {type === "individual" && (
        <>
          <Input name="email" title="Email" placeholder="Enter your email" />
          <Input
            name="address"
            title="Address"
            placeholder="Enter your address"
            multiline
            rows={4}
          />
        </>
      )}

      {type === "business" && (
        <>
          <Input
            name="companyName"
            title="Company Name"
            placeholder="Enter your company name"
          />

          <Input
            name="businessName"
            title="Business Name/Trade Name"
            placeholder="Enter your business name"
          />

          <Input
            name="email"
            title="Business Email"
            placeholder="Enter your business email"
          />

          <Input
            name="address"
            title="Business Address"
            placeholder="Enter your address"
            multiline
            rows={4}
          />
        </>
      )}

      {type === "teacher" && (
        <>
          <Input
            name="speciality"
            title="Speciality"
            placeholder="Enter your speciality"
          />
          <SelectController
            name="title"
            title="Title"
            placeholder="Select Title"
            options={["Mr.", "Mrs.", "Dr."].map((o) => ({ name: o, value: o }))}
            variant="filled"
            wsx={{ mt: 2.75 }}
          />
          <Input
            name="email"
            title="Email"
            placeholder="Enter your email address"
          />
          <Input
            name="hospitalName"
            title="Hospital/Clinic Name"
            placeholder="Enter the hospital or clinic name"
          />
          <Input
            name="address"
            title="Work Address"
            placeholder="Enter your address"
            multiline
            rows={4}
          />
        </>
      )}

      <CountryPicker wsx={{ mt: 2.75 }} title="Country" />
      <StatePicker wsx={{ mt: 2.75 }} title="State" />
      <Input name="zipcode" title="Zipcode" placeholder="Enter zip code" />
      {props?.type === "business" && (
        <Input
          name="signatoryName"
          title="Name of Authorized Signatory"
          placeholder="Enter Authorized Signatory name"
        />
      )}

      {props?.type != "teacher" && (
        <>
          <File
            name="photoId"
            title="Upload your Id"
            subtitle="(Passport copy, Driver licence)"
            buttonTitle="Upload Id"
          />

          <File
            name="selfie"
            title="Upload your selfie with the ID visible"
            buttonTitle="Upload Selfie"
          />
        </>
      )}

      {type === "business" && (
        <File
          name="aoiDoc"
          title="Upload Article of Incorporation"
          buttonTitle="Upload Doc"
        />
      )}
    </Box>
  );
};

export const ServiceForm = (props) => {
  const { schema } = props;
  const form = useFormContext();

  const mainCategory = form.watch("mainCategory");
  const category = form.watch("category");

  return (
    <Grid container>
      <Grid item sm={12} md={9} sx={{ px: 3 }}>
        <RowBox fullWidth sx={{ pb: 3 }}>
          <MainCatSelectController
            name="mainCategory"
            title="Main Category"
            hideTitle
            disabled
            wsx={{ flex: 1, mr: 1 }}
          />
         <CatSelectController
            name="category"
            title="Category"
            mainCategory={mainCategory}
            hideTitle
            disabled
            wsx={{ flex: 1, mr: 1 }}
          />
          <SubCatSelectController
            name="subCategory"
            title="Sub Category"
            mainCategory={mainCategory}
            category={category}
            hideTitle
            disabled
            wsx={{ flex: 1 }}
          />
        </RowBox>
        <Divider />
        {schema?.title && (
          <InputField
            title="Title"
            name="title"
            placeholder="Enter Title"
            wsx={{ mt: 4 }}
          />
        )}
        {schema?.shortDesc && (
          <MultiLineInputControl
            title="Short Description"
            name="shortDesc"
            placeholder="Write a short Description of the service offered"
            totalChars={90}
            rows={2}
            wsx={{ mt: 3 }}
          />
        )}

        {schema?.application && <ApplicationForm />}

        {schema?.package && (
          <Grid container spacing={2}>
            {schema?.package?.map((s, i) => (
              <Grid item xs={12} md={6}>
                <PackageItem schema={s} name={`packages.${i}`} sx={{ mb: 3 }} />
              </Grid>
            ))}
          </Grid>
        )}
        {schema?.longDesc && (
          <MultiLineInputControl
            title="Description"
            subtitle="(Introduce briefly about yourself and your offering)"
            name="longDesc"
            placeholder="Briefly describe the service offered"
            rows={5}
            totalChars={1000}
            wsx={{ mt: 3, width: "100%" }}
          />
        )}

        {schema?.wordDelivery?.length > 0 && (
          <>
            <RowBox sx={{ mb: 1, mt: 3 }}>
              <Typography sx={{ width: 150, mr: 3 }} variant="h3">
                Price
              </Typography>
              <Typography variant="h3">WordLimit</Typography>
            </RowBox>
            {schema?.wordDelivery?.map((s, i) => (
              <WordLimitItem schema={s} name={`wordDelivery.${i}`} />
            ))}
          </>
        )}

        <RowBox sx={{ mt: 3 }}>
          {schema?.singlePrice && <SinglePrice title="Price" name="price" />}
          {schema?.revisions && (
            <SelectController
              title="Total number of revisions"
              name="noOfRevisions"
              placeholder="Select No of Revisions"
              options={revisions}
              sx={{ width: 150 }}
            />
          )}

          {schema?.delivery && (
            <SelectController
              title="Delivery Time"
              name="deliveryTime"
              placeholder="Select Delivery Time"
              options={deliveryTimes}
              wsx={{ ml: 3 }}
              sx={{ width: 150 }}
              afterText="Days"
            />
          )}
        </RowBox>

        {schema?.rushDelivery && (
          <RowBox sx={{ mt: 3 }}>
            <SinglePrice name="rushDeliveryFee" title="Rush Delivery Fee" />
            <SelectController
              title="Rush Delivery Time"
              name="rushDeliveryTime"
              placeholder="Select Delivery Time"
              options={rushDeliveryTimes}
              wsx={{ ml: 3 }}
              sx={{ width: 150 }}
              afterText="Hrs"
            />
          </RowBox>
        )}

        {schema?.duration && (
          <DurationItem
            schema={schema.duration}
            title="Price"
            wsx={{ mt: 3 }}
          />
        )}
        {schema?.availability && (
          <AvailabilityPicker
            name="availability"
            title="Availability"
            wsx={{ mt: 3 }}
          />
        )}
        <RowBox>
          {schema?.country && (
            <CountryPicker
              title="Country"
              wsx={{ mt: 3, mr: 3, minWidth: 100 }}
            />
          )}
          {schema?.state && (
            <StatePicker title="State" wsx={{ mt: 3, minWidth: 100 }} />
          )}
        </RowBox>
        {schema?.languages && (
          <LanguagePicker title="Languages Known" wsx={{ mt: 3 }} />
        )}
        {schema?.file && <ProductFile sx={{ mt: 3 }} />}
        <RowBox fullWidth center sx={{ mt: 5, mb: 3 }}>
          <Button
            variant="contained"
            onClick={props?.onSubmit}
            sx={{ alignSelf: "center" }}
          >
            Save Changes
          </Button>
        </RowBox>
      </Grid>
    </Grid>
  );
};

export const CategoryForm = (props) => {
  const { schema } = props;
  const form = useFormContext();

  const Label = styled(Typography)(() => ({
    flex: 0.1,
    textAlign: "end",
    marginRight: 20,
  }));

  return (
    <ColBox fullWidth>
      <InputField
        name="displayName"
        title="Category Name"
        placeholder="Enter cateogry name"
        wsx={{ width: "100%", maxWidth: 300
       }}
      />
      <Typography variant="h3" sx={{ mt: 3, mb: 1 }}>
        Set Commission
      </Typography>
      <RowBox sx={{ alignItems: "flex-start" }}>
        <InputField name="commissionPercent" wsx={{ maxWidth: 120, mr: 0.4 }} />
        <RowBox sx={{ width: "auto", mt: 0.4 }}>
          <Typography>%</Typography>
          <Typography sx={{ mx: 2 }} variant="h2">
            +
          </Typography>
          <Typography sx={{ mr: 0.4 }}>$</Typography>
        </RowBox>
        <InputField name="commissionFixed" wsx={{ maxWidth: 120 }} />
      </RowBox>

      <Typography variant="h3" sx={{ mt: 3, mb: 1 }}>
        Set Fee
      </Typography>
      <RowBox sx={{ width: "100%" }}>
        <Label>Fee Name</Label>
        <InputField name="feeName" wsx={{ maxWidth: 200, flex: 0.8 }} />
      </RowBox>
      <RowBox sx={{ mt: 2, alignItems: 'flex-start' }}>
        <Label sx={{mt: 1}}>Fee</Label>
        <RowBox sx={{ flex: 0.8, alignItems: 'flex-start' }}>
          <Typography sx={{ mr: 0.4, mt: 1 }}>$</Typography>
          <InputField name="fee" wsx={{ maxWidth: 120 }} />
        </RowBox>
      </RowBox>
    </ColBox>
  );
};

const countryCodes = [
  {
    name: "+ 91",
    value: "91",
  },
  {
    name: "+ 65",
    value: "65",
  },
  {
    name: "+ 1",
    value: "1",
  },
];
