import React, { useState, useContext } from "react";
import { Typography } from "@mui/material";
import { Container, ProgressIndicator } from "../components/ui";

import { useNavigate } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import { UserSearchContext } from "../services/context";
import { UsersTable } from "../components/tables";
import { useAlert } from "../components/alert";

export default function UserList(props) {
  const { type } = props;
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [q, country, state, profession] = useContext(UserSearchContext);

  const alert = useAlert();

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchUsers, suspendUser } = useContext(APIContext);
  const { isLoading, data: users } = useQuery(
    ["users", { page, type, q, country, state, profession, sort }],
    () => fetchUsers(page, type, q, country, state, profession, sort),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const suspendMutation = useMutation(
    ({ userId, status }) => suspendUser(userId, status),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users"]);
        toast.success("Successfully saved changes");
      },
    }
  );

  const viewHandler = (user) => {
    navigate(`/user-detail/${user.id}`);
  };

  const suspendHandler = (user) => {
    alert(
      "Confirmation",
      `Are you sure to ${user?.isSuspended ? "suspend" : "unsuspend"} user ${
        user.fullname
      } ?`,
      () => {
        suspendMutation.mutate({
          userId: user?.id,
          status: !user.isSuspended,
        });
      }
    );
  };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Users: ${
        users?.totalResults || 0
      }`}</Typography>
      <UsersTable
        data={users}
        onSelect={viewHandler}
        onSuspend={suspendHandler}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      {(isLoading || suspendMutation.isLoading) && <ProgressIndicator />}
    </Container>
  );
}
