import React, { useContext, useState } from "react";
import { Container, ProgressIndicator } from "../components/ui";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { useNavigate } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { PostModal } from "../components/app-ui";
import { MediaTable } from "../components/tables";
import { MediaSearchContext } from "../services/context";
import { useAlert, usePhoto } from "../components/alert";

export default function PhotoList(props) {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [media, setMedia] = useState();
  const [shouldShowPost, showPost] = useState(false);
  const { type } = props;
  const alert = useAlert()
  const showPhoto = usePhoto()
  const q = useContext(MediaSearchContext);

  const queryClient = useQueryClient();
  const { fetchMedia, deletePost } = useContext(APIContext);
  const { isLoading, data: photos } = useQuery(
    ["media", { type, page, q, sort }],
    () => fetchMedia(type, page, q, sort),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const deletePostMutation = useMutation((postId) => deletePost(postId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("posts");
      queryClient.invalidateQueries("media");
      toast.success("Successfully deleted post");
    },
  });

  const deleteHandler = (media) => {
    alert("Confirmation", "Are you sure to delete this post?", () => {
      deletePostMutation.mutate(media?.post?.id);
    })
  };

  const viewPostHandler = (media) => {
    setMedia(media);
    showPost(true);
  };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Images: ${
        photos?.totalResults || 0
      }`}</Typography>
      <MediaTable
        data={photos}
        page={page}
        onPageChange={setPage}
        onSort={(s) => {
          setSort(s);
          setPage(0);
        }}
        onView={showPhoto}
        onViewPost={viewPostHandler}
        onDelete={deleteHandler}
      />
      <PostModal
        show={shouldShowPost}
        onClose={() => showPost(false)}
        post={media?.post}
      />
      {isLoading && deletePostMutation.isLoading && <ProgressIndicator />}
    </Container>
  );
}
