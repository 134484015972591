import mime from "mime";
import { Controller, useFormContext } from "react-hook-form";
import { Typography } from "@mui/material";
import { ColBox, Error } from "../components/ui";

import data from "../data/countries+states";
import _, { update } from "lodash";
import { v4 as uuidv4 } from 'uuid';

const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");
const utc = require("dayjs/plugin/utc");
const isToday = require("dayjs/plugin/isToday");
const isYesterday = require("dayjs/plugin/isYesterday");

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isToday)
dayjs.extend(isYesterday)

export const formatCounts = (value) => {
  if (value < 1e3) return value;
  if (value >= 1e3 && value < 1e6) return +(value / 1e3).toFixed(1) + "K";
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(1) + "M";
  if (value >= 1e9 && value < 1e12) return +(value / 1e9).toFixed(1) + "B";
  if (value >= 1e12) return +(value / 1e12).toFixed(1) + "T";
};

const generateUuid = () => {
  return uuidv4();
};

/*** File Related - BEGIN ***/
export const fileName = (uri) => {
  return uri?.split("/").pop();
};

const getExtension = (filename) => {
  return filename.split(".").pop();
};

export const getMimeType = (filename) => {
  return mime.getType(getExtension(filename));
};

/*** File Related - END ***/

/*** Time Related - BEGIN  ****/
export const timeAgo = (date) => {
  return dayjs().to(dayjs(date));
};

export const timeAgoShort = (date) => {
  const end = dayjs();
  const start = dayjs(date);
  const diff = end.diff(start); //in millseconds
  const duration = dayjs.duration(diff);
  if (duration.years() > 0) {
    return duration.years() + "y";
  } else if (duration.months() > 0) {
    return duration.months() + "mo";
  } else if (duration.days() > 0) {
    return duration.days() + "d";
  } else if (duration.hours() > 0) {
    return duration.hours() + "h";
  } else if (duration.minutes() > 0) {
    return duration.minutes() + "m";
  } else {
    return "now";
  }
};

export const timeIntervalsInaDay = () => {
  let date = dayjs("1973-07-07", "YYYY-MM-DD").startOf("date"); //Fix a single day for all the intervals
  const values = [];
  for (let i = 0; i < 48; i++) {
    values.push({
      name: date.format("HH:mm"),
      value: date.utc().format(),
    });
    date = date.add(30, "minute");
  }
  return values;
};

export const toUTCString = (date) => {
  if (!date) return null;
  return dayjs(date)?.utc().format();
};

export const timeFormat = (timeString) => {
  if (!timeString) return "";
  return dayjs(timeString).format("h:mmA");
};

export const timeString = (time) => {
  return dayjs(time || dayjs()).format("h:mm A");
};

export const dateString = (date) => {
  return dayjs(date || dayjs()).format("MMMM D, YYYY");
};

export const dateTimeString = (date) => {
  return dayjs(date).format("MMM D, YYYY h:mm A");
};

export const lastMonth = () => {
  return dayjs().subtract(1, "month");
};

export const lastWeek = () => {
  return dayjs().subtract(1, "week");
};

export const last6Months = () => {
  return dayjs().subtract(6, "month");
};

export const lastYear = () => {
  return dayjs().subtract(1, "year");
};

export const toLocalDate = (utc) => {
  let date = new Date(utc);
  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  );
  return dayjs(milliseconds)
}

export const collateMessagesByDate = (messages) => {
  if (!messages || messages.length < 1) return messages;

  let last;
  const newMessages = []

  for (let i = 0; i < messages.length; i++) {
    const dayi = toLocalDate(messages[i].createdAt);
    if (!dayi.isSame(last, "day")) {
      newMessages.push({
        id: generateUuid(),
        sectionDate: messages[i].createdAt,
      });
      last = toLocalDate(messages[i].createdAt);
    }
    newMessages.push(messages[i]);
  }
  
  return newMessages;
};

export const chatHeaderFormattedDate = (date) => {
  const d = toLocalDate(date);
  if (d.isToday()) return "Today";
  if (d.isYesterday()) return "Yesterday";
  return d.format("MMMM D, YYYY");
};

/*** Time Related - END  ****/

/*** Country/City/State - BEGIN  ****/
export const getAllCountries = () => {
  return data.map((country) => country.name);
};

export const getStatesOfCountry = (name) => {
  const country = data.find((c) => c.name === name);
  return country?.states.map((s) => s.name);
};

export const getCountryCode = (name) => {
  const country = data.find((c) => c.name === name);
  return country.iso2;
};

export const getStateCode = (country, state) => {
  const _country = data.find((c) => c.name === country);
  const _state = _country?.states.find((s) => s.name === state);
  return _state?.state_code;
};
/*** Country/City/State - END  ****/

/*** Category Schema - BEGIN  ****/
export const getSchemaType = (
  categories,
  mainCategory,
  category,
  subCategory
) => {
  let node = _.find(categories?.mainCategories, { name: mainCategory });
  node = _.find(node?.categories, { name: category }) || node;
  node = _.find(node?.subCategories, { name: subCategory }) || node;
  return node?.productType;
};

export const getSchema = (categories, mainCategory, category, subCategory) => {
  const type = getSchemaType(categories, mainCategory, category, subCategory);
  if (type) {
    return categories?.schema.find((s) => s.name === type);
  }
};

export const getNode = (categories, mainCategory, category, subCategory) => {
  let node = _.find(categories?.mainCategories, { name: mainCategory });
  if (node && category) {
    node = _.find(node?.categories, { name: category });
  }
  if (node && subCategory) {
    node = _.find(node?.subCategories, { name: subCategory });
  }
  return node;
};

export const CattoOptions = (categories, selectObject) => {
  return categories?.map((c) => ({
    name: c.displayName,
    value: selectObject ? c:  c.id,
  }));
};

/*** Category Schema - END  ****/

/*** Grid Table Related - BEGIN */

export const sortModelToString = (model) => {
  return model
    .map((m) => `${m.sort === "desc" ? "-" : ""}${m.field}`)
    ?.join(",");
};

/*** Grid Table Related - END */

/*** API Related - BEGIN */
export const getDirtyFields = (values, dirtyFields) => {
  let updatedFields = {};
  for (let key in dirtyFields) {
    // console.log("Dirty", dirtyFields)
    // if (Array.isArray(dirtyFields[key])) {
    //   if (dirtyFields[key].some(f => {console.log(f); return f === true})) {
    //     updatedFields[key] = values[key]
    //   }
    //   continue;
    // }
    // if (dirtyFields[key] == true) {
    // updatedFields[key] = values[key];
    // }
    updatedFields[key] = values[key];
  }

  return Object.keys(updatedFields)?.length > 0 ? updatedFields : null;
};

/*** API Related - END */

/*** Functionaliy Related - BEGIN */

export const typeFromMedia = (media) => {
  if (media instanceof File) {
    return media?.type?.split("/")?.[0];
  } else {
    //For objects from backend
    return media?.mediaType;
  }
};

export const flattenComments = (comments) => {
  if (!comments) return [];
  let result = [];
  for (let i = 0; i < comments.length; i++) {
    const comment = comments[i];
    result.push(comment);
    if (comment.comments?.length > 0) {
      result = result.concat(flattenComments(comment.comments));
    }
  }
  return result;
};

/*** Functionaliy Related - END */

/***  UI Related - BEGIN */

const isClassComponent = (Component) =>
  Boolean(Component?.prototype?.isReactComponent);

export const applyController = (WrappedComponent, displayName) => {
  return Object.assign(
    (props, forwardRef) => {
      const {
        control,
        formState: { errors },
        defaultValue,
      } = useFormContext();
      const { name, children, hideTitle, hideError, ...rest } = props;

      const Title = (props) =>
        props?.title ? (
          <Typography variant="h3" sx={{ mb: 0.8 }}>
            {props?.title}
          </Typography>
        ) : null;

      return (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const NewComponent = isClassComponent(WrappedComponent) ? (
              <>
                {!(hideTitle === true) && <Title title={props?.title} />}
                <WrappedComponent
                  ref={forwardRef}
                  {...rest}
                  value={value}
                  onChange={onChange}
                >
                  {children}
                </WrappedComponent>
                <Error error={error} />
              </>
            ) : (
              <>
                {!(hideTitle === true) && <Title title={props?.title} />}
                <WrappedComponent {...rest} value={value} onChange={onChange}>
                  {children}
                </WrappedComponent>
                <Error error={error} />
              </>
            );
            return NewComponent;
          }}
        />
      );
    },
    {
      displayName: displayName,
    }
  );
};

/***  UI Related - END */
