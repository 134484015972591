import React from "react";
import { Navigate, useLocation, Route, Routes } from "react-router";
import { useSelector } from "react-redux";

import App from "./App";
import Login from "./pages/login";
import User from "./pages/users";
import Post from "./pages/post";
import NewPost from "./pages/post-new";
import PostList from "./pages/post-list";
import PostTrash from "./pages/post-trash";
import PostDetail from "./pages/post-detail";
import Media from "./pages/media";
import MediaList from "./pages/media-list";
import Group from "./pages/group";
import GroupList from "./pages/group-list";
import GroupDetail from "./pages/group-details";
import GroupInfo from "./pages/group-info";
import GroupPosts from "./pages/group-posts";
import GroupMedia from "./pages/group-media";
import GroupComments from "./pages/group-comment";
import GroupMembers from "./pages/group-member";
import CreateGroup from "./pages/group-create";
import UserList from "./pages/user-list";
import UserDetail from "./pages/user-detail";
import UserInfo from "./pages/user-info";
import UserPosts from "./pages/user-posts";
import UserMedia from "./pages/user-media";
import UserComments from "./pages/user-comments";
import UserFollowers from "./pages/user-followers";
import UserFollowing from "./pages/user-following";
import Report from "./pages/report";
import ReportPostList from "./pages/report-post";
import ReportUserList from "./pages/report-user";
import BlockedUserList from "./pages/report-block";
import Activity from "./pages/activity";
import Dashboard from "./pages/dashboard";
import Conversations from "./pages/conversations";
import Services from "./pages/eCommerce/services";
import ServiceList from "./pages/eCommerce/service-list";
import ServiceDetail from "./pages/eCommerce/service-detail";
import Orders from "./pages/eCommerce/orders";
import OrderList from "./pages/eCommerce/order-list";
import OrderDetail from "./pages/eCommerce/order-detail";
import Categories from "./pages/eCommerce/categories";
import CategoryInfo from "./pages/eCommerce/category-info";
import Sellers from "./pages/eCommerce/sellers";
import SellerList from "./pages/eCommerce/seller-list";
import SellerDetail from "./pages/eCommerce/seller-detail";
import SellerInfo from "./pages/eCommerce/seller-info";
import SellerServices from "./pages/eCommerce/seller-services";
import SellerOrders from "./pages/eCommerce/seller-orders";
import Customers from "./pages/eCommerce/customers";
import CustomerList from "./pages/eCommerce/customer-list";
import CustomerDetail from "./pages/eCommerce/customer-detail";
import Reviews from "./pages/eCommerce/reviews";
import ReviewList from "./pages/eCommerce/review-list";
import ReviewDetail from "./pages/eCommerce/review-detail";
import Settings from "./pages/settings";
import CategorySettings from "./pages/setting-category";

const AuthRoute = (props) => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  if (!auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return props?.children;
};

export default function Root() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthRoute>
            <App />
          </AuthRoute>
        }
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="user" element={<User />}>
          <Route index element={<Navigate to="all" />} />
          <Route path="all" element={<UserList type="all" />} />
          <Route path="suspended" element={<UserList type="suspended" />} />
        </Route>
        <Route path="user-detail/:userId" element={<UserDetail />}>
          <Route index element={<Navigate to="info" replace={true} />} />
          <Route path="info" element={<UserInfo />} />
          <Route path="posts" element={<UserPosts />} />
          <Route path="media" element={<UserMedia />} />
          <Route path="comments" element={<UserComments />} />
          <Route path="followers" element={<UserFollowers />} />
          <Route path="following" element={<UserFollowing />} />
        </Route>
        <Route path="post" element={<Post />}>
          <Route index element={<Navigate to="list" />} />
          <Route path="new" element={<NewPost />} />
          <Route path="list" element={<PostList />} />
          <Route path="trash" element={<PostTrash />} />
        </Route>
        <Route path="post-detail" element={<PostDetail />} />
        <Route path="post-edit" element={<NewPost />} />
        <Route path="media" element={<Media />}>
          <Route index element={<Navigate to="photo" />} />
          <Route path="photo" element={<MediaList type="image" />} />
          <Route path="video" element={<MediaList type="video" />} />
        </Route>
        <Route path="group" element={<Group />}>
          <Route index element={<Navigate to="all" />} />
          <Route path="all" element={<GroupList type="all" />} />
          <Route path="public" element={<GroupList type="public" />} />
          <Route path="hidden" element={<GroupList type="private" />} />
        </Route>
        <Route path="group-new" element={<CreateGroup />}></Route>
        <Route path="group-detail/:groupId" element={<GroupDetail />}>
          <Route index element={<Navigate to="info" replace={true} />} />
          <Route path="info" element={<GroupInfo />} />
          <Route path="posts" element={<GroupPosts />} />
          <Route path="media" element={<GroupMedia />} />
          <Route path="comments" element={<GroupComments />} />
          <Route path="members" element={<GroupMembers />} />
        </Route>
        <Route path="report" element={<Report />}>
          <Route index element={<Navigate to="post" />} />
          <Route path="post" element={<ReportPostList />} />
          <Route path="user" element={<ReportUserList />} />
          <Route path="block" element={<BlockedUserList />} />
        </Route>
        <Route path="activity" element={<Activity />} />
        <Route path="/ecommerce/services" element={<Services />}>
          <Route index element={<Navigate to="all" />} />
          <Route path="all" element={<ServiceList status="all" />} />
          <Route
            path="published"
            element={<ServiceList status="published" />}
          />
          <Route path="pending" element={<ServiceList status="pending" />} />
          <Route path="rejected" element={<ServiceList status="rejected" />} />
          <Route
            path="suspended"
            element={<ServiceList status="suspended" />}
          />
        </Route>
        <Route path="chat" element={<Conversations />} />
        <Route path="service-detail/:id" element={<ServiceDetail />}></Route>
        <Route path="/ecommerce/orders" element={<Orders />}>
          <Route index element={<Navigate to="all" />} />
          <Route path="all" element={<OrderList />} />
          <Route path="pending" element={<OrderList status="pending" />} />
          <Route path="completed" element={<OrderList status="completed" />} />
          <Route path="cancelled" element={<OrderList status="cancelled" />} />
          <Route path="failed" element={<OrderList status="failed" />} />
        </Route>
        <Route path="order-detail/:id" element={<OrderDetail />}></Route>
        <Route path="/ecommerce/category" element={<Categories />} />
        <Route path="/ecommerce/category-edit" element={<CategoryInfo />} />
        <Route path="/ecommerce/seller" element={<Sellers />}>
          <Route index element={<Navigate to="all" />} />
          <Route path="all" element={<SellerList />} />
          <Route path="pending" element={<SellerList status="pending" />} />
          <Route path="rejected" element={<SellerList status="rejected" />} />
          <Route path="suspended" element={<SellerList status="suspended" />} />
        </Route>
        <Route path="seller-detail/:id" element={<SellerDetail />}>
          <Route index element={<Navigate to="info" />} />
          <Route path="info" element={<SellerInfo />} />
          <Route path="services" element={<SellerServices />} />
          <Route path="orders" element={<SellerOrders />} />
        </Route>
        <Route path="/ecommerce/customer" element={<Customers />}>
          <Route index element={<Navigate to="all" />} />
          <Route path="all" element={<CustomerList />} />
        </Route>
        <Route
          path="/ecommerce/customer-detail/:id"
          element={<CustomerDetail />}
        />
        <Route path="/ecommerce/review" element={<Reviews />}>
          <Route index element={<Navigate to="all" />} />
          <Route path="all" element={<ReviewList />} />
          <Route path="approved" element={<ReviewList status="approved" />} />
          <Route path="pending" element={<ReviewList status="pending" />} />
        </Route>
        <Route path="/ecommerce/review-detail/:id" element={<ReviewDetail />} />
        <Route path="/settings" element={<Settings />}>
          <Route index element={<Navigate to="category" />} />
          <Route path="category" element={<CategorySettings />} />
          <Route path="tax" element={<></>} />
          <Route path="password" element={<></>} />
          <Route path="role" element={<></>} />
          <Route path="site" element={<></>} />
        </Route>
      </Route>

      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

const NoMatch = () => {
  return <div>Nothing to show</div>;
};

const Dummy = () => {
  return <div>Hello there</div>;
};

const Mummy = () => {
  return <div>Group Home</div>;
};
