import React, { useState, useContext } from "react";
import { Grid, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { toast } from "react-toastify";
import {
  Container,
  PageTitle,
  ProgressIndicator,
  Tabs
} from "../../components/ui";
import { useAlert } from "../../components/alert";

import { SellerSummary } from "../../components/ecommerce-ui";

export default function SellerDetail() {
  const { id } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();
  const alert = useAlert()

  const queryClient = useQueryClient();
  const { fetchSeller, removeUserProfile, updateSeller, updateSellerBadge } =
    useContext(APIContext);

  const { isLoading, data: seller } = useQuery(
    ["seller", { id }],
    () => fetchSeller(id),
    {
      select: (data) => data?.data,
    }
  );

  const removeProfileMutation = useMutation(
    () => removeUserProfile(seller?.user?.id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["seller", { id }]);
        toast.success("Successfully removed profile image");
      },
    }
  );

  const updateMutation = useMutation((data) => updateSeller(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["seller", { id }]);
      toast.success("Successfully saved changes");
    },
  });

  const badgeMutation = useMutation((value) => updateSellerBadge(seller?.user?.id, value), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["seller", { id }]);
      toast.success("Successfully saved changes");
    },
  });

  const menu = [
    {
      name: "Details",
      link: "info",
    },
    {
      name: `Services Offered`,
      link: "services",
    },
    {
      name: `Orders Received`,
      link: "orders",
    },
  ];

  const removeProfileHandler = () => {
    removeProfileMutation.mutate();
  };

  const viewHandler = () => {
    navigate(`/user-detail/${seller?.user?.id}`);
  };

  const badgeHandler = () => {
    badgeMutation.mutate(!seller?.user?.isVerified);
  };

  const approveHandler = () => {
    alert("Confirmation", "Are you sure to Approve the seller?", () => {
      updateMutation.mutate({
        status: "approved"
      });
    })
  };

  const rejectHandler = () => {
    alert("Confirmation", "Are you sure to Reject the seller?", () => {
      updateMutation.mutate({
        status: "rejected"
      });
    })
  };

  const suspendHandler = () => {
    const status = seller?.status
    alert("Confirmation", `Are you sure to ${seller?.status === 'suspended' ? 'Unsuspend' : 'Suspend'} the seller?`, () => {
      updateMutation.mutate({
        status: "suspended"
      });
    })
  };

  return (
    <Container sx={{ mt: 1.25, pb: 2, pr: 2, height: "auto" }}>
      <PageTitle title={seller?.fullname} back />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            borderRight: `1px solid ${theme.palette.info.light}`,
            display: "flex",
            flex: 1,
          }}
        >
          {seller && (
            <SellerSummary
              sx={{ mt: 1 }}
              {...seller}
              onRemoveImage={removeProfileHandler}
              onView={viewHandler}
              onBadge={badgeHandler}
              onSuspend={suspendHandler}
              onApprove={approveHandler}
              onReject={rejectHandler}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={10}>
          <Tabs menu={menu} />
          <Outlet />
        </Grid>
      </Grid>
      {(removeProfileMutation.isLoading ||
        updateMutation.isLoading ||
        badgeMutation.isLoading ||
        isLoading) && <ProgressIndicator />}
    </Container>
  );
}
