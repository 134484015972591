import React, { useContext, useState } from "react";
import { Outlet } from "react-router";

import { Box } from "@mui/material";

import {
  RowBox,
  ColBox,
  SearchBar,
  SplitTabs,
  Container,
} from "../components/ui";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { MediaSearchContext } from "../services/context";

export default function Media() {
  const [q, setQuery] = useState();
  const { fetchMedia } = useContext(APIContext);

  const { data: photos } = useQuery(
    ["media", { type: "image", page: 0 }],
    () => fetchMedia("image", 0),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const { data: videos } = useQuery(
    ["media", { type: "video", page: 0 }],
    () => fetchMedia("video", 0),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const menu = [
    {
      name: `All Images (${photos?.totalResults || 0})`,
      link: "photo",
    },
    {
      name: `All Videos (${videos?.totalResults || 0})`,
      link: "video",
    },
  ];

  return (
    <Container sx={{ mt: 1.25 }}>
      <RowBox
        sx={{
          paddingRight: 2,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
      >
        <SplitTabs
          menu={menu}
          hsx={{
            justifyContent: "flex-start",
            width: "100%",
            borderBottom: "none",
          }}
        />
        <SearchBar
          placeholder="Search Users"
          sx={{ mr: 2 }}
          value={q}
          onChange={setQuery}
        />
      </RowBox>
      <MediaSearchContext.Provider value={q?.length > 2 ? q : null}>
        <Outlet />
      </MediaSearchContext.Provider>
    </Container>
  );
}
