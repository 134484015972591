import React, { useState, useContext } from "react";
import { Grid, useTheme } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import { GroupSummary } from "../components/app-ui";
import {
  Container,
  PageTitle,
  Tabs,
  ProgressIndicator,
} from "../components/ui";
import { useAlert, useCrop } from "../components/alert";

export default function GroupDetail() {
  const { groupId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const alert = useAlert()
  const cropImage = useCrop()

  const queryClient = useQueryClient();
  const {
    fetchGroup,
    updateGroup,
    removeGroupProfile,
    deleteGroup,
    fetchGroupActivity,
    fetchGroupMedia,
    fetchGroupComments,
    fetchGroupMembers
  } = useContext(APIContext);

  const { isLoading, data: group } = useQuery(
    ["group", { id: groupId }],
    () => fetchGroup(groupId),
    {
      select: (data) => data?.data,
    }
  );

  const { data: groupPosts } = useQuery(
    ["posts", { type: "group", content: "post", id: groupId, page: 0 }],
    () => fetchGroupActivity(groupId, 0),
    {
      select: (data) => data?.data,
    }
  );

  const { data: groupMedia } = useQuery(
    ["photos", { type: "group", content: "media", id: groupId, page: 0 }],
    () => fetchGroupMedia(groupId, 0),
    {
      select: (data) => data?.data,
    }
  );

  const { data: groupComments } = useQuery(
    ["posts", { type: "group", content: "comment", id: groupId, page: 0 }],
    () => fetchGroupComments(groupId, 0),
    {
      select: (data) => data?.data,
    }
  );

  const { data: groupMembers } = useQuery(
    ["members", { groupId, page: 0 }],
    () => fetchGroupMembers(groupId, 0),
    {
      select: (data) => data?.data,
    }
  );

  const updateMutation = useMutation((data) => updateGroup(groupId, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["group", { id: groupId }]);
      toast.success("Successfully updated group");
    },
  });

  const removeProfileMutation = useMutation(() => removeGroupProfile(groupId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["group", { id: groupId }]);
      toast.success("Successfully removed profile image");
    },
  });

  const deleteGroupMutation = useMutation(() => deleteGroup(groupId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["group"]);
      toast.success("Successfully deleted group");
      navigate("/group");
    },
  });

  const profileChangeHandler = (image) => {
    cropImage(image, (cropped) => {
      updateMutation.mutate({
        profile: cropped,
      });
    })
  };

  const removeProfileHandler = () => {
    removeProfileMutation.mutate();
  };

  const deleteGroupHandler = () => {
    alert("Confirmation", "Are you sure to delete this group?", () => {
      deleteGroupMutation.mutate();
    })
  };

  const menu = [
    {
      name: "Group Details",
      link: "info",
    },
    {
      name: `All Posts (${groupPosts?.totalResults || 0})`,
      link: "posts",
    },
    {
      name: `Images/Videos (${groupMedia?.totalResults || 0})`,
      link: "media",
    },
    {
      name: `Comments (${groupComments?.totalResults || 0})`,
      link: "comments",
    },
    {
      name: `Members (${groupMembers?.totalResults || 0})`,
      link: "members",
    },
  ];

  return (
    <Container sx={{ mt: 1.25, pb: 2, pr: 2 }}>
      <PageTitle title={group?.name} back />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={2}
          sx={{
            borderRight: `1px solid ${theme.palette.info.light}`,
            display: "flex",
            flex: 1,
          }}
        >
          {group && (
            <GroupSummary
              sx={{ mt: 1 }}
              onProfileChange={profileChangeHandler}
              onRemoveProfile={removeProfileHandler}
              onDelete={deleteGroupHandler}
              {...group}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <Tabs menu={menu} />
          <Outlet />
        </Grid>
      </Grid>
      {(updateMutation.isLoading ||
        removeProfileMutation.isLoading ||
        deleteGroupMutation.isLoading ||
        isLoading) && <ProgressIndicator />}
    </Container>
  );
}
