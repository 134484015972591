import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import { RowBox, ColBox, Container, ProgressIndicator } from "../components/ui";
import { UserProfile } from "../components/app-ui";

/* TOD0: Pagination, multiple followers wrap to next line */

export default function UserFollowing() {
  const { userId } = useParams();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const { fetchUserFollowing } = useContext(APIContext);
  const { isLoading, data, isFetching } = useQuery(
    ["following", { id: userId, page }],
    () => fetchUserFollowing(userId, page),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const userSelectHandler = (user) => {
    navigate(`/user-detail/${user?.id}`);
  };

  return (
    <Container sx={{ p: 2 }}>
      <ColBox>
        <Typography
          variant="h4Black"
          sx={{ mb: 2, mr: "auto" }}
        >{`Total Following: ${data?.totalResults || 0}`}</Typography>
        <RowBox sx={{ flexWrap: "wrap" }}>
          {data?.results?.map((f) => (
            <UserProfile
              user={f?.user}
              onClick={() => userSelectHandler(f?.user)}
              sx={{ padding: 1 }}
            />
          ))}
        </RowBox>
      </ColBox>
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}
