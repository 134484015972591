import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import { RowBox, ProgressIndicator, Container } from "../components/ui";

import { PostModal } from "../components/app-ui";
import { MediaTable } from "../components/tables";
import { useAlert, usePhoto } from "../components/alert";

export default function UserMedia() {
  const { userId } = useParams();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [media, setMedia] = useState();
  const [shouldShowPost, showPost] = useState(false);

  const alert = useAlert();
  const showPhoto = usePhoto()

  const { fetchUserMedia, deletePost } = useContext(APIContext);
  const { isLoading, data, isFetching } = useQuery(
    ["posts", { content: "media", id: userId, page, sort }],
    () => fetchUserMedia(userId, page, sort),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const queryClient = useQueryClient();
  const deletePostMutation = useMutation((postId) => deletePost(postId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("posts");
      toast.success("Successfully deleted post");
    },
  });

  const deleteHandler = (media) => {
    if (media.post) {
      alert("Confirmation", "Are you sure to delete this post?", () => {
        deletePostMutation.mutate(media.post?.id);
      });
    } else {
      toast.error(
        "Unknown error. Not able to delete this post. Contact system admin."
      );
    }
  };

  const viewPostHandler = (media) => {
    if (media?.post) {
      setMedia(media);
      showPost(true);
    } else {
      toast.error(
        "Unknown error. Not able to view this post. Contact system admin."
      );
    }
  };

  return (
    <Container sx={{ height: "calc(100% - 33px)" }}>
      <RowBox sx={{ pl: 2, my: 1, boxSizing: "border-box" }}>
        <Typography
          variant="h4Black"
          sx={{ my: 2, mr: "auto" }}
        >{`Total media: ${data?.totalResults || 0}`}</Typography>
      </RowBox>
      <MediaTable
        data={data}
        page={page}
        onPageChange={setPage}
        onView={showPhoto}
        onViewPost={viewPostHandler}
        onDelete={deleteHandler}
        onSort={(s) => {
          setSort(s);
          setPage(0);
        }}
      />
      <PostModal
        show={shouldShowPost}
        onClose={() => showPost(false)}
        post={media?.post}
      />
      {(isLoading || deletePostMutation.isLoading) && <ProgressIndicator />}
    </Container>
  );
}
