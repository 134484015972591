import React, { useState, useContext, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { toast } from "react-toastify";

import { useNavigate, useLocation } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { postSchema } from "../utils/validation";

import { CreatePost } from "../components/app-ui";
import {
  PageTitle,
  ProgressIndicator,
  Container,
} from "../components/ui";
import { getDirtyFields } from "../utils/utils";
import { useAlert } from "../components/alert";

export default function NewPost() {
  const location = useLocation();
  const post = location.state;
  const alert = useAlert();

  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(postSchema),
    defaultValues: {
      content: post?.content,
      media: post?.media,
      notes: post?.notes,
      postTo: post?.group ? "group" : "timeline",
      group: post?.group
        ? { label: post?.group?.name, value: post?.group?.id }
        : null,
    },
  });

  const queryClient = useQueryClient();
  const { createPost, editPost } = useContext(APIContext);

  const postMutation = useMutation((data) => createPost(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("posts");
      toast.success("Post Success!");
      navigate("/post");
    },
  });

  const editMutation = useMutation((data) => editPost(post?.id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("posts");
      queryClient.invalidateQueries("media");
      toast.success("Post Successfully updated!");
      navigate("/post");
    },
  });

  const dirtyFields = methods.formState.dirtyFields;
  const submitHandler = (data) => {
    const { postTo, ...values } = data

    if (post) {
      const updatedData = getDirtyFields(values, dirtyFields);
      if (updatedData) {
        alert("Confirmation", "Are you sure to edit the post?", () => {
          updatedData.media = values.media; //Always send images and video
          editMutation.mutate(updatedData);
        });
      } else {
        toast.error("No changes to save");
        return;
      }
    } else {
      alert(
        "Confirmation",
        `Are you sure to post to ${values?.group?.label || "Timeline"}`,
        () => {
          if (values?.group) {
            values.group = values?.group?.id;
          }
          postMutation.mutate(values);
        }
      );
    }
  };

  const errorHandler = (errors) => {
    Object.values(errors).forEach((error) => {
      if (error.message) {
        toast.error(error.message);
      }
    });
  };

  return (
    <Container sx={{ backgroundColor: "white", padding: 2 }}>
      {post && <PageTitle title="Edit Post" back sx={{ mb: 2 }} />}
      <FormProvider {...methods}>
        <CreatePost isEdit={!!post} />
      </FormProvider>

      <Button
        variant="contained"
        sx={{ my: 2 }}
        onClick={methods.handleSubmit(submitHandler, errorHandler)}
      >
        {post ? "Update Post" : "Post"}
      </Button>

      {(postMutation.isLoading || editMutation.isLoading) && (
        <ProgressIndicator />
      )}
    </Container>
  );
}
