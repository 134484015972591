import { Outlet } from "react-router";
import { Container, SplitTabs } from "../components/ui";

const menu = [
  { name: "All Admin Posts", link: "list" },
  { name: "Create a New Post", link: "new" },
  { name: "Trash", link: "trash" },
];

export default function Post() {
  return (
    <Container>
      <SplitTabs menu={menu} />
      <Outlet />
    </Container>
  );
}
