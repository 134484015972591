import React, { useContext, useEffect, useState } from "react";
import { Button, styled, Typography, Grid, Input } from "@mui/material";

import { useLocation, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { toast } from "react-toastify";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  sellerBusinessRegistrationSchema,
  sellerRegistrationIndividualSchema,
  sellerTeacherRegistrationSchema,
  userSchema,
} from "../../utils/validation";
import {
  RowBox,
  ColBox,
  InputField,
  ProgressIndicator,
  CountryPicker,
  StatePicker,
  GenderPicker,
  MultiLineInputControl,
  FileSelect,
} from "../../components/ui";
import { useAlert } from "../../components/alert";

import { ProfileImage } from "../../components/app-ui";
import { getDirtyFields } from "../../utils/utils";

const LabelGrid = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const ContentGrid = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}));

const Row = (props) => (
  <>
    <LabelGrid item md={2} sm={2} xs={2} sx={{ mb: 2 }}>
      {props?.children?.[0]}
    </LabelGrid>
    <ContentGrid item md={3} sm={4} xs={10} sx={{ mb: 2 }}>
      {props?.children?.[1]}
    </ContentGrid>
    <LabelGrid item md={2} sm={2} xs={2} sx={{ mb: 2 }}>
      {props?.children?.[2]}
    </LabelGrid>
    <ContentGrid item md={3} sm={4} xs={10} sx={{ mb: 2 }}>
      {props?.children?.[3]}
    </ContentGrid>
    <Grid item xs={2}></Grid>
  </>
);

const Label = styled(Typography)(() => ({
  textAlign: "end",
  marginRight: 20,
  justifySelf: "center",
}));

const inputStyles = {
  width: "100%",
  "& .MuiOutlinedInput-input": {
    paddingTop: 1,
    paddingBottom: 1,
  },
};

export default function SellerInfo() {
  const { id } = useParams();
  const alert = useAlert();

  const queryClient = useQueryClient();
  const { fetchSeller, updateSeller } = useContext(APIContext);

  const { isLoading, data: seller } = useQuery(
    ["seller", { id }],
    () => fetchSeller(id),
    {
      select: (data) => data?.data,
    }
  );

  const updateMutation = useMutation((data) => updateSeller(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["seller", { id }]);
      toast.success("Successfully saved changes");
    },
  });

  const schema = {
    individual: sellerRegistrationIndividualSchema,
    business: sellerBusinessRegistrationSchema,
    teacher: sellerTeacherRegistrationSchema,
  };

  const methods = useForm({
    resolver: yupResolver(schema[seller?.type || "individual"]),
    defaultValues: {
      ...seller,
      gender: seller?.user?.gender,
    },
  });

  useEffect(() => {
    /* Some timing issue, form values are not loading after page reload. */
    methods.reset({
      ...seller,
      gender: seller?.user?.gender,
    });
  }, [seller]);

  const selfie = methods.watch("selfie");
  const photoId = methods.watch("photoId");
  const aoiDoc = methods.watch("aoiDoc");

  const dirtyFields = methods.formState.dirtyFields;
  const submitHandler = (values) => {
    const data = getDirtyFields(values, dirtyFields);
    if (data) {
      alert(
        "Confirmation",
        "Are you sure to update the seller profile?",
        () => {
          updateMutation.mutate(data);
        }
      );
    } else {
      toast.error("No changes to save");
    }
  };

  const errorHandler = (error) => {
    console.log(error);
  };

  const aoiDownloadHandler = () => {
    const url = seller?.aoiDoc.url;
    if (url) {
      window.open(url, "_blank");
    } else {
      toast.error("Error downloading file. Contact system administrator.");
    }
  };

  return (
    <ColBox fullWidth>
      <FormProvider {...methods}>
        <Grid container sx={{ my: 2, boxSizing: "border-box" }} spacing={1}>
          <Row>
            <Label>First Name</Label>
            <InputField name="firstname" wsx={inputStyles} />
            <Label>Last Name</Label>
            <InputField name="lastname" wsx={inputStyles} />
          </Row>
          {/* {seller?.type !== "business" && (
            <Row>
              <Label>Gender</Label>
              <GenderPicker name="gender" fullWidth />
            </Row>
          )} */}
          {seller?.type === "business" && (
            <Row>
              <Label>Authorized Signatory</Label>
              <InputField name="signatoryName" wsx={inputStyles} />
            </Row>
          )}
          {seller?.type === "business" && (
            <Row>
              <Label>Company Name</Label>
              <InputField name="companyName" wsx={inputStyles} />
              <Label>Business Name/Trade Name</Label>
              <InputField name="businessName" wsx={inputStyles} />
            </Row>
          )}
          <Row>
            <Label>Email Id</Label>
            <InputField name="email" wsx={inputStyles} />
            <Label>Contact No</Label>
            <InputField name="phoneNo" wsx={inputStyles} />
          </Row>
          <Row>
            <Label>Address</Label>
            <MultiLineInputControl
              placeholder="Address"
              variant="outlined"
              name="address"
              numberOfLines={4}
              wsx={inputStyles}
            />
          </Row>
          <Row>
            <Label>Country</Label>
            <CountryPicker variant="outlined" fullWidth />
            <Label>State</Label>
            <StatePicker variant="outlined" fullWidth />
          </Row>
          <Row>
            <Label>Zipcode</Label>
            <InputField variant="outlined" fullWidth name="zipcode" />
          </Row>
          {(seller?.type === "individual" || seller?.type === "business") && (
            <Row>
              <Label>Id</Label>
              <ColBox center>
                <ProfileImage variant="banner" image={photoId} />
                <FileSelect name="photoId" single crop>
                  <Typography
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Change Image
                  </Typography>
                </FileSelect>
              </ColBox>

              <Label>Photo Id</Label>
              <ColBox center>
                <ProfileImage variant="banner" image={selfie} />
                <FileSelect name="selfie" single crop>
                  <Typography
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Change Image
                  </Typography>
                </FileSelect>
              </ColBox>
            </Row>
          )}
          {seller?.type === "business" && (
            <Row>
              <Label>Article of Incorporation</Label>
              <ColBox>
              <Typography sx={{ mt: 1, ml: 1 }}>{aoiDoc?.name}</Typography>
                <RowBox sx={{ alignItems: "flex-start" }}>
                  <FileSelect
                    name="aoiDoc"
                    type={["application/pdf"]}
                    single
                    showError
                  >
                    <Button variant="outlined">
                      {aoiDoc ? "Re Upload" : "Upload"}
                    </Button>
                  </FileSelect>
                  {aoiDoc && (
                    <Button
                      variant="outlined"
                      sx={{ ml: 2 }}
                      onClick={aoiDownloadHandler}
                    >
                      Download
                    </Button>
                  )}
                </RowBox>
                
              </ColBox>
            </Row>
          )}
        </Grid>
        <Button
          variant="contained"
          sx={{ mt: 8, mb: 6, px: 5, alignSelf: "center" }}
          onClick={methods.handleSubmit(submitHandler, errorHandler)}
        >
          Save Changes
        </Button>
      </FormProvider>
      {(isLoading || updateMutation.isLoading) && <ProgressIndicator />}
    </ColBox>
  );
}
