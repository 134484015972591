import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router";

import { Box, Button, Typography } from "@mui/material";

import {
  RowBox,
  ColBox,
  SearchBar,
  SplitTabs,
  Container,
  InputSelect,
  CountryPick,
} from "../../components/ui";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { ServiceFilterContext } from "../../services/context";
import {
  CatSelect,
  MainCatSelect,
  SubCatSelect,
} from "../../components/ecommerce-ui";

export default function Services() {
  const [q, setQuery] = useState();
  const [availability, setAvailability] = useState();
  const [country, setCountry] = useState();
  const [mainCategory, setMainCategory] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();

  const { fetchAllProducts } = useContext(APIContext);

  const { data: allProducts } = useQuery(["products", { status: "all" }], () =>
    fetchAllProducts({ status: "all" })
  );

  const { data: published } = useQuery(
    ["products", { status: "published" }],
    () => fetchAllProducts({ status: "published" })
  );

  const { data: pending } = useQuery(["products", { status: "pending" }], () =>
    fetchAllProducts({ status: "pending" })
  );

  const { data: rejected } = useQuery(
    ["products", { status: "rejected" }],
    () => fetchAllProducts({ status: "rejected" })
  );

  const { data: suspended } = useQuery(
    ["products", { status: "suspended" }],
    () => fetchAllProducts({ status: "suspended" })
  );

  const menu = [
    {
      name: `All Services (${allProducts?.data?.totalResults || 0})`,
      link: "all",
    },
    {
      name: `Published (${published?.data?.totalResults || 0})`,
      link: "published",
    },
    {
      name: `Pending (${pending?.data?.totalResults || 0})`,
      link: "pending",
    },
    {
      name: `Rejected (${rejected?.data?.totalResults || 0})`,
      link: "rejected",
    },
    {
      name: `Suspended (${suspended?.data?.totalResults || 0})`,
      link: "suspended",
    },
  ];

  const clearFilterHandler = () => {
    setQuery();
    setCountry();
    setAvailability();
    setMainCategory();
    setCategory();
    setSubCategory();
  };

  return (
    <Container sx={{ mt: 1.25 }}>
      <ColBox
        sx={{
          pr: 2,
          pt: 0,
          pb: 2,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
        fullWidth
      >
        <RowBox>
          <SplitTabs
            menu={menu}
            hsx={{
              width: "auto",
              borderBottom: "none",
            }}
          />
          <SearchBar
            placeholder="Search User/Services"
            sx={{ ml: "auto" }}
            value={q}
            onChange={setQuery}
          />
        </RowBox>
        <RowBox sx={{ justifyContent: "center" }}>
          <Typography>Filter By:</Typography>

          <InputSelect
            options={[
              { name: "Available", value: "available" },
              { name: "Unavailable", value: "unavailable" },
            ]}
            placeholder="Availability"
            value={availability}
            onChange={setAvailability}
            fullWidth={false}
            sx={{ mx: 2, minWidth: 150 }}
          />

          <CountryPick
            value={country}
            onChange={setCountry}
            fullWidth={false}
            sx={{ mr: 2, minWidth: 150 }}
          />

          <MainCatSelect
            value={mainCategory}
            onChange={(v) => {
              setMainCategory(v);
            }}
            fullWidth={false}
            sx={{ mr: 2, minWidth: 150 }}
          />

          {mainCategory && (
            <CatSelect
              value={category}
              mainCategory={mainCategory}
              onChange={setCategory}
              fullWidth={false}
              sx={{ mr: 2, minWidth: 150 }}
            />
          )}

          {category && (
            <SubCatSelect
              mainCategory={mainCategory}
              category={category}
              value={subCategory}
              onChange={setSubCategory}
              fullWidth={false}
              sx={{ mr: 2, minWidth: 150 }}
            />
          )}

          <Typography
            sx={{ cursor: "pointer", textDecoration: "underline", ml: 2 }}
            onClick={clearFilterHandler}
          >
            Clear Filters
          </Typography>
        </RowBox>
      </ColBox>

      <ServiceFilterContext.Provider
        value={{
          country,
          availability,
          q,
          mainCategory,
          category,
          subCategory,
        }}
      >
        <Outlet />
      </ServiceFilterContext.Provider>
    </Container>
  );
}
