import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

//UI Related
import { Button } from "@mui/material";

//API related imports
import { useMutation } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

//Store related imports
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../store/auth";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../utils/validation";

//Internal Components
import { LoginForm } from "../components/form";
import { PageCenterContent, ProgressIndicator } from "../components/ui";
import { LogoHeader } from "../components/app-ui";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(loginSchema),
  });

  const { login } = useContext(APIContext);
  const loginMutation = useMutation((data) => login(data), {
    onSuccess: (data) => {
      dispatch(setToken(data?.data?.tokens));
      dispatch(setUser(data?.data?.user));
      navigate("/", { replace: true });
    },
  });

  const submitHandler = (values) => {
    loginMutation.mutate(values);
  };

  return (
    <PageCenterContent>
      <LogoHeader sx={{ mb: 5 }} />
      <FormProvider {...methods}>
        <LoginForm />
        <Button
          variant="primary"
          sx={{ mt: 6 }}
          onClick={methods.handleSubmit(submitHandler)}
        >
          Log in
        </Button>
      </FormProvider>
      {loginMutation.isLoading && <ProgressIndicator />}
    </PageCenterContent>
  );
};

export default Login;