import React, { useRef, useState, useEffect, useContext } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
  InputBase,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  useTheme,
  styled,
  InputLabel,
  FormHelperText,
  ImageList,
  ImageListItem,
  DialogActions,
  Button,
  Autocomplete,
  Divider,
  DialogTitle,
  Rating,
} from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";
import Carousel from "react-material-ui-carousel";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "../utils/canvasPreview";
import "react-image-crop/dist/ReactCrop.css";

import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import {
  dateString,
  timeString,
  getAllCountries,
  getStatesOfCountry,
} from "../utils/utils";

export const Page = (props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.info.lightest,
        minHeight: "100vh",
        ...props?.sx,
      }}
    >
      {props?.children}
    </Box>
  );
};

export const PageCenterContent = (props) => {
  const theme = useTheme();
  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={4}
        sx={{ [theme.breakpoints.only("xs")]: { margin: 2 }, marginTop: -10 }}
      >
        {props?.children}
      </Grid>
    </Grid>
  );
};

export const PageTitle = (props) => {
  const theme = useTheme();
  const { title, back, path } = props;
  return (
    <RowBox
      sx={{
        p: 2,
        boxSizing: "border-box",
        borderBottom: `1px solid ${theme.palette.info.light}`,
        ...props?.sx,
      }}
    >
      {back && <BackButton path={path} />}
      <Typography variant="h3">{title}</Typography>
    </RowBox>
  );
};

export const Container = (props) => {
  const theme = useTheme();
  return (
    <ColBox
      fullWidth
      sx={{
        backgroundColor: "white",
        borderRadius: 2,
        height: "100%",
        boxSizing: "border-box",
        ...props?.sx,
      }}
    >
      {props?.children}
    </ColBox>
  );
};

export const SplitTabs = (props) => {
  const TabHeader = styled("div")(({ theme }) => ({
    backgroundColor: "white",
    //marginTop: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingTop: 24,
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.info.light}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    boxSizing: "border-box",
    ...props?.hsx,
  }));

  const TabItem = styled("div")(({ isActive, theme }) => ({
    minWidth: 120,
    minHeight: 32,
    border: `1px solid ${theme.palette.info.light}`,
    borderRadius: 20,
    backgroundColor: isActive ? theme.palette.primary.main : "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 7,
    marginRight: 7,
    paddingLeft: 7,
    paddingRight: 7,
    boxSizing: "border-box",
    ...props?.sx,
  }));

  return (
    <TabHeader>
      {props?.menu.map((i) => {
        return (
          <NavLink to={i.link} key={i.name} style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <TabItem isActive={isActive}>
                <Typography
                  variant="body1"
                  sx={{ color: isActive ? "white" : "black" }}
                >
                  {i.name}
                </Typography>
              </TabItem>
            )}
          </NavLink>
        );
      })}
    </TabHeader>
  );
};

export const Tabs = (props) => {
  const TabHeader = styled("div")(({ theme }) => ({
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.palette.info.light}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    ...props?.hsx,
  }));

  const TabItem = styled("div")(({ isActive, theme }) => ({
    width: 140,
    minHeight: 32,
    borderRight: `1px solid ${theme.palette.info.light}`,
    borderBottom: `1px solid ${theme.palette.info.light}`,
    backgroundColor: isActive ? theme.palette.info.light : "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    ...props?.sx,
  }));

  const Indicator = styled("div")(({ theme }) => ({
    height: 2,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    bottom: 0,
  }));

  return (
    <TabHeader>
      {props?.menu.map((i) => {
        return (
          <NavLink to={i.link} key={i.name} style={{ textDecoration: "none" }}>
            {({ isActive }) => (
              <TabItem isActive={isActive}>
                <Typography variant="body1">{i.name}</Typography>
                {isActive && <Indicator />}
              </TabItem>
            )}
          </NavLink>
        );
      })}
    </TabHeader>
  );
};

export const RowBox = (props) => {
  const { sx, ...params } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: props?.center ? "center" : "flex-start",
        alignItems: "center",
        position: props?.relative ? "relative" : "inherit",
        width: "100%",
        boxSizing: "border-box",
        ...props?.sx,
      }}
      {...params}
    >
      {props?.children}
    </Box>
  );
};

export const ColBox = (props) => {
  const { sx, fullWidth, center, ...params } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: fullWidth ? "100%" : "auto",
        alignItems: center ? "center" : "flex-start",
        boxSizing: "border-box",
        ...sx,
      }}
      {...params}
    >
      {props?.children}
    </Box>
  );
};

export const ImageBox = (props) => {
  return <Box component="img" {...props} />;
};

export const Error = (props) => {
  const theme = useTheme();
  const { error } = props;
  if (!error) return null;
  return (
    <Typography
      variant="error"
      sx={{ ml: theme.typography.pxToRem(14), mt: theme.typography.pxToRem(3) }}
    >
      {error?.message}
    </Typography>
  );
};

export const Title = (props) =>
  props?.title ? (
    <Typography variant="h3" sx={{ mb: 0.8 }}>
      {props?.title}
    </Typography>
  ) : null;

export const Clock = (props) => {
  const [render, reRender] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => reRender((v) => !v), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ ...props?.sx }}>
      <Typography variant="h6">{dateString()}</Typography>
      <Typography variant="h6">{timeString()}</Typography>
    </Box>
  );
};

export const BackButton = (props) => {
  const navigate = useNavigate();
  return (
    <IconButton
      onClick={() => navigate(props?.path || -1)}
      sx={{ mr: 2, padding: 0, ...props.sx }}
    >
      <ImageBox
        src={require("../assets/icons/back-circled.png")}
        width={24}
        height={24}
      />
    </IconButton>
  );
};

export const ProgressIndicator = (props) => {
  return (
    <Box boxShadow={4} elevation={2}>
      <Dialog
        open={true}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export const LoadingIndicator = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CircularProgress sx={{ my: 1 }} />
    </Box>
  );
};

export const SearchBar = (props) => {
  const theme = useTheme();
  return (
    <RowBox
      sx={{
        height: 42,
        borderRadius: 2,
        border: `1px solid ${theme.palette.info.light}`,
        justifyContent: "flex-start",
        width: 300,
        ...props?.sx,
      }}
    >
      <SearchIcon color="info" sx={{ ml: 1 }} />
      <InputBase
        sx={{ pr: 1, mr: "auto" }}
        placeholder={props?.placeholder || "Type to search"}
        value={props?.value || ""}
        onChange={(e) => props?.onChange(e.target.value)}
      />
      {props?.value?.length > 0 && (
        <ClearIcon
          color="info"
          sx={{ mr: 1, width: 20, height: 20 }}
          onClick={() => props?.onChange("")}
        />
      )}
    </RowBox>
  );
};

export const ImageCarousel = (props) => {
  const StyledImage = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
    objectFit: props?.objectFit || "contain",
  }));

  if (!props?.images || props?.images?.length === 0) return null;

  return (
    <Carousel
      height={280}
      sx={props?.sx}
      index={props?.last ? props?.images?.length - 1 : 0}
      autoPlay={false}
      indicators={props?.images?.length > 1}
    >
      {props?.images?.map((i, index) => {
        const objectType = typeof i;
        const url =
          i instanceof File
            ? URL.createObjectURL(i)
            : objectType === "object"
            ? i.url
            : i;

        return <StyledImage key={index} src={url} />;
      })}
    </Carousel>
  );
};

export const ImageGrid = (props) => {
  const StyledImage = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
    objectFit: props?.objectFit || "cover",
    border: `1px solid ${theme.palette.info.light}`,
    boxSizing: "border-box",
  }));

  const Delete = styled(ImageBox)(() => ({
    position: "absolute",
    right: 0,
    top: 0,
  }));

  if (!props?.images || props?.images?.length === 0) return null;

  return (
    <ImageList cols={3} sx={props?.sx}>
      {props?.images?.map((i, index) => {
        const objectType = typeof i;
        const url =
          i instanceof File
            ? URL.createObjectURL(i)
            : objectType === "object"
            ? i.url
            : i;
        return (
          <ImageListItem>
            <StyledImage key={index} src={url} />
            {props?.editable && (
              <Delete
                src={require("../assets/icons/cancel.png")}
                onClick={() => props?.onDelete(index)}
              />
            )}
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

export const VideoPlayer = (props) => {
  const { video } = props;

  const url =
    video instanceof File
      ? URL.createObjectURL(video)
      : typeof video === "object"
      ? video?.url
      : video;

  return (
    <Box sx={{ position: "relative", ...props?.wsx }}>
      <video
        controls
        autoPlay
        muted={true}
        style={{ width: "100%", maxHeight: 280, ...props.vsx }}
      >
        <source src={url} />
      </video>
      {props?.editable && (
        <ImageBox
          sx={{ position: "absolute", right: 0, top: 0 }}
          src={require("../assets/icons/cancel.png")}
          onClick={() => props?.onDelete()}
        />
      )}
    </Box>
  );
};

export const ConfirmDialog = (props) => {
  const Title = styled(DialogTitle)(({ theme }) => ({
    marginLeft: 20,
    marginRight: 20,
    borderBottom: `1px solid ${theme.palette.info.light}`,
  }));

  return (
    <Dialog
      open={props?.show}
      fullWidth
      maxWidth="sm"
      sx={{ textAlign: "center" }}
    >
      <Title>
        <Typography variant="h4">{props?.title}</Typography>
      </Title>
      <DialogContent sx={{ mt: 2.5 }}>
        <Typography variant="body1">{props?.body}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
        <Button variant="outlined" onClick={props?.onConfirm}>
          Yes
        </Button>
        <Button variant="outlined" onClick={props?.onCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const MultiLineInput = (props) => {
  return (
    <TextField
      multiline
      maxRows={4}
      InputProps={{
        disableUnderline: true,
        style: { fontSize: 14 },
      }}
      variant="filled"
      sx={props?.sx}
      placeholder={props?.placeholder}
      value={props?.value}
      onChange={(e) => props?.onChange(e.target.value)}
    />
  );
};

export const MultiLineInputControl = (props) => {
  const { control, defaultValue } = useFormContext();
  const { name, totalChars, wsx, title, subtitle, ...params } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <ColBox sx={wsx}>
          {title && (
            <RowBox sx={{ mb: 1.25 }}>
              <Typography variant="h3">{title}</Typography>
              {subtitle && (
                <Typography variant="h4" sx={{ ml: 1 }}>
                  {subtitle}
                </Typography>
              )}
            </RowBox>
          )}
          <TextField
            multiline
            InputProps={{
              disableUnderline: true,
              style: { fontSize: 14 },
            }}
            inputProps={{
              maxLength: totalChars,
            }}
            placeholder={props?.placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            {...params}
          />

          <RowBox>
            <Error error={error} />
            {totalChars && (
              <Typography variant="body2" sx={{ ml: "auto" }}>{`${
                value?.length || 0
              }/${totalChars}`}</Typography>
            )}
          </RowBox>
        </ColBox>
      )}
    />
  );
};

export const ToggleButtons = (props) => {
  const { options } = props;
  const [value, setValue] = useState(options?.[0]?.value);

  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={(event, value) => setValue(value)}
      sx={props?.sx}
    >
      {options?.map((o) => (
        <ToggleButton value={o.value}>{o.name}</ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export const FileButton = (props) => {
  const ref = useRef();

  const buttonClickHandler = () => {
    ref.current.click();
  };

  const fileSelectHandler = (e) => {
    const file = e.target.files[0];
    props?.onSelect(file);
    e.target.value = "";
  };

  return (
    <Box sx={props?.sx}>
      <input
        ref={ref}
        type="file"
        hidden
        accept={props?.type || "image/*"}
        onChange={fileSelectHandler}
      ></input>
      {React.cloneElement(props.children, {
        onClick: buttonClickHandler,
      })}
    </Box>
  );
};

export const FileSelect = (props) => {
  const { control, setValue, defaultValue } = useFormContext();
  const [shouldShowCrop, showCrop] = useState(false);
  const [imageToCrop, setImageToCrop] = useState();

  const selectHandler = (image, prev, callback) => {
    if (!image) {
      return;
    }

    if (props?.single) {
      if (props?.crop) {
        setImageToCrop(image);
        showCrop(true);
      } else {
        callback(image);
        return;
      }
    }

    if (!prev || prev.length === 0) {
      callback([image]);
      return;
    }

    callback([...prev, image]);
  };

  const cropCompleteHandler = (image) => {
    showCrop(false)
    setValue(props?.name, image)
  }

  return (
    <ColBox sx={props?.wsx}>
      {props?.title && (
        <RowBox sx={{ mb: 1.25 }}>
          <Typography variant="h3Bold">{props.title}</Typography>
          {props.subtitle && (
            <Typography variant="h4" sx={{ ml: 1 }}>
              {props?.subtitle}
            </Typography>
          )}
        </RowBox>
      )}
      <Controller
        control={control}
        name={props?.name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            {value && props?.show && (
              <ImageCarousel
                last
                images={props?.single ? [value] : value}
                sx={{ mb: 2 }}
              />
            )}
            {value && props?.showGrid && (
              <ImageGrid
                images={props?.single ? [value] : value}
                sx={{ mb: 2 }}
              />
            )}
            {props?.button || (
              <FileButton
                value={value}
                onSelect={(image) => selectHandler(image, value, onChange)}
                type={props?.type}
                sx={props?.sx}
              >
                {props.children}
              </FileButton>
            )}
            {props?.showError && <Error error={error} />}
          </>
        )}
      />
      <ImageCropDialog
        image={
          imageToCrop instanceof File
            ? URL.createObjectURL(imageToCrop)
            : imageToCrop
        }
        show={shouldShowCrop}
        onCrop={cropCompleteHandler}
        onCancel={() => showCrop(false)}
      />
    </ColBox>
  );
};

export const RadioButtons = (props) => {
  const handleChange = (event) => {
    props?.onChange(event.target.value);
  };

  const Label = styled(FormLabel)(({ theme }) => ({
    marginRight: 30,
    fontSize: 16,
    fontWeight: "bold",
    color: "black",
  }));

  const ButtonLabel = styled(FormControlLabel)(({ theme }) => ({
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.info.dark,
      marginRight: 18,
    },
  }));

  return (
    <FormControl sx={{ flexDirection: "row", alignItems: "center" }}>
      <Label>{props.label}</Label>
      <RadioGroup row value={props?.value} onChange={handleChange}>
        {props?.options?.map((o) => (
          <ButtonLabel
            key={o.name}
            value={o.value}
            control={<Radio color="success" sx={{ p: 0 }} />}
            label={o.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export const InputField = (props) => {
  const { control, defaultValue } = useFormContext();
  return (
    <ColBox sx={props?.wsx}>
      {props?.title && (
        <Typography variant="h3" sx={{ marginBottom: 1.25 }}>
          {props.title}
        </Typography>
      )}
      <Controller
        control={control}
        name={props?.name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <TextField
              value={value}
              onChange={onChange}
              error={error}
              helperText={error?.message}
              autoComplete="off"
              {...props}
            />
          );
        }}
      />
    </ColBox>
  );
};

export const AutoCompleteInputText = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "&:hover, .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: 1,
    borderColor: theme.palette.info.main,
  },
}));

export const InputAutoComplete = (props) => {
  const { value, onChange, onInputChange, placeholder, options, ...settings } =
    props;
  const _options = options?.map((o) => {
    return typeof o === "object" ? o : { label: o, id: o };
  });

  return (
    <Autocomplete
      disablePortal
      value={value}
      onChange={(e) => {
        const index = e.target.value;
        onChange(options[index]);
      }}
      onInputChange={(e, v) => onInputChange(v)}
      options={_options || []}
      renderInput={(params) => (
        <AutoCompleteInputText {...params} placeholder={placeholder} />
      )}
      {...settings}
    />
  );
};

export const AutocompleteController = (props) => {
  const { control, defaultValue } = useFormContext();
  const { name, hideError, ...params } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <InputAutoComplete value={value} onChange={onChange} {...params} />
          {!hideError && <Error error={error} />}
        </>
      )}
    />
  );
};

export const InputSelect = (props) => {
  const { value, onChange, placeholder, options, ...settings } = props;
  const _options = options?.map((o) => {
    return typeof o === "object" ? o : { name: o, value: o };
  });

  return (
    <Select
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      displayEmpty
      renderValue={(v) => {
        if (!value && placeholder) {
          return <Typography variant="h4">{placeholder}</Typography>;
        }
        const name = _options?.find((o) => o.value === value)?.name;
        return name;
      }}
      {...settings}
    >
      {placeholder && (
        <MenuItem key="empty" value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {_options?.map((o) => (
        <MenuItem key={o.name} value={o.value}>
          {o.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export const SelectController = (props) => {
  const theme = useTheme();
  const { control, defaultValue } = useFormContext();
  const {
    wsx,
    title,
    name,
    placeholder,
    options,
    afterText,
    hideError,
    fullWidth,
    ...settings
  } = props;

  return (
    <ColBox sx={{ width: fullWidth ? "100%" : "auto", ...wsx }}>
      {title && (
        <Typography variant="h3" sx={{ marginBottom: 1.25 }}>
          {title}
        </Typography>
      )}

      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <RowBox>
              <InputSelect
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                options={options}
                {...settings}
              />
              {afterText && (
                <Typography sx={{ ml: 1.2 }}>{afterText}</Typography>
              )}
            </RowBox>
            {!hideError && <Error error={error} />}
          </>
        )}
      />
    </ColBox>
  );
};

export const CountryPick = (props) => {
  const countries = getAllCountries().map((c) => ({ name: c, value: c }));
  const { value, onChange, ...params } = props;

  return (
    <InputSelect
      options={countries}
      placeholder="Select Country"
      value={value}
      onChange={onChange}
      {...params}
    />
  );
};

export const CountryPicker = (props) => {
  const countries = getAllCountries().map((c) => ({ name: c, value: c }));
  return (
    <SelectController
      name="country"
      options={countries}
      placeholder="Select Country"
      {...props}
    />
  );
};

export const StatePick = (props) => {
  const { country, value, onChange, ...params } = props;
  let states = [];

  if (country) {
    states = getStatesOfCountry(country);
    if (!states || !states.length) {
      states = [country];
    }
  }
  const _states = states.map((s) => ({ name: s, value: s }));
  return (
    <InputSelect
      options={_states}
      placeholder="Select State"
      value={value}
      onChange={onChange}
      {...params}
    />
  );
};

export const StatePicker = (props) => {
  const [stateList, setStateList] = useState([]);

  const { watch, setValue } = useFormContext();
  const country = watch("country");
  let states;

  if (country) {
    states = getStatesOfCountry(country);
    if (!states || !states.length) {
      states = [country];
    }
  }

  //TODO: find a better way to reset state when country is changed.
  //setValue("state", "");

  const _states = states?.map((s) => ({ name: s, value: s }));
  return (
    <SelectController
      name="state"
      options={_states}
      placeholder="Select State"
      {...props}
    />
  );
};

export const CityPicker = (props) => {
  const { fetchCities } = useContext(APIContext);

  const { watch, getValues } = useFormContext();
  const country = getValues("country");
  const state = watch("state");

  const { data: cities } = useQuery(
    ["cities", state],
    () => fetchCities(country, state),
    {
      enabled: !!country && !!state,
      select: (data) =>
        data?.data?.length === 0 ? [state] : data.data?.map((c) => c.name),
    }
  );

  return (
    <SelectController
      title={props?.title}
      name="city"
      options={cities || []}
      placeholder={props?.placeholder}
      wsx={props?.wsx}
      sx={props?.sx}
    />
  );
};

export const ProfessionPick = (props) => {
  const { value, onChange, ...params } = props;
  return (
    <InputSelect
      options={["Engineer", "Doctor", "Other"]}
      placeholder="Select Profession"
      value={value}
      onChange={onChange}
      {...params}
    />
  );
};

export const ProfessionPicker = (props) => {
  const { control, defaultValue } = useFormContext();
  const { name, hideError, ...params } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <ProfessionPick value={value} onChange={onChange} {...params} />
          {!hideError && <Error error={error} />}
        </>
      )}
    />
  );
};

export const QuantityPicker = (props) => {
  const quantities = [1, 2, 3, 4, 5, 6].map((v) => ({
    name: v.toString(),
    value: v,
  }));
  return (
    <SelectController
      name={props?.name}
      options={quantities}
      value={props?.value || 1}
      fullWidth={false}
      variant="outlined"
      {...props}
    />
  );
};

export const GenderPicker = (props) => {
  const options = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
  ];
  return (
    <SelectController
      name={props?.name}
      options={options}
      variant="outlined"
      placeholder="Select Gender"
      {...props}
    />
  );
};

export const SelectOptions = (props) => {
  const Item = styled("div")(({ isActive, theme }) => ({
    border: isActive
      ? `1px solid black`
      : `1px solid ${theme.palette.info.light}`,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 14,
    paddingRight: 15,
    paddingTop: 7,
    paddingBottom: 7,
    marginRight: 8,
    cursor: "pointer",
  }));

  const Text = styled(Typography)(({ isActive, theme }) => ({
    color: isActive ? "black" : theme.palette.info.dark,
  }));

  return (
    <RowBox>
      {props?.options?.map((o) => {
        const isActive = o.type === props?.value;
        return (
          <Item isActive={isActive} onClick={() => props?.onChange(o.type)}>
            <Text variant="h5Gray" isActive={isActive}>
              {o.name || o.title}
            </Text>
          </Item>
        );
      })}
    </RowBox>
  );
};

export const ImageCropDialog = (props) => {
  const { image: srcImage } = props;
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const imgRef = useRef();
  const canvasRef = useRef();
  const scale = 1;
  const aspect = props?.aspect || 0;
  const rotate = 1;

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop({ unit: "%", width: 50 }, aspect, mediaWidth, mediaHeight),
      mediaWidth,
      mediaHeight
    );
  }

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    } else {
      setCrop({
        unit: "%", // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 50,
        height: 50,
      });
    }
  };

  const useDebounceEffect = (fn, waitTime, deps) => {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined, deps);
      }, waitTime);

      return () => {
        clearTimeout(t);
      };
    }, deps);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        canvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          canvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const cropHandler = () => {
    canvasRef.current.toBlob((blob) => {
      const file = new File([blob], "profile.png", { type: blob.type });
      props?.onCrop?.(file);
      setCompletedCrop(null);
      setCrop(null);
    });
  };

  return (
    <Dialog open={props?.show} scroll="body">
      <DialogContent>
        <ReactCrop
          crop={crop}
          onChange={setCrop}
          onComplete={setCompletedCrop}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            src={srcImage}
            style={{ width: "100%", height: "100%", maxHeight: "80vh" }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
        {completedCrop && (
          <canvas
            ref={canvasRef}
            style={{
              display: "none",
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cropHandler}>Crop</Button>
        <Button onClick={props?.onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export const RatingControl = (props) => {
  const { control, defaultValue } = useFormContext();
  const { name, hideError, ...params } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Rating value={value} onChange={onChange} {...params} />
          {!hideError && <Error error={error} />}
        </>
      )}
    />
  );
}
