import React, { useContext, useState } from "react";
import { Container, ProgressIndicator } from "../../components/ui";
import { Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { OrdersTable } from "../../components/tables";
import { OrderContext } from "../../services/context";

export default function OrderList(props) {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const { status } = props;
  const { q } = useContext(OrderContext);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchOrders } = useContext(APIContext);
  const { isLoading, data: orders } = useQuery(
    ["orders", { page, q , status}],
    () => fetchOrders({ page, q, status}),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const viewHandler = (order) => {
    navigate(`/order-detail/${order?.id}`, { state: order });
  };

  const sellerViewHandler = (seller) => {
    console.log("Seller ", seller)
    navigate(`/seller-detail/${seller.seller}`, {state: seller})
  }

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Orders: ${
        orders?.totalResults || 0
      }`}</Typography>
      <OrdersTable
        data={orders}
        onView={viewHandler}
        onSellerClick={sellerViewHandler}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}