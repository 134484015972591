import "./App.css";
import React, { useContext, useState } from "react";
import { Box, Grid, Drawer, Hidden } from "@mui/material";

import { useSelector } from "react-redux";

import { Outlet } from "react-router";
import { NavBar, SideBar } from "./components/app-ui";
import { useTheme } from "@emotion/react";
import { Page } from "./components/ui";

const App = () => {
  const theme = useTheme();
  const [showSideBar, setSideBar] = useState();
  const { user } = useSelector((store) => store.auth);

  const toggleDrawer = () => {
    setSideBar((prev) => !prev);
  };

  return (
    <Page>
      <NavBar user={user} onMenu={setSideBar} />
      <Grid
        container
        spacing={1}
        // sx={{
        //   height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        // }}
      >
        <Hidden mdDown>
          <Grid item sm={2}>
            <SideBar />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={10} sx={{height: 'calc(100vh - 70px)'}}>
          <Outlet />
        </Grid>
      </Grid>
      <Drawer
        variant="temporary"
        open={showSideBar}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SideBar onSelect={toggleDrawer} sx={{ml: 0}} />
      </Drawer>
    </Page>
  );
};

export default App;
