import React, { useContext, useState } from "react";
import { Container, ProgressIndicator } from "../../components/ui";
import { Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { GroupTable, ServicesTable } from "../../components/tables";
import { ServiceFilterContext } from "../../services/context";

export default function ServiceList(props) {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const { status } = props;
  const { q, country, availability, mainCategory, category, subCategory } = useContext(ServiceFilterContext);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchAllProducts } = useContext(APIContext);
  const { isLoading, data: services } = useQuery(
    ["products", { page, q, country, availability, status, mainCategory, category, subCategory }],
    () => fetchAllProducts({ page, q, country, availability, status, mainCategory, category, subCategory }),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const viewHandler = (product) => {
    navigate(`/service-detail/${product?.id}`, { state: product });
  };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Services: ${
        services?.totalResults || 0
      }`}</Typography>
      <ServicesTable
        data={services}
        onView={viewHandler}
        onReview={viewHandler}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}
