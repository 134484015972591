import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  refreshToken: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload.access.token;
      state.refreshToken = action.payload.refresh.token;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    setSeller: (state, action) => {
      state.user.seller = action.payload;
    },
    addFollower: (state, action) => {
      const otherParty = action.payload;
      const index = state.user.followRequests?.indexOf(otherParty?.id);
      if (index > -1) {
        state.user.followRequests?.splice(index, 1);
      }
      state.user.followers.push(otherParty);
      state.user.followerCount += 1;
    },
    setFollowerCount: (state, action) => {
      state.user.followerCount = action.payload;
    },
    setFollowingCount: (state, action) => {
      state.user.followingCount = action.payload;
    },
    logout: (state, action) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
    },
  },
});

export default authSlice.reducer;

export const {
  setUser,
  setToken,
  updateUser,
  addFollower,
  setFollowerCount,
  setFollowingCount,
  setSeller,
  logout,
} = authSlice.actions;