import * as yup from "yup";

const nameSchema = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .required("Field is required")
    .max(35, "Must be less than 35 letters")
    .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
  lastname: yup
    .string()
    .trim()
    .required("Field is required")
    .max(40, "Must be less than 40 letters")
    .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
});

const professionSchema = yup.object().shape({
  profession: yup.string().trim().required("Profession is required"),
  country: yup
    .string()
    .trim()
    .typeError("Country is required")
    .required("Country is required"),
  state: yup
    .string()
    .trim()
    .typeError("State is required")
    .required("State is required"),
});

const registerSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  password: yup
    .string()
    .trim()
    .min(6, "Minimum 6 characters neeeded")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .trim()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
});

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  password: yup.string().trim().required("Password is required"),
});

export const groupSchema = yup.object().shape({
  name: yup.string().trim().required("Name is required"),
  description: yup.string().trim().required("Name is required"),
});

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  phoneNo: yup.string().trim().required("Mobile Number is required"),
  password: yup.string().trim().required("Password is required"),
});

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .min(6, "Minimum 6 characters neeeded")
    .required("Password is required"),

  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please type password again"),
});

const editProfileSchema = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .required("Field is required")
    .max(16, "Must be less than 16 letters")
    .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
  lastname: yup
    .string()
    .trim()
    .required("Field is required")
    .max(16, "Must be less than 16 letters")
    .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
  profession: yup.string().trim().required("Field is required"),
  country: yup.string().trim().required("Field is required"),
  state: yup.string().trim().required("Field is required"),
  bio: yup.string().trim().required("Field is Required"),
});

export const userSchema = yup.object().shape({
  firstname: yup
    .string()
    .trim()
    .required("Field is required")
    .max(35, "Must be less than 35 letters")
    .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
  lastname: yup
    .string()
    .trim()
    .required("Field is required")
    .max(40, "Must be less than 40 letters")
    .matches(/^[a-zA-Z\s]+$/, "Only letters are allowed"),
  profession: yup.string().trim().required("Profession is required"),
  country: yup
    .string()
    .trim()
    .typeError("Country is required")
    .required("Country is required"),
  state: yup
    .string()
    .trim()
    .typeError("State is required")
    .required("State is required"),
});

export const postSchema = yup
  .object()
  .shape({
    content: yup.string().trim(),
    images: yup.array(),
    notes: yup.string().trim(),
    postTo: yup.string().required("Select a Post To option"),
    group: yup.object().nullable(),
  })
  .test("isValid", "Post has no content", (value) => {
    return value?.content || value?.images?.length > 0;
  })
  .test("isValidGroup", "Select a group", (value) => {
    return value?.postTo === "timeline" || value.group;
  });

const orderSupportSchema = yup.object().shape({
  orderId: yup.string().trim().required("Select Order ID"),
  issue: yup.string().trim().required("Field is Required"),
});

const applicationSchema = yup.object().shape({
  firstname: yup.string().trim().required("First Name is required"),
  lastname: yup.string().trim().required("Last Name is required"),
  countryCode: yup.string().trim().required("Required"),
  phoneNo: yup.string().trim().required("Mobile Number is required"),
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  address: yup.string().trim().required("Address is required"),
  country: yup.string().trim().required("Country is required"),
  state: yup.string().trim().required("State is required"),
  medicalSchool: yup.string().trim().required("Field is required"),
  studentStatus: yup.string().trim().required("Field is required"),
  graduationYear: yup.string().trim().required("Field is required"),
  resumeHighlight: yup.string().trim().required("Field is required"),
  tentativeDate: yup.string().trim().required("Date is required"),
  duration: yup.string().trim().required("Duration is required"),
  emergencyFirstName: yup.string().trim().required("First Name is required"),
  emergencyLastName: yup.string().trim().required("Last Name is required"),
  emergencyCountryCode: yup.string().trim().required("Required"),
  emergencyPhoneNo: yup.string().trim().required("Mobile Number is required"),
  resume: yup.object().required("File is required"),
  idDoc: yup.object().required("File is required"),
  immunizationDoc: yup.object().required("File is required"),
  medicalDoc: yup.object().required("File is required"),
  requireRor: yup.boolean().required("Agree to continue"),
  ror: yup.boolean().required("Agree to continue"),
  toc: yup.boolean().required("Agree to continue"),
});

const sellerRegistrationIndividualSchema = yup.object().shape({
  firstname: yup.string().trim().required("First Name is required"),
  lastname: yup.string().trim().required("Last Name is required"),
  phoneNo: yup.string().trim().required("Mobile Number is required"),
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  countryCode: yup.string().default("+91"),
  address: yup.string().trim().required("Enter Address"),
  country: yup
    .string()
    .trim()
    .typeError("Country is required")
    .required("Country is required"),
  state: yup
    .string()
    .trim()
    .typeError("State is required")
    .required("Field is required"),
  zipcode: yup.string().trim().required("Field is required"),
  photoId: yup.mixed().required("PhotoId is required"),
  selfie: yup.mixed().required("Selfie is required"),
});

const sellerBusinessRegistrationSchema = yup.object().shape({
  firstname: yup.string().trim().required("First Name is required"),
  lastname: yup.string().trim().required("Last Name is required"),
  phoneNo: yup.string().trim().required("Mobile Number is required"),
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Business Email is required"),
  companyName: yup.string().trim().required("Company Name is required"),
  businessName: yup.string().trim().required("Business Name is required"),
  signatoryName: yup.string().trim().required("Name is required"),
  address: yup.string().trim().required("Address is requried"),
  country: yup
    .string()
    .trim()
    .typeError("Country is required")
    .required("Country is required"),
  state: yup
    .string()
    .trim()
    .typeError("State is required")
    .required("State is required"),
  zipcode: yup.string().trim().required("Zipcode is required"),
  photoId: yup.mixed().required("PhotoId is required"),
  selfie: yup.mixed().required("Photo is required"),
  aoiDoc: yup.mixed().required("AOI Doc is required"),
});

const sellerTeacherRegistrationSchema = yup.object().shape({
  firstname: yup.string().trim().required("First Name is required"),
  lastname: yup.string().trim().required("Last Name is required"),
  phoneNo: yup.string().trim().required("Mobile Number is required"),
  email: yup
    .string()
    .trim()
    .email("Invalid Email")
    .required("Email is required"),
  hospitalName: yup.string().trim().required("Hospital Name is required"),
  speciality: yup.string().trim().required("Speciality is required"),
  title: yup.string().trim().required("Title is required"),
  address: yup.string().trim().required("Work Address is required"),
  country: yup
    .string()
    .trim()
    .typeError("Country is required")
    .required("Country is required"),
  state: yup
    .string()
    .trim()
    .typeError("State is required")
    .required("State is required"),
  zipcode: yup.string().trim().required("zipcode is required"),
});

const HelpSupportSchema = yup.object().shape({
  category: yup.string().trim().required("Enter Category "),
  issue: yup.string().trim().required("Field is required "),
});

const paymentInformationSchema = yup.object().shape({
  firstName: yup.string().trim().required("Enter First Name"),
  lastName: yup.string().trim().required("Enter Last Name"),
  accountNumber: yup.number().required("Enter Account Number"),
  reAccountNumber: yup
    .number()
    .oneOf([yup.ref("accountNumber"), null], "Enter Same Account as Above")
    .required("Enter Account Number"),
  bankName: yup.string().trim().required("Enter Bank Name"),
  ifscCode: yup.string().trim().required("Enter IFSC Code"),
  swiftcode: yup.string().trim().required("Enter SWIFT Code"),
});

const taxInfoSchema = yup.object().shape({
  isUSPerson: yup.boolean(),
  taxDoc: yup.object().when("isUSPerson", {
    is: true,
    then: yup
      .object()
      .required("Tax Document is required")
      .typeError("Tax Document is required"),
  }),
});

const currencySchema = yup
  .number()
  .typeError("Enter a valid number")
  .required("Amount is required")
  .min(1, "Enter a valid amount");

const availabilitySchema = yup
  .object()
  .shape({
    weekday: yup.object().shape({
      from: yup.string(),
      to: yup.string(),
    }),
    weekend: yup.object().shape({
      from: yup.string(),
      to: yup.string(),
    }),
  })
  .test("isValid", "Please specify all time intervals", (value) => {
    return (
      value.weekday.from &&
      value.weekday.to &&
      value.weekend.from &&
      value.weekend.to
    );
  });

const durationSchema = yup
  .object()
  .shape({
    type: yup.string(),
    amount: yup.number(),
  })
  .test("isValid", "Enter a valid amount", (value) => value.amount > 0)
  .test("required", "Amount is required", (value) => !!value.amount);

const wordSchema = yup.object().shape({
  type: yup.string(),
  amount: yup
    .number()
    .required("Amount is required")
    .typeError("Amount is required")
    .min(1, "Enter a valid amount"),
});

const packageSchema = yup.object().shape({
  type: yup.string(),
  title: yup.string().required("Title is required"),
  desc: yup.string().required("Description is required"),
  amount: currencySchema,
});

const firstPackageSchema = yup
  .object()
  .shape({
    type: yup.string(),
    title: yup.string(),
    desc: yup.string(),
    amount: yup
      .number()
      .transform((value) => {
        return value === "" ? null : value;
      })
      .nullable()
      .min(1, "Enter a valid amount"),
  })
  .test("all-fields", "All Fields are mandatory for Package 1", (value) => {
    console.log("First Package value ", value);
    return value.title && value?.desc && value?.amount;
  });

const restPackageSchema = yup
  .object()
  .shape({
    type: yup.string(),
    title: yup.string(),
    desc: yup.string(),
    amount: yup
    .number()
    .transform((value) => {
      return value === "" || Number.isNaN(value) ? null : value;
    })
    .nullable()
    .min(1, "Enter a valid amount")
  })
  .test(
    "either-or-none",
    "Either all or none of the fields should be filled",
    (value) => {
      if (value.title || value.desc || value.amount) {
        return value.title && value.desc && value.amount;
      } else {
        return true;
      }
    }
  )

const productSchema = (schema) => {
  const yupSchema = {};
  if (!schema) return;
  if (schema.title) {
    yupSchema.title = yup.string().required("Title is required");
  }
  if (schema.shortDesc) {
    yupSchema.shortDesc = yup
      .string()
      .required("Short description is required");
  }
  if (schema.longDesc) {
    yupSchema.longDesc = yup
      .string()
      .required("Detailed description is required");
  }
  if (schema.availability) {
    yupSchema.availability = availabilitySchema;
  }
  if (schema.duration) {
    yupSchema.duration = yup.array().of(durationSchema);
  }

  if (schema.singlePrice) {
    yupSchema.price = currencySchema;
  }

  if (schema.country) {
    yupSchema.country = yup
      .string()
      .typeError("Country is required")
      .required("Country is required");
  }

  if (schema.state) {
    yupSchema.state = yup
      .string()
      .typeError("State is required")
      .required("State is required");
  }

  if (schema.city) {
    yupSchema.city = yup
      .string()
      .typeError("City is required")
      .required("City is required");
  }

  if (schema.file) {
    yupSchema.file = yup.mixed().required("File is required");
  }

  if (schema.package) {
    yupSchema.packages = yup.array().of(
      yup.lazy((value) => {
        return value.type === "package1"
          ? firstPackageSchema
          : restPackageSchema;
      })
    );
  }

  if (schema.delivery) {
    yupSchema.deliveryTime = yup.string().required("Delivery time is required");
  }

  if (schema.rushDelivery) {
    yupSchema.rushDeliveryFee = currencySchema;
    yupSchema.rushDeliveryTime = yup
      .string()
      .required("Rush Delivery time is required");
  }

  if (schema.wordDelivery) {
    yupSchema.wordDelivery = yup.array().of(wordSchema);
    yupSchema.noOfRevisions = yup
      .number()
      .required("Select number of revisions");
  }

  if (schema.languages) {
    yupSchema.languages = yup
      .array()
      .of(yup.string())
      .min(1, "Language is required")
      .required("Language is required");
  }

  if (schema.application) {
    yupSchema.city = yup
      .string()
      .typeError("State & City is required")
      .required("State & City is required");
    yupSchema.affiliation = yup.string().required("Affliation is required");
    yupSchema.affiliationType = yup
      .string()
      .required("Affiliation Type is required");
    yupSchema.exposureType = yup.string().required("Exposure Type is required");
    yupSchema.letterType = yup.string().required("Letter Type is required");
  }

  return yup.object().shape(yupSchema);
};

const ReviewSchema = yup.object().shape({
  rating: yup
    .number()
    .typeError("Rating is required")
    .required("Rating is required"),
});

const categorySchema = yup.object().shape({
  displayName: yup.string().required("Category Name is required"),
  commissionPercent: yup
    .number()
    .typeError("Invalid value")
    .min(0, "Invalid value")
    .max(100, "Invalid value"),
  commissionFixed: yup
    .number()
    .typeError("Invalid value")
    .min(0, "Invalid value"),
  fee: yup.number().typeError("Invalid value").min(0, "Invalid value"),
  feeName: yup.string().when("fee", {
    is: (fee) => fee > 0,
    then: yup.string().required("Enter Fee name"),
    otherwise: yup.string(),
  }),
});

export {
  nameSchema,
  professionSchema,
  registerSchema,
  emailSchema,
  forgotPasswordSchema,
  loginSchema,
  resetPasswordSchema,
  editProfileSchema,
  orderSupportSchema,
  applicationSchema,
  sellerRegistrationIndividualSchema,
  sellerBusinessRegistrationSchema,
  sellerTeacherRegistrationSchema,
  HelpSupportSchema,
  paymentInformationSchema,
  productSchema,
  wordSchema,
  taxInfoSchema,
  ReviewSchema,
  categorySchema,
};
