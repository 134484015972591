import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import { Container } from "../components/ui";
import { PostModal } from "../components/app-ui";
import { PostTable } from "../components/tables";

export default function PostTrash() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [post, setPost] = useState()
  const [shouldShowPost, showPost] = useState(false)
  const { fetchDeletedPosts } = useContext(APIContext);

  const { isLoading, data, isFetching } = useQuery(
    ["trash", page],
    () => fetchDeletedPosts(page),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const viewPostHandler = (post) => {
    setPost(post)
    showPost(true)
  };

  return (
    <Container sx={{ p: 2 }}>
      <PostTable posts={data?.results} trash onViewPost={viewPostHandler} />
      <PostModal
        show={shouldShowPost}
        onClose={() => showPost(false)}
        post={post}
      />
    </Container>
  );
}
