import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { useAlert } from "../components/alert";

import {
  RowBox,
  ProgressIndicator,
  Container,
} from "../components/ui";

import { GroupPostsTable } from "../components/tables";

import { CommentModal, PostModal } from "../components/app-ui";

export default function UserPosts(props) {
  const { userId } = useParams();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [post, setPost] = useState();
  const [shouldShowPost, showPost] = useState(false)
  const alert = useAlert()

  const { fetchUserPosts, deletePost } = useContext(APIContext);
  const { isLoading, data, isFetching } = useQuery(
    ["posts", { content: "post", id: userId, page, sort }],
    () => fetchUserPosts(userId, page, sort),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const queryClient = useQueryClient();
  const deletePostMutation = useMutation((postId) => deletePost(postId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["posts", { content: "post", id: userId }]);
      queryClient.invalidateQueries(["comments", { post: post?.id }]);
      toast.success("Successfully deleted post");
    },
  });

  const commentViewHandler = (post) => {
    setPost(post)
    showPost(true)
  };

  const deletePostHandler = (post) => {
    alert("Confirmation", "Are you sure to delete this post?", () => {
      deletePostMutation.mutate(post?.id);
    })
  };

  return (
    <Container sx={{ height: "calc(100% - 33px)" }}>
      <RowBox sx={{ pl: 2, my: 1, boxSizing: "border-box" }}>
        <Typography
          variant="h4Black"
          sx={{ my: 2, mr: "auto" }}
        >{`Total posts: ${data?.totalResults || 0}`}</Typography>
      </RowBox>
      <GroupPostsTable
        data={data}
        onCommentClick={commentViewHandler}
        onDelete={deletePostHandler}
        onSort={(s) => {
          setSort(s);
          setPage(0);
        }}
      />
      <CommentModal
        show={shouldShowPost}
        onClose={() => showPost(false)}
        showDelete
        onDelete={deletePostHandler}
        post={post}
      />
      {(isLoading || deletePostMutation.isLoading) && <ProgressIndicator />}
    </Container>
  );
}
