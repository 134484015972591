import React, { useState, useContext, useEffect, useCallback } from "react";
import { Grid, Box, useTheme } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { toast } from "react-toastify";

import { PageTitle, ColBox, ProgressIndicator } from "../../components/ui";

import { ProductSummary } from "../../components/ecommerce-ui";
import { ServiceForm } from "../../components/form";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { productSchema } from "../../utils/validation";

import { getDirtyFields } from "../../utils/utils";
import { useAlert, useCrop, usePhoto } from "../../components/alert";

export default function ServiceDetail(props) {
  const { id } = useParams();
  const alert = useAlert();
  const cropImage = useCrop();
  const showPhoto = usePhoto();

  const theme = useTheme();

  const queryClient = useQueryClient();
  const { fetchProduct, addProductImage, deleteProductImage, updateProduct } =
    useContext(APIContext);
  const { isLoading, data: product } = useQuery(
    ["products", { id }],
    () => fetchProduct(id),
    {
      select: (data) => data?.data,
    }
  );

  const updateMutation = useMutation((data) => updateProduct(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["products", { id }]);
      toast.success("Successfully updated service");
    },
  });

  const addImageMutation = useMutation((data) => addProductImage(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["products", { id }]);
      toast.success("Successfully updated service");
    },
  });

  const deleteImageMutation = useMutation(
    (index) => deleteProductImage(id, index),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["products", { id }]);
        toast.success("Successfully updated service");
      },
    }
  );

  const form = useForm({
    resolver: yupResolver(productSchema(product?.categorySchema)),
    mode: "onChange",
    defaultValues: product || {},
  });

  useEffect(() => {
    const {
      files,
      noOfRevisions,
      mainCategory,
      category,
      subCategory,
      ...rest
    } = product || {};
    form?.reset({
      file: files?.[0],
      noOfRevisions: noOfRevisions?.toString(),
      country: "India",
      mainCategory: product?.mainCategory?.id,
      category: product?.category?.id,
      subCategory: product?.subCategory?.id,
      ...rest,
    });
  }, [product]);

  const addImageHandler = (file) => {
    cropImage(file, (cropped) => {
      addImageMutation.mutate({
        images: [cropped],
      });
    });
  };

  const removeImageHandler = (index) => {
    alert("Confirmation", "Are you sure to delete this image?", () => {
      deleteImageMutation.mutate(index);
    });
  };

  //Always send images. Needed to handle add/remove images.
  const publishHandler = () => {
    alert(
      "Confirmation",
      "Are you sure to publish this service to the store?",
      () => {
        updateMutation.mutate({ status: "published", images: product?.images });
      }
    );
  };

  const rejectHandler = () => {
    alert("Confirmation", "Are you sure to reject this service?", () => {
      updateMutation.mutate({ status: "rejected", images: product?.images });
    });
  };

  const suspendHandler = () => {
    alert("Confirmation", "Are you sure to suspend this service?", () => {
      updateMutation.mutate({ status: "suspended", images: product?.images });
    });
  };

  const dirtyFields = form.formState.dirtyFields;
  const submitHandler = (values) => {
    const data = getDirtyFields(values, dirtyFields);
    if (data) {
      updateMutation.mutate({
        ...data,
        images: product.images, //Always send images. Needed to handle add/remove images.
        ...(data?.packages ? {packages: data?.packages?.filter(p => p.title) } : {})
      });
    } else {
      toast.error("No changes to save");
    }
  };

  const errorHandler = (error) => {
    console.log(error);
  };

  return (
    <Box sx={{ mt: 1.25, backgroundColor: "white" }}>
      <PageTitle title={product?.title} back />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={2}
          sx={{
            borderRight: `1px solid ${theme?.palette?.info?.light}`,
            display: "flex",
            flex: 1,
          }}
        >
          <ProductSummary
            product={product}
            onAddImage={addImageHandler}
            onShow={showPhoto}
            onRemove={removeImageHandler}
            onPublish={publishHandler}
            onReject={rejectHandler}
            onSuspend={suspendHandler}
          />
        </Grid>
        <Grid item xs={10}>
          <ColBox fullWidth sx={{ py: 3 }}>
            <FormProvider {...form}>
              <ServiceForm
                schema={product?.categorySchema}
                onSubmit={form.handleSubmit(submitHandler, errorHandler)}
              />
            </FormProvider>
          </ColBox>
        </Grid>
      </Grid>
      {(addImageMutation?.isLoading ||
        deleteImageMutation.isLoading ||
        updateMutation.isLoading) && <ProgressIndicator />}
    </Box>
  );
}
