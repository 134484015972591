import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Typography,
  useTheme,
  Divider,
  styled,
  Button,
} from "@mui/material";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReviewSchema } from "../../utils/validation";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { toast } from "react-toastify";
import { useAlert } from "../../components/alert";

import {
  RowBox,
  Container,
  PageTitle,
  ProgressIndicator,
  Tabs,
  ImageBox,
  MultiLineInputControl,
  RatingControl,
} from "../../components/ui";

import { ReviewSummary } from "../../components/ecommerce-ui";
import { dateString, timeString, getDirtyFields } from "../../utils/utils";
import { Form } from "react-router-dom";

const LabelGrid = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-start",
}));

const ContentGrid = styled(Grid)(() => ({
  display: "flex",
}));

const Row = (props) => (
  <>
    <LabelGrid item md={2} sm={2} xs={2} sx={{ mb: 2 }}>
      {props?.children?.[0]}
    </LabelGrid>
    <ContentGrid item md={6} sm={9} xs={10} sx={{ mb: 2 }}>
      {props?.children?.[1]}
    </ContentGrid>
    <Grid item md={4} sm={1} sx={{ mb: 2 }}></Grid>
  </>
);

export default function ReviewDetail() {
  const { id } = useParams();

  const theme = useTheme();
  const navigate = useNavigate();
  const alert = useAlert();

  const queryClient = useQueryClient();
  const { fetchReviews, getReview, updateReview, deleteReview, approveReview } =
    useContext(APIContext);

  const { data: review } = useQuery(["reviews", { id }], () => getReview(id), {
    select: (data) => data.data,
  });

  const updateMutation = useMutation((data) => updateReview(id, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["reviews", { id }]);
      toast.success("Successfully saved changes");
    },
  });

  const deleteMutation = useMutation(() => deleteReview(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["reviews"]);
      toast.success("Successfully saved changes");
      navigate(-1);
    },
  });

  const approveMutation = useMutation(() => approveReview(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["reviews"]);
      toast.success("Successfully saved changes");
      navigate(-1);
    },
  });

  const methods = useForm({
    resolver: yupResolver(ReviewSchema),
    defaultValues: review || { rating: 0 },
  });

  useEffect(() => {
    methods.reset(review);
  }, [review]);

  const viewHandler = () => {
    navigate(`/user-detail/${review?.buyer?.id}`);
  };

  const approveHandler = () => {
    alert("Confirmation", "Are you sure to approve this review?", () => {
      approveMutation.mutate();
    });
  };

  const deleteHandler = () => {
    alert("Confirmation", "Are you sure to delete this review?", () => {
      deleteMutation.mutate();
    });
  };

  const dirtyFields = methods.formState.dirtyFields;
  const submitHandler = (values) => {
    const data = getDirtyFields(values, dirtyFields);
    if (data) {
      updateMutation.mutate(data);
    } else {
      toast.error("No changes to save");
    }
  };

  const errorHandler = (error) => {
    console.log(error);
  };

  return (
    <Container sx={{ mt: 1.25, pb: 2, pr: 2, height: "auto" }}>
      <PageTitle title="Service Review" back />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            borderRight: `1px solid ${theme.palette.info.light}`,
            display: "flex",
            flex: 1,
          }}
        >
          {review && (
            <ReviewSummary
              sx={{ mt: 1 }}
              review={review}
              onView={viewHandler}
              onApprove={approveHandler}
              onDelete={deleteHandler}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={10}>
          <RowBox sx={{ p: 2 }}>
            <Typography variant="h4Black">{`Date: ${dateString(
              review?.createdAt
            )}`}</Typography>
            <Typography variant="h4Black" sx={{ ml: 3 }}>{`Time: ${timeString(
              review?.createdAt
            )}`}</Typography>
          </RowBox>
          <Divider />
          <FormProvider {...methods}>
            <Grid container sx={{ mt: 5 }} spacing={2}>
              <Row>
                <Typography variant="body0">Service</Typography>
                <RowBox sx={{ alignItems: "flex-start" }}>
                  <ImageBox
                    src={review?.product?.images?.[0]}
                    sx={{ width: 65, height: 67, borderRadius: 2 }}
                  />
                  <Typography variant="body0" sx={{ ml: 1 }}>
                    {review?.product?.title}
                  </Typography>
                </RowBox>
              </Row>
              <Row>
                <Typography variant="body0">Seller</Typography>
                <Typography variant="body0">
                  {review?.seller?.fullname}
                </Typography>
              </Row>
              <Row>
                <Typography variant="body0">Star Rating</Typography>
                <RatingControl name="rating" />
              </Row>
              <Row>
                <Typography variant="body0">Review</Typography>
                <MultiLineInputControl
                  name="comment"
                  placeholder="Enter review comment"
                  rows={5}
                  wsx={{ width: "100%" }}
                />
              </Row>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  sx={{ mt: 8, mb: 6, px: 5, alignSelf: "center" }}
                  onClick={methods.handleSubmit(submitHandler, errorHandler)}
                >
                  Save Changes
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
      {(updateMutation.isLoading || deleteMutation.isLoading) && (
        <ProgressIndicator />
      )}
    </Container>
  );
}
