import React, { useContext, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import {
  RowBox,
  ColBox,
  ProgressIndicator,
  SearchBar,
  Container
} from "../components/ui";

import { UserPicker } from "../components/app-ui";
import { GroupMemberTable } from "../components/tables";
import { useAlert } from "../components/alert";

export default function GroupMembers() {
  const { groupId } = useParams();
  const [page, setPage] = useState(0);
  const [q, setQuery] = useState();
  const [country, setCountry] = useState();
  const [sort, setSort] = useState();
  const [showUserPicker, setUserPicker] = useState(false);
  const alert = useAlert()

  const { fetchGroupMembers, addGroupMember, removeGroupMember } =
    useContext(APIContext);
  const { isLoading, data, isFetching } = useQuery(
    ["members", { groupId, page, q, country, sort }],
    () => fetchGroupMembers(groupId, page, q, country, sort),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const queryClient = useQueryClient();
  const addMemberMutation = useMutation(
    ({ groupId, userId }) => addGroupMember(groupId, userId),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("members");
        toast.success("Successfully added user from group");
      },
    }
  );

  const removeMemberMutation = useMutation(
    ({ groupId, userId }) => removeGroupMember(groupId, userId),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("members");
        toast.success("Successfully removed user from group");
      },
    }
  );

  const removeMemberHandler = (member) => {
    alert("Confirmation", "Are you sure to remove this user from the group?", () => {
      removeMemberMutation.mutate({
        groupId,
        userId: member.member?.id,
      });
    })
  };

  const addMemberHandler = (user) => {
    addMemberMutation.mutate({
      groupId,
      userId: user.id,
    });
  };

  return (
    <Container sx={{height: 'calc(100% - 33px)'}}>
        <RowBox sx={{ my: 1, pl:2, boxSizing: 'border-box' }}>
          <Typography
            variant="h4Black"
            sx={{ my: 2, mr: "auto" }}
          >{`Total Members: ${data?.totalResults || 0}`}</Typography>
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={() => setUserPicker(true)}
          >
            Add New Member
          </Button>
          <SearchBar value={q} onChange={setQuery} />
        </RowBox>
      <GroupMemberTable
        data={data}
        onRemove={removeMemberHandler}
        onSort={(s) => {
          setSort(s);
          setPage(0);
        }}
      />
      <UserPicker
        show={showUserPicker}
        onChange={addMemberHandler}
        onClose={() => setUserPicker(false)}
        group={groupId}
      />
      {(isLoading ||
        removeMemberMutation.isLoading ||
        addMemberMutation.isLoading) && <ProgressIndicator />}
    </Container>
  );
}
