import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import {
  RowBox,
  ColBox,
  ProgressIndicator,
  Container
} from "../components/ui";
import { UserProfile } from "../components/app-ui";

export default function UserFollowers() {
  const { userId } = useParams();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const { fetchUserFollowers } = useContext(APIContext);
  const { isLoading, data, isFetching } = useQuery(
    ["followers", { id: userId, page }],
    () => fetchUserFollowers(userId, page),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const userSelectHandler = (user) => {
    navigate(`/user-detail/${user?.id}`);
  }

  return (
    <Container sx={{p: 2}}>
      <ColBox>
        <RowBox>
          <Typography
            variant="h4Black"
            sx={{ mb: 2, mr: "auto" }}
          >{`Total Followers: ${data?.totalResults || 0}`}</Typography>
        </RowBox>
        <RowBox sx={{ flexWrap: "wrap" }}>
        {data?.results?.map((f) => (
          <UserProfile
            user={f?.follower}
            onClick={() => userSelectHandler(f?.follower)}
            sx={{padding: 1}}
          />
        ))}
        </RowBox>
      </ColBox>
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}
