import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router";

import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  styled,
  IconButton,
  Collapse,
} from "@mui/material";

import {
  RowBox,
  ColBox,
  SearchBar,
  SplitTabs,
  Container,
  ImageBox,
  InputSelect,
  CountryPick,
  ProgressIndicator,
} from "../../components/ui";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { ProfileImage } from "../../components/app-ui";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function Categories() {
  const navigate = useNavigate();
  const { fetchCategory } = useContext(APIContext);

  const { isLoading, data: mainCategories } = useQuery(
    ["category", { parent: null }],
    () => fetchCategory(),
    {
      select: (data) => data?.data,
    }
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Category</TableCell>
        <TableCell>Total Services</TableCell>
        <TableCell>Commission Fixed</TableCell>
        <TableCell>Commission Percentage</TableCell>
      </TableRow>
    </TableHead>
  );

  const CatRow = (props) => {
    const [open, setOpen] = useState(false);
    const { level, category } = props;

    const { data: subLevelCategories } = useQuery(
      ["category", { parent: category?.id }],
      () => fetchCategory(category?.id),
      {
        select: (data) => data.data,
      }
    );

    const toggle = () => setOpen((prev) => !prev);

    const StyledRow = styled(RowBox)(({ theme }) => ({
      marginLeft: level * 40,
      border: `1px solid ${theme.palette.border.main}`,
      width: "auto",
      paddingLeft: 10,
      paddingRight: 30,
      paddingTop: 8,
      paddingBottom: 8,
    }));

    return (
      <>
        <TableRow onClick={toggle}>
          <TableCell sx={{ borderBottom: "none" }}>
            <RowBox>
              <StyledRow>
                <IconButton>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <ProfileImage
                  image={category?.image}
                  sx={{ mr: 2 }}
                  variant="header"
                  rounded
                />
                <Typography>{category?.displayName}</Typography>
              </StyledRow>
            </RowBox>
          </TableCell>
          <TableCell sx={{ borderBottom: "none" }}>
            {category?.serviceCount}
          </TableCell>
          {level === 0 && (
            <>
              <TableCell sx={{ borderBottom: "none" }}>
                $ {category?.commissionFixed || 0}
              </TableCell>
              <TableCell sx={{ borderBottom: "none" }}>
                {category?.commissionPercent || 0}%
              </TableCell>
            </>
          )}
        </TableRow>
        {subLevelCategories?.length > 0 &&
          open &&
          subLevelCategories.map((c) => (
            <CatRow category={c} level={level + 1} />
          ))}
      </>
    );
  };

  const editHandler = () => {
    navigate("/ecommerce/category-edit");
  };

  return (
    <Container sx={{ mt: 1.25, height: "auto" }}>
      <RowBox
        sx={{
          pr: 2,
          pb: 2,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
        fullWidth
      >
        <SplitTabs
          menu={[
            {
              name: `All Categories (${mainCategories?.length || 0})`,
              link: "",
            },
          ]}
          hsx={{
            width: "auto",
            borderBottom: "none",
          }}
        />
        <Button variant="contained" sx={{ ml: "auto" }} onClick={editHandler}>
          Edit Categories
        </Button>
      </RowBox>
      <Table>
        <TableHeader />
        <TableBody>
          {mainCategories?.map((c) => {
            return <CatRow category={c} level={0} />;
          })}
          <TableRow></TableRow>
        </TableBody>
      </Table>
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}
