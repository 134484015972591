import React, { useContext, useEffect, useMemo } from "react";
import { Button } from "@mui/material";

import { useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { groupSchema } from "../utils/validation";
import { GroupForm } from "../components/form";
import { ColBox, ProgressIndicator } from "../components/ui";

import { getDirtyFields } from "../utils/utils";

export default function GroupInfo() {
  const { groupId } = useParams();

  const queryClient = useQueryClient();
  const { fetchGroup, updateGroup } = useContext(APIContext);

  const { isLoading, data: group } = useQuery(
    ["group", { id: groupId }],
    () => fetchGroup(groupId),
    {
      select: (data) => {
        return data?.data;
      },
    }
  );

  const updateMutation = useMutation((data) => updateGroup(groupId, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["group", { id: groupId }]);
      toast.success("Successfully updated group");
    },
  });

  const methods = useForm({
    resolver: yupResolver(groupSchema),
    defaultValues: {
      name: group?.name,
      description: group?.description,
      groupType: group?.isPrivate ? "private" : "public"
    }
  });

  useEffect(() => {
    /* Some timing issue, form values are not loading after page reload. */
    methods.reset({
      name: group?.name,
      description: group?.description,
      groupType: group?.isPrivate ? "private" : "public"
    });
  }, [group]);

  const dirtyFields = methods.formState.dirtyFields
  const submitHandler = (values) => {
    const data = getDirtyFields(values, dirtyFields)
    if (data) {
      if (data?.groupType) {
        data.isPrivate = data.groupType === 'private'
      }
      updateMutation.mutate(data)
    } else {
      toast.error("No changes to save")
    }
  };

  return (
    <ColBox center fullWidth>
      <FormProvider {...methods}>
        <GroupForm />
        <Button
          variant="contained"
          sx={{ mt: 8, mb: 6, px: 5 }}
          onClick={methods.handleSubmit(submitHandler)}
        >
          Save Changes
        </Button>
      </FormProvider>
      {(isLoading || updateMutation.isLoading) && <ProgressIndicator />}
    </ColBox>
  );
}
