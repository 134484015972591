import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  useTheme,
  Typography,
  TextField,
} from "@mui/material";
import { useLocation, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { categorySchema, groupSchema } from "../utils/validation";

import { ProfileImage } from "../components/app-ui";

import {
  PageTitle,
  RowBox,
  ColBox,
  ProgressIndicator,
  FileSelect,
} from "../components/ui";

import {
  CatSelect,
  MainCatSelect,
  SubCatSelect,
} from "../components/ecommerce-ui";

import { getNode, getDirtyFields } from "../utils/utils";
import { useCategory } from "../components/hooks";
import { useCrop } from "../components/alert";
import { CategoryForm } from "../components/form";

export default function CategorySettings(props) {
  const theme = useTheme();
  const [mainCategory, setMainCategory] = useState();

  const { updateCategory, fetchCategory } = useContext(APIContext);
  const queryClient = useQueryClient();

  const {data: categories} = useQuery(["categories"], () => fetchCategory(), {
    select: (data) => data.data
  })
  const node = categories?.find(c => c.id === mainCategory)
  console.log("Selected ", mainCategory, node)

  const updateMutation = useMutation(
    (data) => updateCategory(mainCategory, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["category"]);
        toast.success("Successfully updated Category");
      },
    }
  );

  const form = useForm({
    resolver: yupResolver(categorySchema),
    defaultValues: {
      image: node?.image,
      displayName: node?.displayName,
      commissionPercent: node?.commissionPercent || 0,
      commissionFixed: node?.commissionFixed || 0,
      fee: node?.fee || 0,
      feeName: node?.feeName || "",
    },
  });

  useEffect(() => {
    form.reset({
      image: node?.image,
      displayName: node?.displayName,
      commissionPercent: node?.commissionPercent || 0,
      commissionFixed: node?.commissionFixed || 0,
      fee: node?.fee || 0,
      feeName: node?.feeName || "",
    });
  }, [node]);

  const image = form.watch("image");

  const dirtyFields = form.formState.dirtyFields;
  const submitHandler = (values) => {
    const data = getDirtyFields(values, dirtyFields);
    console.log(data);
    if (data) {
        /* Backend is expecting photo key in file upload */
      data.photo = data.image;
      delete data.image
      updateMutation.mutate(data);
    } else {
      toast.error("No changes to save");
    }
  };

  return (
    <Box sx={{ p: 1.25, pl: 3, backgroundColor: "white" }}>
      <MainCatSelect
        value={mainCategory}
        onChange={setMainCategory}
        title="Main Category"
        wsx={{ flex: 1, maxWidth: 300 }}
      />

      {mainCategory && (
        <>
          <RowBox sx={{ mt: 5, alignItems: "flex-start" }}>
            <FormProvider {...form}>
              <ColBox center sx={{ mr: 3 }}>
                <ProfileImage image={image} variant="banner" />
                <FileSelect name="image" single crop>
                  <Typography
                    variant="body2"
                    sx={{
                      textDecoration: "underLine",
                      cursor: "pointer",
                      mt: 0.4,
                    }}
                  >
                    Change Image
                  </Typography>
                </FileSelect>
              </ColBox>

              <CategoryForm />
            </FormProvider>
          </RowBox>
          <RowBox fullWidth center sx={{ my: 4 }}>
            <Button
              variant="contained"
              onClick={form.handleSubmit(submitHandler)}
            >
              Save Changes
            </Button>
          </RowBox>
        </>
      )}

      {updateMutation.isLoading && <ProgressIndicator />}
    </Box>
  );
}
