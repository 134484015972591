import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";

import { useNavigate } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";

import {
  RowBox,
  ColBox,
  ProgressIndicator,
  Container,
} from "../components/ui";
import { PostModal } from "../components/app-ui";
import { PostTable } from "../components/tables";
import { useAlert } from "../components/alert";


export default function PostList() {
  const [page, setPage] = useState(0);
  const [post, setPost] = useState();
  const [shouldShowPost, showPost] = useState(false);
  const alert = useAlert()

  const { fetchPosts, deletePost } = useContext(APIContext);
  const navigate = useNavigate();

  const { isLoading, data, isFetching } = useQuery(
    ["posts", page],
    () => fetchPosts(page),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const queryClient = useQueryClient();
  const deletePostMutation = useMutation((postId) => deletePost(postId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("posts");
      queryClient.invalidateQueries("trash");
      toast.success("Successfully Moved post to Trash");
    },
  });

  const deletePostHandler = (post) => {
    alert("Confirmation", "Are you sure to move the post to Trash", () => {
      deletePostMutation.mutate(post?.id);
    })
  };

  const viewPostHandler = (post) => {
    setPost(post)
    showPost(true)
  };

  const editPostHandler = (post) => {
    navigate("/post/new", { state: post });
  };

  return (
    <Container sx={{ p: 2 }}>
      <ColBox>
        <Typography variant="h4Black" sx={{ my: 1 }}>{`Total admin posts: ${
          data?.totalResults || 0
        }`}</Typography>
      </ColBox>
      <PostTable
        posts={data?.results}
        onDeletePost={deletePostHandler}
        onEditPost={editPostHandler}
        onViewPost={viewPostHandler}
      />
      <PostModal
        show={shouldShowPost}
        onClose={() => showPost(false)}
        post={post}
      />
      {(isLoading || deletePostMutation.isLoading) && <ProgressIndicator />}
    </Container>
  );
}
