import React, { useState, useContext } from "react";
import { Box, Typography } from "@mui/material";
import {
  RowBox,
  SplitTabs,
  SearchBar,
  CountryPick,
  StatePick,
  InputSelect,
  ColBox,
  ProfessionPick,
  Container,
} from "../../components/ui";

import { Outlet } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { toast } from "react-toastify";

import { CustomerContext } from "../../services/context";

export default function Customers() {
  const [q, setQuery] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();

  const { fetchCustomers } = useContext(APIContext);
  const { data: allCustomers } = useQuery(["customers"], fetchCustomers);

  const menu = [
    {
      name: `All Customers (${allCustomers?.data?.totalResults || 0})`,
      link: "all",
    },
  ];

  const clearFilterHandler = () => {
    setCountry();
    setState();
  };

  return (
    <Container sx={{ mt: 1.25 }}>
      <RowBox
        sx={{
          pr: 2,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
        fullWidth
      >
        <SplitTabs
          menu={menu}
          hsx={{
            justifyContent: "flex-start",
            borderBottom: "none",
            width: "auto",
          }}
        />

        <Typography sx={{ ml: "auto", mr: 2 }}>Filter By:</Typography>
        <CountryPick
          value={country}
          onChange={(c) => {
            setCountry(c);
          }}
          fullWidth={false}
          sx={{ minWidth: 150 }}
        />
        {country && (
          <StatePick
            country={country}
            value={state}
            onChange={setState}
            fullWidth={false}
            sx={{ ml: 2, minWidth: 150 }}
          />
        )}
        <Typography
          sx={{ cursor: "pointer", textDecoration: "underline", ml: 2 }}
          onClick={clearFilterHandler}
        >
          Clear Filters
        </Typography>

        <SearchBar
          placeholder="Search Customers"
          sx={{ ml: "auto" }}
          value={q}
          onChange={setQuery}
        />
      </RowBox>

      <CustomerContext.Provider
        value={[q?.length > 2 ? q : null, country, state]}
      >
        <Outlet />
      </CustomerContext.Provider>
    </Container>
  );
}
