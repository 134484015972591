import React, { useContext, useState } from "react";
import {
  Container,
  ProgressIndicator,
  RowBox,
  InputSelect,
} from "../../components/ui";
import { Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { OrdersTable } from "../../components/tables";
import { OrderStatusOptions } from "../../config";

export default function SellerOrders(props) {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState();
  const [status, setStatus] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchSeller, fetchSellerOrders } = useContext(APIContext);

  const { data: seller } = useQuery(["seller", { id }], () => fetchSeller(id), {
    select: (data) => data?.data,
  });

  const { isLoading, data: orders } = useQuery(
    ["orders", { page, seller: seller?.user?.id, status }],
    () => fetchSellerOrders({ page, seller: seller?.user?.id, status }),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const viewHandler = (order) => {
    navigate(`/order-detail/${order?.id}`, { state: order });
  };

  const clearFilterHandler = () => {
    setPage(0)
    setStatus()
  }

  return (
    <Container sx={{ p: 2 }}>
      <RowBox sx={{ mb: 2 }}>
        <Typography variant="h4Black" sx={{ mr: 2 }}>{`Total Orders: ${
          orders?.totalResults || 0
        }`}</Typography>
        <InputSelect
          options={OrderStatusOptions}
          placeholder="Status"
          value={status}
          onChange={setStatus}
          sx={{ width: 200 }}
        />
        <Typography
          sx={{ cursor: "pointer", textDecoration: "underline", ml: 2 }}
          onClick={clearFilterHandler}
        >
          Clear Filters
        </Typography>
      </RowBox>

      <OrdersTable
        data={orders}
        onView={viewHandler}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}
