import { createTheme } from "@mui/material";

const colorTheme = createTheme({
  palette: {
    primary: {
      main: "#00284F",
      dark: "#00182F",
      light: "#667E9F",
    },
    info: {
      dark: "#828282",
      main: "#BDBDBD",
      light: "#E0E0E0",
      lightest: "#F2F2F2",
    },
    neutral: {
      dark: "#eae5ff",
      main: "#fff",
      light: "#F5F3FF",
    },
    background: {
      main: "#F2F2F2",
    },
    border: {
      main: "#0000003B",
    },
    success: {
      main: "#3AAC29",
    },
    error: {
      main: "#d32f2f",
    },
  },
});

let theme = createTheme();
theme = createTheme({
  palette: colorTheme.palette,
  typography: {
    fontFamily: "SF-Pro-Display",
    fontWeight: "normal",
    h2: {
      fontWeight: 500,
      fontSize: 20,
      color: "black",
    },
    h2Bold: {
      fontWeight: "bold",
      fontSize: 20,
      color: "black",
    },
    h3: {
      fontWeight: 500,
      fontSize: 16,
      color: "black",
    },
    h3Bold: {
      fontWeight: "bold",
      fontSize: 16,
      color: "black",
    },
    h4: {
      fontWeight: 500,
      fontSize: 14,
      color: colorTheme.palette.info.dark,
    },
    h4Black: {
      fontWeight: 500,
      fontSize: 14,
      color: "black",
    },
    h5: {
      fontWeight: 500,
      fontSize: 12,
      color: "black",
    },
    h5Gray: {
      fontWeight: 500,
      fontSize: 12,
      color: colorTheme.palette.info.dark,
    },
    h6: {
      fontWeight: 500,
      fontSize: 10,
      color: colorTheme.palette.info.dark,
    },
    body0: {
      fontWeight: "normal",
      fontSize: 14,
      color: "black",
    },
    body1: {
      fontWeight: "normal",
      fontSize: 12,
      color: "black",
    },
    body2: {
      fontWeight: "normal",
      fontSize: 12,
      color: colorTheme.palette.info.dark,
    },
    caption: {
      fontWeight: "bold",
      fontSize: 12,
      color: "black",
    },
    subtitle1: {
      fontWeight: "normal",
      fontSize: 10,
      color: colorTheme.palette.info.dark,
    },
    subtitle2: {
      fontWeight: "normal",
      fontSize: 8,
      letterSpacing: 2,
      color: "black",
    },
    error: {
      fontWeight: "bold",
      fontSize: 12,
      color: colorTheme.palette.error.main,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        fullWidth: true,
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: 4,
            "& .MuiOutlinedInput-root": {
              padding: 0
            },
            "& .MuiOutlinedInput-input": {
              paddingTop: 7,
              paddingBottom: 7,
              paddingLeft: 14,
              paddingRight: 14
            },
          },
        },
        {
          props: { variant: "filled" },
          style: {
            "& .MuiFilledInput-root": {
              borderRadius: 10,
              borderBottom: "none",
              justifyContent: "center",
              paddingTop: 0,
              paddingBottom: 0,
              backgroundColor: colorTheme.palette.neutral.light,
              paddingRight: 0,
            },
            "& .MuiFilledInput-root:hover, .MuiFilledInput-root.Mui-focused": {
              backgroundColor: colorTheme.palette.neutral.dark,
            },
            "& .MuiFilledInput-input": {
              paddingTop: 18,
              paddingBottom: 19,
              fontSize: 14,
              fontWeight: 500,
              paddingRight: 12,
            },
            "& .MuiFilledInput-root:hover:not(.Mui-disabled):before, .MuiFilledInput-root:before, .MuiFilledInput-root:after":
              {
                borderBottom: "none",
              },
          },
        },
      ],
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            borderRadius: 4,
            "& .MuiSelect-select": {
              paddingTop: 7,
              paddingBottom: 7,
            },
            "&:hover, &.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderWidth: 1,
              borderColor: colorTheme.palette.info.main,
            },
          },
        },
        {
          props: { variant: "filled" },
          style: {
            borderRadius: 10,
            backgroundColor: colorTheme.palette.neutral.light,
            fontSize: 14,
            fontWeight: 500,
            "& .MuiSelect-select": {
              paddingTop: 18,
              paddingBottom: 17,
            },
            "&:hover, &.Mui-focused": {
              backgroundColor: colorTheme.palette.neutral.dark,
            },
            "&:before, :hover:not(.Mui-disabled):before, :after": {
              borderBottom: "none",
            },
            "& .MuiSelect-select:focus": {
              backgroundColor: colorTheme.palette.neutral.dark,
              borderRadius: 10,
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            width: "100%",
            height: 54,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
            fontSize: 18,
            backgroundColor: colorTheme.palette.primary.main,
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: colorTheme.palette.primary.dark,
            },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            width: "100%",
            height: 54,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
            fontSize: 18,
            backgroundColor: colorTheme.palette.info.dark,
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: colorTheme.palette.info.main,
            },
          },
        },
        {
          props: { variant: "contained" },
          style: {
            minWidth: 120,
            borderRadius: 10,
            textTransform: "none",
            fontWeight: 500,
            fontSize: 14,
            paddingTop: 2,
            paddingBottom: 2,
            backgroundColor: colorTheme.palette.primary.main,
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: colorTheme.palette.primary.dark,
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            minWidth: 82,
            height: 34,
            borderRadius: 17,
            textTransform: "none",
            fontSize: 12,
            borderColor: colorTheme.palette.info.light,
          },
        },
        {
          props: { variant: "text" },
          style: {
            textTransform: "none",
            fontSize: 12,
            fontWeight: 500,
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variant: "body1",
      },
      styleOverrides: {
        root: {
          wordWrap: "break-word",
        },
      },
    },
    MuiToggleButtonGroup: {
      exclusive: true,
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          fontSize: 14,
          paddingTop: 4,
          paddingBottom: 4,
          textTransform: "none",
          "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: colorTheme.palette.primary.main,
          },
        },
      },
    },
    MuiImageList: {
      defaultProps: {
        cols: 3,
        gap: 8,
        rowHeight: 180,
      },
    },
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          fontFamily: "SF-Pro-Display",
          fontWeight: 500,
          fontSize: 14,
          "& .MuiImageListItemBar-titleWrap": {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
  },
});

export default theme;
