import React, { useState, useContext } from "react";
import { Box, Typography } from "@mui/material";
import {
  RowBox,
  SplitTabs,
  SearchBar,
  CountryPick,
  StatePick,
  InputSelect,
  ColBox,
  ProfessionPick,
  Container,
} from "../../components/ui";

import { Outlet } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { toast } from "react-toastify";

import { SellerTypeOptions } from "../../config";
import { SellerContext } from "../../services/context";

export default function Sellers() {
  const [q, setQuery] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [type, setType] = useState();

  const { fetchSellers } = useContext(APIContext);
  const { data: allSellers } = useQuery(["selers"], fetchSellers);
  const { data: pending } = useQuery(["selers", { status: "pending" }], () =>
    fetchSellers({ status: "pending" })
  );
  const { data: rejected } = useQuery(["selers", { status: "rejected" }], () =>
    fetchSellers({ status: "rejected" })
  );
  const { data: suspended } = useQuery(["selers", { status: "suspended" }], () =>
    fetchSellers({ status: "suspended" })
  );

  const menu = [
    {
      name: `All Sellers (${allSellers?.data?.totalResults || 0})`,
      link: "all",
    },
    {
      name: `Pending Sellers (${pending?.data?.totalResults || 0})`,
      link: "pending",
    },
    {
      name: `Rejected Sellers (${rejected?.data?.totalResults || 0})`,
      link: "rejected",
    },
    {
      name: `Suspended Sellers (${suspended?.data?.totalResults || 0})`,
      link: "suspended",
    },
  ];

  const clearFilterHandler = () => {
    setCountry();
    setState();
    setType();
  };

  return (
    <Container sx={{ mt: 1.25 }}>
      <RowBox
        sx={{
          pr: 2,
          pb: 2,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
        fullWidth
      >
        <ColBox fullWidth>
          <SplitTabs
            menu={menu}
            hsx={{
              justifyContent: "flex-start",
              borderBottom: "none",
              width: "auto",
            }}
          />

          <RowBox fullWidth>
            <Typography sx={{ ml: "auto", mr: 2 }}>Filter By:</Typography>
            <InputSelect
              value={type}
              onChange={setType}
              options={SellerTypeOptions}
              placeholder="Select Type"
              fullWidth={false}
              sx={{ mx: 2, minWidth: 150 }}
            />
            <CountryPick
              value={country}
              onChange={(c) => {
                setCountry(c);
              }}
              fullWidth={false}
              sx={{ minWidth: 150 }}
            />
            {country && (
              <StatePick
                country={country}
                value={state}
                onChange={setState}
                fullWidth={false}
                sx={{ ml: 2, minWidth: 150 }}
              />
            )}
            <Typography
              sx={{ cursor: "pointer", textDecoration: "underline", ml: 2 }}
              onClick={clearFilterHandler}
            >
              Clear Filters
            </Typography>

            <SearchBar
              placeholder="Search Sellers"
              sx={{ ml: "auto" }}
              value={q}
              onChange={setQuery}
            />
          </RowBox>
        </ColBox>
      </RowBox>

      <SellerContext.Provider
        value={{q, country, state, type}}
      >
        <Outlet />
      </SellerContext.Provider>
    </Container>
  );
}
