import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  useTheme,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
} from "@mui/material";
import { useLocation, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { toast } from "react-toastify";
import { useAlert } from "../../components/alert";

import {
  PageTitle,
  RowBox,
  ColBox,
  InputSelect,
  ProgressIndicator,
} from "../../components/ui";

import { OrderSummary, ProductSummary } from "../../components/ecommerce-ui";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { productSchema } from "../../utils/validation";

import { dateString, getDirtyFields, timeString } from "../../utils/utils";
import { OrderStatus, OrderStatusOptions } from "../../config";

export default function OrderDetail(props) {
  const theme = useTheme();
  const { id } = useParams();
  const [shouldShowConfirm, showConfirm] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState();
  const [orderItem, setOrderItem] = useState();
  const [newStatus, setStatus] = useState();
  const alert = useAlert();

  const queryClient = useQueryClient();
  const { fetchOrder, updateOrderItemStatus } = useContext(APIContext);

  const { isLoading, data: order } = useQuery(
    ["order", { id }],
    () => fetchOrder(id),
    {
      select: (data) => data?.data,
      enabled: !!id,
    }
  );

  const updateMutation = useMutation(
    ({ itemId, status }) => updateOrderItemStatus(id, itemId, status),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["orders", { id }]);
        toast.success("Successfully updated order");
      },
    }
  );

  const SytledTableCell = styled(TableCell)(() => ({
    borderBottom: "none",
  }));

  const downloadHandler = (item) => {
    const url = item?.product?.files?.[0]?.url;
    if (url) {
      window.open(url, "_blank");
    } else {
      toast.error("Error downloading file. Contact system administrator.");
    }
  };

  const statusChangeHandler = (item, status) => {
    alert(
      "Confirmation",
      `Are you sure to move the order status from '${
        OrderStatus[item?.status]
      }' to '${OrderStatus[status]}'`,
      () => {
        updateMutation.mutate({
          itemId: item?.id,
          status: status,
        });
      }
    );
  };

  const cancelOrderHandler = (item) => {
    alert(
      "Confirmation",
      `Are you sure to move the "Cancel the Order" and issue "Refund"`,
      () => {
        updateMutation.mutate({
          itemId: item?.id,
          status: "cancelled",
        });
      }
    );
  };

  return (
    <Box sx={{ mt: 1.25, backgroundColor: "white" }}>
      <PageTitle title="Order Details" back />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={2}
          sx={{
            borderRight: `1px solid ${theme?.palette?.info?.light}`,
            display: "flex",
            flex: 1,
          }}
        >
          <OrderSummary order={order} />
        </Grid>
        <Grid item xs={10}>
          <RowBox sx={{ py: 2, px: 1 }}>
            <Typography variant="body0">{`Order Number: ${order?.orderId}`}</Typography>
            <Typography
              variant="body0"
              sx={{ ml: "auto" }}
            >{`Order Date: ${dateString(order?.orderDate)}`}</Typography>
            <Typography variant="body0" sx={{ ml: 2 }}>{`Time: ${timeString(
              order?.orderDate
            )}`}</Typography>
          </RowBox>
          <Divider />
          <ColBox sx={{ px: 1, py: 2 }} fullWidth>
            <Typography variant="h4Black" sx={{ textDecoration: "underline" }}>
              Order Summary
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Seller</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.items?.map((item) => {
                  const product = item?.product;
                  return (
                    <>
                      <TableRow>
                        <SytledTableCell>
                          <ColBox>
                            <Typography variant="body0">
                              {product?.title}
                            </Typography>
                            <Typography variant="body2">
                              {product?.mainCategoryName}
                            </Typography>
                            {product?.category && (
                              <Typography variant="body2">
                                {item?.product?.categoryName}
                              </Typography>
                            )}
                            {product?.subCategory && (
                              <Typography variant="body2">
                                {item?.product?.subCategoryName}
                              </Typography>
                            )}
                          </ColBox>
                        </SytledTableCell>
                        <SytledTableCell>{item?.quantity}</SytledTableCell>
                        <SytledTableCell>
                          {item?.product?.seller?.fullname}
                        </SytledTableCell>
                        <SytledTableCell>{item?.price}</SytledTableCell>
                        <SytledTableCell>{item?.status}</SytledTableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <RowBox>
                            <Button variant="outlined">View Chat</Button>
                            {product?.productSchema?.file && (
                              <Button
                                variant="outlined"
                                sx={{ ml: 2 }}
                                onClick={() => downloadHandler(item)}
                              >
                                Download
                              </Button>
                            )}
                          </RowBox>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <RowBox sx={{ justifyContent: "flex-end" }}>
                            <InputSelect
                              options={OrderStatusOptions}
                              placeholder="Update Order status"
                              value={item?.status}
                              onChange={(v) => statusChangeHandler(item, v)}
                              sx={{ width: 200 }}
                            />
                            <Button
                              variant="outlined"
                              sx={{ ml: 2 }}
                              onClick={() => cancelOrderHandler(item)}
                            >
                              Cancel and Refund
                            </Button>
                          </RowBox>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </ColBox>
        </Grid>
      </Grid>
      {((id && isLoading) || updateMutation.isLoading) && <ProgressIndicator />}
    </Box>
  );
}
