import React, { useState, useContext } from "react";
import { Grid, useTheme } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";
import { useAlert } from "../components/alert";

import { UserSummary } from "../components/app-ui";
import {
  Container,
  PageTitle,
  ProgressIndicator,
  Tabs
} from "../components/ui";

export default function UserDetail() {
  const { userId } = useParams();
  const theme = useTheme();
  const alert = useAlert();

  const queryClient = useQueryClient();
  const { fetchUser, removeUserProfile, suspendUser } = useContext(APIContext);

  const { isLoading, data: user } = useQuery(
    ["user", { id: userId }],
    () => fetchUser(userId),
    {
      select: (data) => data?.data,
    }
  );

  const removeProfileMutation = useMutation(() => removeUserProfile(userId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user", { id: userId }]);
      toast.success("Successfully removed profile image");
    },
  });

  const suspendMutation = useMutation(
    () => suspendUser(user?.id, !user?.isSuspended),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user", { id: user?.id }]);
        toast.success("Successfully saved changes");
      },
    }
  );

  const menu = [
    {
      name: "Profile Details",
      link: "info",
    },
    {
      name: `All Posts`,
      link: "posts",
    },
    {
      name: `Images/Videos`,
      link: "media",
    },
    {
      name: `Comments`,
      link: "comments",
    },
    {
      name: `Followers`,
      link: "followers",
    },
    {
      name: `Following`,
      link: "following",
    },
  ];

  const removeProfileHandler = () => {
    removeProfileMutation.mutate();
  };

  const suspendHandler = () => {
    alert(
      "Confirmation",
      `Are you sure to ${user?.isSuspended ? "unsuspend" : "suspend"} user ${
        user?.fullname
      } ?`,
      () => {
        suspendMutation.mutate();
      }
    );
  };

  return (
    <Container sx={{ mt: 1.25, pb: 2, pr: 2 }}>
      <PageTitle title={user?.fullname} back />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            borderRight: `1px solid ${theme.palette.info.light}`,
            display: "flex",
            flex: 1,
          }}
        >
          {user && (
            <UserSummary
              sx={{ mt: 1 }}
              onRemoveProfile={removeProfileHandler}
              onSuspend={suspendHandler}
              {...user}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={10}>
          <Tabs menu={menu} />
          <Outlet />
        </Grid>
      </Grid>
      {(removeProfileMutation.isLoading || isLoading) && <ProgressIndicator />}
    </Container>
  );
}
