import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  useTheme,
  Typography,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
} from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";

import { PageTitle, RowBox, ColBox, Container } from "../../components/ui";
import { CustomerSummary } from "../../components/app-ui";
import { CustomerOrdersTable } from "../../components/tables";

export default function CustomerDetail(props) {
  const [page, setPage] = useState(0);
  const theme = useTheme();
  const { id: userId } = useParams();
  const user = useLocation()?.state;
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchOrders } = useContext(APIContext);
  const { isLoading, data: orders } = useQuery(
    ["orders", { page, buyer: userId }],
    () => fetchOrders({ page, buyer: userId }),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const viewHandler = (order) => {
    navigate(`/order-detail/${order?.id}`, { state: order });
  };

  return (
    <Container sx={{ mt: 1 }}>
      <PageTitle title="Customer Orders" back />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={2}
          sx={{
            borderRight: `1px solid ${theme?.palette?.info?.light}`,
            display: "flex",
            flex: 1,
          }}
        >
          <CustomerSummary user={user} />
        </Grid>
        <Grid item xs={10}>
          <ColBox fullWidth sx={{ height: "100%" }}>
            <RowBox sx={{ py: 2, px: 1 }}>
              <Typography variant="h4Black">{`Total Orders Placed: ${user?.orderCount}`}</Typography>
              <Typography
                variant="h4Black"
                sx={{ ml: 4 }}
              >{`Total value of orders placed: $ ${user?.orderValue}`}</Typography>
            </RowBox>
            <Divider />
            <CustomerOrdersTable
              data={orders}
              onView={viewHandler}
              page={page}
              onPageChange={setPage}
              //onSort={(s) => {setSort(s); setPage(0)}}
            />
          </ColBox>
        </Grid>
      </Grid>
      {/* {isLoading && <ProgressIndicator />} */}
    </Container>
  );
}
