import React, { createContext, useContext, useState } from "react";
import { PhotoPopup } from "./app-ui";
import { ConfirmDialog, ImageCropDialog } from "./ui";

const AlertContext = createContext();

export const AlertProvider = (props) => {
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [onConfirm, setConfirm] = useState();

  const [image, setImage] = useState();
  const [onCrop, setOnCrop] = useState();

  const [media, setMedia] = useState();

  const [type, setType] = useState("confirm");
  const [isShowing, show] = useState();

  const alert = (title, body, onConfirm) => {
    setType("confirm");
    setTitle(title);
    setBody(body);
    setConfirm(() => onConfirm);
    show(true);
  };

  const cropImage = (image, onCrop) => {
    setType("crop");
    setImage(image);
    setOnCrop(() => onCrop);
    show(true);
  };

  const showPhoto = (media) => {
    setType("photo");
    setMedia(media);
    show(true)
  };

  const cancelHandler = () => show(false);

  const confirmHandler = () => {
    show(false);
    onConfirm();
  };

  const cropCompleteHandler = (image) => {
    show(false);
    onCrop(image);
  };

  return (
    <AlertContext.Provider value={{ alert, cropImage, showPhoto }}>
      {props?.children}
      {type === "confirm" && (
        <ConfirmDialog
          show={isShowing}
          title={title}
          body={body}
          onCancel={cancelHandler}
          onConfirm={confirmHandler}
        />
      )}
      {type === "crop" && (
        <ImageCropDialog
          image={image instanceof File ? URL.createObjectURL(image) : image}
          show={isShowing}
          onCrop={cropCompleteHandler}
          onCancel={cancelHandler}
        />
      )}
      {type === "photo" && (
        <PhotoPopup show={isShowing} media={media} onClose={cancelHandler} />
      )}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const { alert } = useContext(AlertContext);
  return alert;
};

export const useCrop = () => {
  const { cropImage } = useContext(AlertContext);
  return cropImage;
};

export const usePhoto = () => {
  const {showPhoto} = useContext(AlertContext)
  return showPhoto
};
