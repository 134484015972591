import React, { useState, useContext, useEffect } from "react";
import { Grid, Box, useTheme } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import {
  PageTitle,
  ColBox,
  ProgressIndicator,
} from "../components/ui";

import { ProductSummary, SettingsSideBar } from "../components/ecommerce-ui";
import { ServiceForm } from "../components/form";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { productSchema } from "../utils/validation";

import { getDirtyFields } from "../utils/utils";
import { useAlert, useCrop, usePhoto } from "../components/alert";
import { settingsMenu } from "../config";

export default function Settings(props) {
  const { id } = useParams();
  const alert = useAlert();
  const cropImage = useCrop();
  const showPhoto = usePhoto()
  const product = {}

  const theme = useTheme();

  const queryClient = useQueryClient();
  const { fetchProduct, addProductImage, deleteProductImage, updateProduct } =
    useContext(APIContext);
    
  const form = useForm({
    resolver: yupResolver(productSchema(product?.productSchema)),
    mode: "onChange",
    defaultValues: product || {},
  });

  // useEffect(() => {
  //   const { files, noOfRevisions, ...rest } = product || {};
  //   form?.reset({
  //     file: files?.[0],
  //     noOfRevisions: noOfRevisions?.toString(),
  //     country: "India",
  //     ...rest,
  //   });
  // }, [product]);

  const addImageHandler = (file) => {
    cropImage(file, (cropped) => {
    //   addImageMutation.mutate({
    //     images: [cropped],
    //   });
    });
  };


  const dirtyFields = form.formState.dirtyFields;
  const submitHandler = (values) => {
    const data = getDirtyFields(values, dirtyFields);
    if (data) {
     // updateMutation.mutate(data);
    } else {
      toast.error("No changes to save");
    }
  };

  const errorHandler = (error) => {
    console.log(error);
  };

  return (
    <Box sx={{ mt: 1.25, backgroundColor: "white" }}>
      <PageTitle title="Settings" />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={2}
          sx={{
            borderRight: `1px solid ${theme?.palette?.info?.light}`,
            display: "flex",
            flex: 1,
          }}
        >
            <SettingsSideBar items={settingsMenu} />
        </Grid>
        <Grid item xs={10}>
          <Outlet />
        </Grid>
      </Grid>
      {(false) && <ProgressIndicator />}
    </Box>
  );
}