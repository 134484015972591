import React, { createContext, useCallback, useMemo } from "react";
import queryString from "query-string";
import axios from "axios";
import _ from "lodash";

import createAuthRefreshInterceptor from "axios-auth-refresh";

import { useSelector, useDispatch } from "react-redux";
import { store } from "../store/store";
import { logout, setToken } from "../store/auth";

import { getCountryCode, getStateCode } from "../utils/utils";

const baseUrl = process.env.REACT_APP_API_BASE_URL + "/v1/";
console.log("Base Url", baseUrl);

const APIContext = createContext();
const { Provider } = APIContext;

const APIProvider = ({ children }) => {
  const dispatch = useDispatch();

  const headers = {
    "Content-Type": "application/json",
  };

  const formHeaders = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: baseUrl,
        headers: headers,
      }),
    []
  );

  const getToken = () => {
    const token = store.getState().auth.token;
    return token;
  };

  const getRefreshToken = () => {
    const token = store.getState().auth.refreshToken;
    return token;
  };

  axiosInstance.interceptors.request.use((request) => {
    const token = getToken();
    if (token) {
      request.headers["Authorization"] = `bearer ${getToken()}`;
    }
    return request;
  });

  const refreshAuthLogic = async (failedRequest) => {
    try {
      const response = await axiosInstance.post(
        "auth/refresh-tokens",
        {
          refreshToken: getRefreshToken(),
        },
        { skipAuthRefresh: true }
      );
      dispatch(setToken(response?.data));
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + response?.data?.access?.token;
      return Promise.resolve();
    } catch (err) {
      console.log("Interceptor error ", err?.response);
      if (err?.response.status === 401) {
        dispatch(logout());
      }
      throw err;
    }
  };

  createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

  const login = useCallback(
    (data) => {
      return axiosInstance.post("auth/login", data);
    },
    [axiosInstance]
  );

  const fetchUser = useCallback(
    (id) => {
      return axiosInstance.get(`user/${id}`);
    },
    [axiosInstance]
  );

  const fetchAllUsers = useCallback(
    (q, group) => {
      let url = `user?limit=100`;
      url += q ? `&q=${q}` : "";
      url += group ? `&group=${group}` : "";
      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchUsers = useCallback(
    (page = 0, type, q, country, state, profession, sort) => {
      let url = `user?page=${page + 1}`;
      url += q ? `&q=${q}` : "";
      url += country ? `&country=${country}` : "";
      url += state ? `&state=${state}` : "";
      url += profession ? `&profession=${profession}` : "";
      url += sort ? `&sort=${sort}` : "";
      url += type ? `&type=${type}` : "";
      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const removeUserProfile = useCallback((userId) => {
    return axiosInstance.delete(`user/${userId}/profile`);
  });

  const updateUser = useCallback(
    (userId, body) => {
      return axiosInstance.patch(`user/${userId}`, body);
    },
    [axiosInstance]
  );

  const suspendUser = useCallback((userId, status) => {
    return axiosInstance.put(`user/${userId}/suspend?status=${status}`);
  });

  const fetchPosts = useCallback(
    (page = 0) => {
      return axiosInstance.get(`post/admin?page=${page + 1}`);
    },
    [axiosInstance]
  );

  const fetchDeletedPosts = useCallback(
    (page = 0) => {
      return axiosInstance.get(`post?type=deleted&page=${page + 1}`);
    },
    [axiosInstance]
  );

  const createPost = useCallback(
    (data) => {
      return axiosInstance.post("post", toPostFormData(data), formHeaders);
    },
    [axiosInstance]
  );

  const editPost = useCallback(
    (postId, data) => {
      return axiosInstance.patch(
        `post/${postId}`,
        toPostFormData(data),
        formHeaders
      );
    },
    [axiosInstance]
  );

  const deletePost = useCallback(
    (postId) => {
      return axiosInstance.delete(`/post/${postId}`);
    },
    [axiosInstance]
  );

  const fetchPostComments = useCallback(
    (postId) => {
      return axiosInstance.get(`/post/${postId}/comment`);
    },
    [axiosInstance]
  );

  const fetchGroups = useCallback(
    ({ page = 0, type, q, sort, limit }) => {
      let url = `/group?page=${page + 1}`;
      url += q ? `&q=${q}` : "";
      url += sort ? `&sort=${sort}` : "";
      url += type ? `&type=${type}` : "";
      url += limit ? `&limit=${limit}` : "";
      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchGroup = useCallback(
    (groupId) => {
      return axiosInstance.get(`/group/${groupId}`);
    },
    [axiosInstance]
  );

  const fetchGroupPosts = useCallback(
    ({ pageParam = 0 }) => {
      return axiosInstance.get(`/group?page=${pageParam + 1}`);
    },
    [axiosInstance]
  );

  const fetchYourGroups = useCallback(
    ({ pageParam = 0 }) => {
      return axiosInstance.get(`/group/myGroups?page=${pageParam + 1}`);
    },
    [axiosInstance]
  );

  const fetchDiscoverGroups = useCallback(
    ({ pageParam = 0 }) => {
      return axiosInstance.get(`/group/discover?page=${pageParam + 1}`);
    },
    [axiosInstance]
  );

  const fetchUserPosts = useCallback(
    (id, page = 0) => {
      return axiosInstance.get(
        `user/${id}/posts?postType=Post&page=${page + 1}`
      );
    },
    [axiosInstance]
  );

  const fetchUserPhotos = useCallback(
    (id, page = 0) => {
      return axiosInstance.get(`user/${id}/posts?type=image&page=${page + 1}`);
    },
    [axiosInstance]
  );

  const fetchUserVideos = useCallback(
    (id, page = 0) => {
      return axiosInstance.get(`user/${id}/posts?type=video&page=${page + 1}`);
    },
    [axiosInstance]
  );

  const fetchUserMedia = useCallback(
    (id, page = 0) => {
      return axiosInstance.get(`user/${id}/posts?type=media&page=${page + 1}`);
    },
    [axiosInstance]
  );

  const fetchUserComments = useCallback(
    (id, page, sort) => {
      let url = `/user/${id}/comments?page=${page + 1}`;
      if (sort) url += `&sort=${sort}`;

      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchUserFollowers = useCallback(
    (id, page, sort) => {
      let url = `/user/${id}/followers?page=${page + 1}`;
      if (sort) url += `&sort=${sort}`;

      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchUserFollowing = useCallback(
    (id, page, sort) => {
      let url = `/user/${id}/following?page=${page + 1}`;
      if (sort) url += `&sort=${sort}`;

      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchMedia = useCallback(
    (type, page = 0, q, sort) => {
      let url = `media?type=${type}&page=${page + 1}`;
      url += q ? `&q=${q}` : "";
      url += sort ? `&sort=${sort}` : "";
      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchBlockReport = useCallback(
    (page, country, sort) => {
      let url = `/user/blockReport?page=${page + 1}`;
      if (country) url += `&country=${country}`;
      if (sort) url += `&sort=${sort}`;
      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchGroupActivity = useCallback(
    (id, page = 0, q, sort) => {
      let url = `/post/group/${id}?page=${page + 1}`;
      if (q) url += `&q=${q}`;
      if (sort) url += `&sort=${sort}`;

      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchGroupComments = useCallback(
    (id, page = 0, q, sort) => {
      let url = `/post/group/${id}/comments?page=${page + 1}`;
      if (q) url += `&q=${q}`;
      if (sort) url += `&sort=${sort}`;

      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchGroupMedia = useCallback(
    (id, page = 0, q, sort) => {
      let url = `/media/group/${id}?page=${page + 1}`;
      if (q) url += `&q=${q}`;
      if (sort) url += `&sort=${sort}`;

      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchGroupMembers = useCallback(
    (id, page = 0, q, country, sort) => {
      let url = `/group/${id}/members?page=${page + 1}`;
      if (q) url += `&q=${q}`;
      if (country) url += `&country=${country}`;
      if (sort) url += `&sort=${sort}`;

      return axiosInstance.get(url);
    },
    [axiosInstance]
  );

  const fetchGroupPhotos = useCallback(
    ({ id, pageParam = 0 }) => {
      return axiosInstance.get(
        `group/${id}/posts?type=photo&page=${pageParam + 1}`
      );
    },
    [axiosInstance]
  );

  const fetchGroupVideos = useCallback(
    ({ id, pageParam = 0 }) => {
      return axiosInstance.get(
        `group/${id}/posts?type=video&page=${pageParam + 1}`
      );
    },
    [axiosInstance]
  );

  const removeGroupMember = useCallback(
    (groupId, userId) => {
      return axiosInstance.get(`group/${groupId}/remove/${userId}`);
    },
    [axiosInstance]
  );

  const addGroupMember = useCallback(
    (groupId, userId) => {
      return axiosInstance.get(`group/${groupId}/add/${userId}`);
    },
    [axiosInstance]
  );

  const createGroup = useCallback(
    (data) => {
      return axiosInstance.post(`group`, toFormData(data), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    [axiosInstance]
  );

  const updateGroup = useCallback(
    (groupId, data) => {
      return axiosInstance.patch(
        `group/${groupId}`,
        toFormData(data),
        formHeaders
      );
    },
    [axiosInstance]
  );

  const removeGroupProfile = useCallback((groupId) => {
    return axiosInstance.delete(`group/${groupId}/profile`);
  });

  const deleteGroup = useCallback((groupId) => {
    return axiosInstance.delete(`group/${groupId}`);
  });

  const fetchReports = useCallback((page, type, category, sort) => {
    let url = `report?page=${page + 1}`;
    if (type) url += `&type=${type}`;
    if (category) url += `&category=${category}`;
    if (sort) url += `&sort=${sort}`;

    return axiosInstance.get(url);
  });

  const updateReport = useCallback((id, body) => {
    return axiosInstance.patch(`report/${id}`, body);
  });

  const fetchActivity = useCallback((page, type, duration, q, sort) => {
    let url = `audit?page=${page + 1}`;
    if (type) url += `&type=${type}`;
    if (duration) url += `&duration=${duration}`;
    if (sort) url += `&sort=${sort}`;
    if (q) url += `&q=${q}`;

    return axiosInstance.get(url);
  });

  const fetchConversations = useCallback((page, sort, name) => {
    return axiosInstance.get(
      `/message?${queryString.stringify({
        page: page + 1,
        sort,
        name,
        type: `all`,
      })}`
    );
  });

  const fetchMessages = useCallback((conv, page) => {
    return axiosInstance.get(
      `/message/conv/${conv}?${queryString.stringify({
        page: page + 1,
      })}`
    );
  });

  /* Ecommerce APIs BEGIN */
  const fetchCategory = useCallback(
    (catId) => {
      if (catId) {
        return axiosInstance.get(`ecommerce/category/${catId}`);
      } else {
        return axiosInstance.get(`ecommerce/category`);
      }
    },
    [axiosInstance]
  );

  const updateCategory = useCallback(
    (catId, data) => {
      let path = `ecommerce/category/${catId}`;
      return axiosInstance.put(path, toFormData(data), formHeaders);
    },
    [axiosInstance]
  );

  const fetchProducts = useCallback(
    ({
      pageParam = 0,
      mainCategory,
      category,
      subCategory,
      query,
      sort,
      filter,
    }) => {
      let path = `ecommerce/product/${mainCategory}`;

      if (category) {
        path += `/${category}`;
      }

      if (subCategory) {
        path += `/${subCategory}`;
      }

      const params = new URLSearchParams();
      params.append("page", pageParam + 1);

      if (query) {
        params.append("q", query);
      }

      if (sort) {
        params.append("sort", sort);
      }

      if (filter) {
        const f = _.omitBy(filter, _.isNil);
        for (let key in f) {
          if (key === "languages") {
            for (let l in f[key]) {
              params.append("languages", f[key][l]);
            }
          } else {
            params.append(key, f[key]);
          }
        }
      }

      return axiosInstance.get(`${path}?${params.toString()}`);
    },
    [axiosInstance]
  );

  const fetchAllProducts = useCallback(
    ({
      page,
      status,
      q,
      seller,
      country,
      mainCategory,
      category,
      subCategory,
      availability,
    }) => {
      return axiosInstance.get(
        `ecommerce/product?${queryString.stringify({
          page: page + 1,
          status,
          q,
          seller,
          country,
          mainCategory,
          category,
          subCategory,
          availability,
        })}`
      );
    },
    [axiosInstance]
  );

  const sellerRegistration = useCallback(
    (data) => {
      return axiosInstance.post("ecommerce/product", toFormData(data));
    },
    [axiosInstance]
  );

  const addProduct = useCallback(
    (data) => {
      return axiosInstance.post(
        "ecommerce/product",
        toServiceFormData(data),
        formHeaders
      );
    },
    [axiosInstance]
  );

  const updateProduct = useCallback((id, data) => {
    return axiosInstance.post(
      `ecommerce/product/${id}`,
      toServiceFormData(data),
      formHeaders
    );
  });

  const fetchProduct = useCallback(
    (id) => {
      return axiosInstance.get(`ecommerce/product/${id}`);
    },
    [axiosInstance]
  );

  const addProductImage = useCallback(
    (id, data) => {
      return axiosInstance.put(
        `ecommerce/product/${id}/image`,
        toServiceFormData(data),
        formHeaders
      );
    },
    [axiosInstance]
  );

  const deleteProductImage = useCallback(
    (id, index) => {
      return axiosInstance.delete(`ecommerce/product/${id}/image/${index}`);
    },
    [axiosInstance]
  );

  const fetchCities = useCallback(
    (country, state) => {
      const countryCode = getCountryCode(country);
      const stateCode = getStateCode(country, state);
      return axiosInstance.get(`country/${countryCode}/${stateCode}`);
    },
    [axiosInstance]
  );

  const fetchOrders = useCallback(
    ({ page = 0, q, status, buyer }) => {
      return axiosInstance.get(
        `ecommerce/order?${queryString.stringify({ page: page +1, q, status, buyer })}`
      );
    },
    [axiosInstance]
  );

  const fetchOrder = useCallback(
    (id) => {
      return axiosInstance.get(
        `ecommerce/order/${id}`
      );
    },
    [axiosInstance]
  );


  const fetchSellerOrders = useCallback(
    ({ page = 0, seller, status }) => {
      return axiosInstance.get(
        `ecommerce/order/seller/${seller}?${queryString.stringify({
          page: page + 1,
          status,
        })}`
      );
    },
    [axiosInstance]
  );

  const updateOrderItemStatus = useCallback(
    (orderId, itemId, status) => {
      return axiosInstance.patch(
        `ecommerce/order/${orderId}/${itemId}?${queryString.stringify({
          status,
        })}`
      );
    },
    [axiosInstance]
  );

  const fetchSeller = useCallback(
    (id) => {
      return axiosInstance.get(`ecommerce/seller/${id}`);
    },
    [axiosInstance]
  );

  const fetchSellers = useCallback(
    ({ page = 0, type, country, state, status, q }) => {
      return axiosInstance.get(
        `ecommerce/seller?${queryString.stringify({
          q,
          page: page + 1,
          type,
          country,
          state,
          status,
        })}`
      );
    },
    [axiosInstance]
  );

  const updateSeller = useCallback(
    (id, data) => {
      const formData = toFormData(data);
      printFormData(formData);
      return axiosInstance.post(
        `ecommerce/seller/${id}`,
        toFormData(data),
        formHeaders
      );
    },
    [axiosInstance]
  );

  const updateSellerBadge = useCallback(
    (id, value) => {
      return axiosInstance.patch(`user/${id}/badge?value=${value}`);
    },
    [axiosInstance]
  );

  const fetchCustomers = useCallback(
    ({ page = 0, q, country, state }) => {
      return axiosInstance.get(
        `user/customers?${queryString.stringify({ page: page + 1, q, country, state })}`
      );
    },
    [axiosInstance]
  );

  const fetchReviews = useCallback(
    ({ page = 0, q, status }) => {
      return axiosInstance.get(
        `ecommerce/review?${queryString.stringify({ page: page + 1, q, status })}`
      );
    },
    [axiosInstance]
  );

  const getReview = useCallback(
    (id) => {
      return axiosInstance.get(`ecommerce/review/${id}`);
    },
    [axiosInstance]
  );

  const updateReview = useCallback(
    (id, data) => {
      return axiosInstance.patch(`ecommerce/review/${id}`, data);
    },
    [axiosInstance]
  );

  const deleteReview = useCallback(
    (id) => {
      return axiosInstance.delete(`ecommerce/review/${id}`);
    },
    [axiosInstance]
  );

  const approveReview = useCallback(
    (id) => {
      return axiosInstance.post(`ecommerce/review/${id}/approve`);
    },
    [axiosInstance]
  );

  const apis = useMemo(
    () => ({
      login,
      fetchUser,
      fetchUsers,
      fetchAllUsers,
      removeUserProfile,
      updateUser,
      fetchPosts,
      fetchDeletedPosts,
      createPost,
      editPost,
      deletePost,
      fetchPostComments,
      fetchMedia,
      fetchGroups,
      fetchGroup,
      fetchGroupPosts,
      fetchYourGroups,
      fetchDiscoverGroups,
      fetchUserPosts,
      fetchUserPhotos,
      fetchUserVideos,
      fetchUserMedia,
      fetchUserComments,
      fetchGroupActivity,
      fetchGroupComments,
      fetchUserFollowers,
      fetchUserFollowing,
      fetchGroupMedia,
      fetchGroupMembers,
      fetchGroupPhotos,
      fetchGroupVideos,
      addGroupMember,
      removeGroupMember,
      createGroup,
      updateGroup,
      removeGroupProfile,
      deleteGroup,
      fetchReports,
      updateReport,
      fetchActivity,
      suspendUser,
      fetchBlockReport,
      fetchConversations,
      fetchMessages,
      fetchCategory,
      updateCategory,
      fetchProducts,
      fetchProduct,
      fetchAllProducts,
      addProduct,
      updateProduct,
      addProductImage,
      deleteProductImage,
      fetchCities,
      fetchOrders,
      fetchOrder,
      fetchSellerOrders,
      updateOrderItemStatus,
      sellerRegistration,
      fetchSellers,
      fetchSeller,
      updateSeller,
      updateSellerBadge,
      fetchCustomers,
      getReview,
      fetchReviews,
      updateReview,
      deleteReview,
      approveReview,
    }),
    [axiosInstance]
  );

  return <Provider value={apis}>{children}</Provider>;
};

export { APIContext, APIProvider };

const toFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (_.isNil(data[key])) {
      continue;
    }
    formData.append(key, data[key]);
  }
  return formData;
};

const toPostFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (_.isNil(data[key])) {
      continue;
    }
    if (key === "media") {
      for (let i = 0; i < data.media.length; i++) {
        const media = data.media[i];
        if (media instanceof File) {
          formData.append(key, media);
        } else {
          //These are data from server. Just send the ids.
          formData.append("media[]", media?.id);
        }
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

const toServiceFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (_.isNil(data[key])) {
      continue;
    }

    if (key === "images") {
      const photos = data[key];
      for (let i = 0; i < photos?.length; i++) {
        formData.append("images", photos[i]);
      }
      continue;
    }

    if (
      key === "availability" ||
      key === "duration" ||
      key === "packages" ||
      key === "wordDelivery"
    ) {
      for (let subKey in data[key]) {
        if (subKey === "_id") continue;
        for (let objkey in data[key][subKey]) {
          if (objkey === "_id") continue;
          formData.append(
            `${key}[${subKey}][${objkey}]`,
            data[key][subKey][objkey]
          );
        }
      }
      continue;
    }

    if (key === "languages") {
      for (let subKey in data[key]) {
        if (subKey === "_id") continue;
        formData.append(`${key}[${subKey}]`, data[key][subKey]);
      }
      continue;
    }

    formData.append(key, data[key]);
  }
  return formData;
};

const printFormData = (formData) => {
  for (var pair of formData.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }
};
