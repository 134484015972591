import React, { useState, useContext } from "react";
import { Grid, Box, Typography, useTheme } from "@mui/material";
import {
  RowBox,
  SearchBar,
  InputSelect,
} from "../components/ui";
import { PostModal } from "../components/app-ui";
import { Outlet, useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

import { Container, ProgressIndicator, ColBox } from "../components/ui";
import { ActivityTypes, DurationOptions } from "../config";
import { ActivityTable } from "../components/tables";
import { last6Months, lastMonth, lastWeek, lastYear } from "../utils/utils";
import { useAlert } from "../components/alert";

export default function Activity() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [q, setQuery] = useState();
  const [type, setType] = useState();
  const [duration, setDuration] = useState();
  const [post, setPost] = useState();
  const [shouldShowPost, showPost] = useState(false)
  const alert = useAlert()

  const queryClient = useQueryClient();
  const { fetchActivity, deletePost } = useContext(APIContext);

  const fromDate = {
    "1week": lastWeek(),
    "1month": lastMonth(),
    "6month": last6Months(),
    "1year": lastYear(),
  };

  const { isLoading, data } = useQuery(
    ["activity", { page, type, duration, q }],
    () => fetchActivity(page, type, fromDate[duration], q),
    {
      select: (data) => data?.data,
    }
  );

  const deletePostMutation = useMutation((postId) => deletePost(postId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("posts");
      toast.success("Successfully deleted post");
    },
  });


  const deletePostHandler = (post) => {
    alert("Confirmation", "Are you sure to delete this post?", () => {
      deletePostMutation.mutate(post?.id)
    })
  };

  const viewHandler = (activity) => {
    if (activity?.type === "comment") {
      setPost(activity?.post?.post);
      showPost(true);
    } else if (
      ["textPost", "imagePost", "videoPost"].includes(activity?.type)
    ) {
      setPost(activity?.post);
      showPost(true);
    } else if (["joinGroup", "leaveGroup"].includes(activity?.type)) {
      navigate(`/group-detail/${activity.group?.id}`);
    }
  };

  const clearFilterHandler = () => {
    setType(null);
    setDuration(null);
  };

  return (
    <Container sx={{ mt: 1.25, p: 2 }}>
      <RowBox>
        <ColBox>
          <Typography variant="h2" sx={{ mb: 1 }}>
            Activity
          </Typography>

          <RowBox sx={{ mb: 1 }}>
            <Typography variant="h4Black" sx={{ mr: 4 }}>{`Total Activity: ${
              data?.totalResults || 0
            }`}</Typography>
            <Typography>Filter By:</Typography>
            <InputSelect
              options={ActivityTypes}
              placeholder="Type"
              value={type}
              onChange={setType}
              fullWidth={false}
              sx={{ mx: 1, minWidth: 100 }}
            />
            <InputSelect
              options={DurationOptions}
              placeholder="Duration"
              value={duration}
              onChange={setDuration}
              fullWidth={false}
              sx={{ mx: 1, minWidth: 100 }}
            />
            <Typography
              sx={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={clearFilterHandler}
            >
              Clear Filters
            </Typography>
          </RowBox>
        </ColBox>
        <SearchBar value={q} onChange={setQuery} sx={{ ml: "auto" }} />
      </RowBox>
      <ActivityTable
        data={data}
        onView={viewHandler}
        onDelete={(activity) => deletePostHandler(activity?.post)}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      <PostModal
        show={shouldShowPost}
        onClose={() => showPost(false)}
        post={post}
        showDelete
        onDelete={deletePostHandler}
      />
      {isLoading && deletePostMutation.isLoading && <ProgressIndicator />}
    </Container>
  );
}
