import React, { useContext, useState } from "react";
import { Container, ProgressIndicator } from "../../components/ui";
import { Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { CustomerTable } from "../../components/tables";
import { CustomerContext } from "../../services/context";

export default function CustomerList(props) {
  const [page, setPage] = useState(0);
  const [q, country, state] = useContext(CustomerContext);
  const navigate = useNavigate();

  console.log("Context ", useContext(CustomerContext))

  const queryClient = useQueryClient();
  const { fetchCustomers } = useContext(APIContext);
  const { isLoading, data: customers } = useQuery(
    ["customers", { page, q, country, state }],
    () => fetchCustomers({ page, q, country, state }),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

    const viewHandler = (user) => {
      navigate(`/ecommerce/customer-detail/${user?.id}`, { state: user });
    };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Customers: ${
        customers?.totalResults || 0
      }`}</Typography>
      <CustomerTable
        data={customers}
        onView={viewHandler}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}
