import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router";

import { Box, Button, Typography } from "@mui/material";

import {
  RowBox,
  ColBox,
  SearchBar,
  SplitTabs,
  Container,
  InputSelect,
  CountryPick,
} from "../../components/ui";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { OrderContext } from "../../services/context";

export default function Orders() {
  const [q, setQuery] = useState();

  const { fetchOrders } = useContext(APIContext);

  const { data: allOrders } = useQuery(["orders"], fetchOrders);

  const { data: pending } = useQuery(
    ["products", { status: "pending" }],
    () => fetchOrders({ status: "pending" })
  );

  const { data: completed } = useQuery(
    ["products", { status: "completed" }],
    () => fetchOrders({ status: "completed" })
  );

  const { data: cancelled } = useQuery(
    ["products", { status: "cancelled" }],
    () => fetchOrders({ status: "cancelled" })
  );

  const { data: failed } = useQuery(
    ["products", { status: "failed" }],
    () => fetchOrders({ status: "failed" })
  );

  const menu = [
    {
      name: `All Orders (${allOrders?.data?.totalResults || 0})`,
      link: "all",
    },
    {
      name: `Under Process (${pending?.data?.totalResults || 0})`,
      link: "pending",
    },
    {
      name: `Completed (${completed?.data?.totalResults || 0})`,
      link: "completed",
    },
    {
      name: `Cancelled/Refunded (${cancelled?.data?.totalResults || 0})`,
      link: "cancelled",
    },
    {
      name: `Failed (${failed?.data?.totalResults || 0})`,
      link: "failed",
    },
  ];

  return (
    <Container sx={{ mt: 1.25 }}>
      <RowBox
        sx={{
          pr: 2,
          pb: 2,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
        fullWidth
      >
        <SplitTabs
          menu={menu}
          hsx={{
            width: "auto",
            borderBottom: "none",
          }}
        />
        <SearchBar
          placeholder="Search Order Id"
          sx={{ ml: "auto" }}
          value={q}
          onChange={setQuery}
        />
      </RowBox>

      <OrderContext.Provider
        value={{
          q,
        }}
      >
        <Outlet />
      </OrderContext.Provider>
    </Container>
  );
}
