import React from "react";
import {
  styled,
  Button,
  Typography,
  Switch,
  Box,
  IconButton,
  Rating,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { RowBox, ColBox, ImageBox } from "./ui";
import {
  PostItem,
  ProfileImage,
  UserProfile,
  GroupProfile,
  MessageDateHeader,
  Message,
} from "./app-ui";
import { ProductImage, ProductProfile, ServiceImage } from "./ecommerce-ui";
import {
  ActivityText,
  OrderStatus,
  ReviewStatus,
  SellerStatus,
  SellerType,
  serviceStatus,
} from "../config";
import {
  dateTimeString,
  timeAgo,
  sortModelToString,
  dateString,
  collateMessagesByDate,
  chatHeaderFormattedDate,
} from "../utils/utils";
import { useTheme } from "@emotion/react";
import { reportCategories } from "../config";
import { TypeSpecimenTwoTone } from "@mui/icons-material";

export const StyledGrid = (props) => {
  const theme = useTheme();
  const { csx, sx, ...params } = props;
  return (
    <Box sx={{ flexGrow: 1, width: "100%", ...csx }}>
      <DataGrid
        disableColumnFilter
        disableSelectionOnClick
        hideable={false}
        sx={{
          color: "black",
          fontSize: 13,
          wdith: "100%",
          "& .MuiDataGrid-row:hover": {
            backgroundColor: params?.onRowClick ? "none" : "white",
          },
          "& .MuiDataGrid-columnHeader": {
            color: "black",
            fontSize: 14,
          },
          "& .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within": {
            outline: 0,
          },
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal",
            py: 2,
          },
          ...sx,
        }}
        {...params}
      />
    </Box>
  );
};

export const PostTable = (props) => {
  const StyledButton = styled(Button)(() => ({
    height: 24,
    marginRight: 6,
  }));

  const rows = props?.posts || [];
  const cols = [
    {
      field: "postedBy",
      headerName: "Admin posts",
      sortable: false,
      disableColumnMenu: true,
      disableSelectionOnClick: true,
      width: 200,
      valueGetter: ({ row: post }) =>
        post.group
          ? `Posted to Group "${post.group?.name}"`
          : 'Posted to "Timeline"',
    },
    {
      field: "notes",
      headerName: "Notes",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
    },
    {
      field: "postedDate",
      headerName: "Posted on",
      width: 200,
      valueFormatter: (param) => dateTimeString(param?.value),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: props?.trash ? 100 : 300,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          <StyledButton
            variant="outlined"
            onClick={() => props?.onViewPost(row)}
          >
            View Post
          </StyledButton>
          {!props?.trash && (
            <>
              <StyledButton
                variant="outlined"
                onClick={() => props?.onEditPost(row)}
              >
                Edit
              </StyledButton>
              <StyledButton
                variant="outlined"
                onClick={() => props?.onDeletePost(row)}
              >
                Move to Trash
              </StyledButton>
            </>
          )}
        </RowBox>
      ),
    },
  ];

  if (props?.trash) {
    cols.splice(3, 0, {
      field: "deletedDate",
      headerName: "Moved to Trash on",
      valueFormatter: (param) => dateTimeString(param?.value),
      width: 200,
    });
  }

  return <StyledGrid rows={rows} columns={cols} />;
};

export const CommentTable = (props) => {
  const { data, page, type } = props;

  const StyledButton = styled(Button)(() => ({
    height: 24,
    marginRight: 6,
  }));

  const rows = data?.results || [];
  const cols = [
    {
      field: "comments",
      headerName: "Comments",
      sortable: false,
      disableColumnMenu: true,
      disableSelectionOnClick: true,
      width: 400,
      renderCell: ({ row }) => <PostItem post={row} sx={{ my: 1 }} />,
    },
    {
      field: "createdAt",
      headerName: "Posted",
      width: 150,
      valueFormatter: (params) => timeAgo(params?.value),
    },
    {
      field: "responseTo",
      headerName: "Response To",
      width: 200,
      renderCell: ({ row: comment }) => {
        const author = comment?.parent?.author;
        return (
          <RowBox>
            <ProfileImage image={author?.profileUrl} />
            <Typography variant="body1">
              {`${author?.firstname} ${author?.lastname} post`}{" "}
            </Typography>
          </RowBox>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          <StyledButton variant="outlined" onClick={() => props?.onView(row)}>
            View Post
          </StyledButton>
          <StyledButton variant="outlined" onClick={() => props?.onDelete(row)}>
            Delete
          </StyledButton>
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
    />
  );
};

export const MediaTable = (props) => {
  const { data, page, type } = props;

  const StyledImage = styled("img")(({ theme }) => ({
    height: 150,
    width: 150,
    objectFit: "cover",
    cursor: "pointer",
    marginTop: 10,
    marginBottom: 10,
  }));

  const PlayButton = styled(IconButton)(() => ({
    zIndex: 2,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  }));

  const rows = data?.results || [];
  const cols = [
    {
      field: "photo",
      headerName: "Photo/Video",
      sortable: false,
      disableColumnMenu: true,
      disableSelectionOnClick: true,
      width: 200,
      renderCell: ({ row }) => (
        <ColBox sx={{ position: "relative", alignItems: "center" }}>
          <StyledImage
            src={row?.mediaType === "video" ? row?.thumbnail : row?.url}
            onClick={() => {
              props?.onView(row);
            }}
          />
          {row?.mediaType === "video" && (
            <PlayButton onClick={() => props?.onView(row)}>
              <ImageBox src={require("../assets/icons/video-play.png")} />
            </PlayButton>
          )}
        </ColBox>
      ),
    },
    {
      field: "postedBy",
      headerName: "Posted By",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => <UserProfile user={row?.author} />,
    },
    {
      field: "createdAt",
      headerName: "Posted",
      width: 200,
      valueFormatter: (param) => timeAgo(param?.value),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          <Button
            variant="outlined"
            sx={{ height: 24, mr: 1 }}
            onClick={() => props?.onViewPost(row)}
          >
            View Post
          </Button>
          <Button
            variant="outlined"
            sx={{ height: 24 }}
            onClick={() => props?.onDelete(row)}
          >
            Delete
          </Button>
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
    />
  );
};

export const GroupTable = (props) => {
  const { data, page, type } = props;

  const rows = data?.results || [];
  const cols = [
    {
      field: "group",
      headerName: "Group",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => <GroupProfile group={row} />,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      valueGetter: ({ row }) => (row?.isPrivate ? "Private" : "Public"),
    },
    {
      field: "memberCount",
      headerName: "Total Members",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "postCount",
      headerName: "Total Posts",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          <Button
            variant="outlined"
            sx={{ height: 24, mr: 1 }}
            onClick={() => props?.onView(row)}
          >
            Edit
          </Button>
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      rowHeight={80}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      onRowClick={(row) => props?.onView(row)}
    />
  );
};

export const GroupPostsTable = (props) => {
  const { data, page, type } = props;

  const rows = data?.results || [];
  const cols = [
    {
      field: "posts",
      headerName: "All Posts",
      sortable: false,
      disableColumnMenu: true,
      width: 400,
      renderCell: ({ row }) => (
        <PostItem
          post={row}
          sx={{ my: 1 }}
          onCommentClick={() => props?.onCommentClick(row)}
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Posted",
      width: 200,
      valueFormatter: (params) => timeAgo(params?.value),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Button
          variant="outlined"
          sx={{ height: 24 }}
          onClick={() => props?.onDelete(row)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
    />
  );
};

export const GroupMemberTable = (props) => {
  const { data, page, type } = props;

  const rows = data?.results || [];
  const cols = [
    {
      field: "member",
      headerName: "User",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => (
        <UserProfile user={row?.member} sx={{ my: 1 }} />
      ),
    },
    {
      field: "dateOfJoin",
      headerName: "Join Date",
      width: 200,
      valueFormatter: (params) => dateString(params?.value),
    },
    {
      field: "postCount",
      headerName: "Total Posts",
      width: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          <Button
            variant="outlined"
            sx={{ height: 24, mr: 1, color: "red" }}
            onClick={() => props?.onRemove(row)}
          >
            Remove User
          </Button>
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
    />
  );
};

export const UsersTable = (props) => {
  const { data, page } = props;

  const rows = data?.results || [];
  const cols = [
    {
      field: "user",
      headerName: "User",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => <UserProfile user={row} sx={{ my: 1 }} />,
    },
    {
      field: "profession",
      headerName: "Profession",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
    },
    {
      field: "lastSeen",
      headerName: "Last Seen",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Switch
          checked={row?.isSuspended}
          onClick={(e) => {
            e.stopPropagation();
            props?.onSuspend(row);
          }}
        ></Switch>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      onRowClick={({ row }) => props?.onSelect(row)}
    />
  );
};

export const ReportPostTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "reportBy",
      headerName: "Reported by",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => (
        <UserProfile user={row?.reportBy} sx={{ my: 1 }} />
      ),
    },
    {
      field: "post",
      headerName: "Against Post/Comment",
      sortable: false,
      disableColumnMenu: true,
      width: 400,
      renderCell: ({ row }) => (
        <PostItem
          post={row?.post}
          sx={{ my: 1, backgroundColor: "transparent" }}
        />
      ),
    },
    {
      field: "category",
      headerName: "Category",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      valueFormatter: (params) =>
        reportCategories.find((r) => r.value === params?.value)?.name,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 300,
    },
    {
      field: "createdAt",
      headerName: "Date",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      valueFormatter: (param) => dateTimeString(param?.value),
    },
    {
      field: "responseTo",
      headerName: "Response to",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => (
        <RowBox>
          <ProfileImage image={row?.reportAgainst?.profileUrl} />
          <Typography variant="h5">{`${row?.reportAgainst?.firstname} ${row?.reportAgainst?.lastname}`}</Typography>
          <Typography variant="h5" sx={{ ml: 0.5 }}>
            {row?.post?.commentLevel > 0 ? "Comment" : "Post"}
          </Typography>
        </RowBox>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          <Action onClick={() => props?.onView(row)}>View Activity</Action>
          <Action onClick={() => props?.onAllow(row)}>Allow</Action>
          <Action onClick={() => props?.onDelete(row)}>Delete</Action>
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
    />
  );
};

export const ReportUserTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "reportBy",
      headerName: "Reported by",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => (
        <UserProfile user={row?.reportBy} sx={{ my: 1 }} />
      ),
    },
    {
      field: "category",
      headerName: "Category",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      valueFormatter: (params) =>
        reportCategories.find((r) => r.value === params?.value)?.name,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 300,
    },
    {
      field: "createdAt",
      headerName: "Date",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      valueFormatter: (param) => dateTimeString(param?.value),
    },
    {
      field: "reportAgainst",
      headerName: "Report Against",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => (
        <UserProfile user={row?.reportAgainst} sx={{ my: 1 }} />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          <Action onClick={() => props?.onView(row?.reportAgainst)}>
            View User
          </Action>
          <Action onClick={() => props?.onAllow(row)}>Allow</Action>
          {/* <Action onClick={() => props?.onDelete(row)}>Delete</Action> */}
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
    />
  );
};

export const BlockedUserTable = (props) => {
  const { data, page } = props;

  const rows = data?.results || [];
  const cols = [
    {
      field: "user",
      headerName: "Blocked User",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => <UserProfile user={row} sx={{ my: 1 }} />,
    },
    {
      field: "blockCount",
      headerName: "Blocks",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
    },
    {
      field: "reportCount",
      headerName: "Reports",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Switch
          checked={row?.isSuspended}
          onClick={(e) => {
            e.stopPropagation();
            props?.onSuspend(row, !row?.isSuspended);
          }}
        ></Switch>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      onRowClick={({ row }) => props?.onSelect(row)}
    />
  );
};

export const ActivityTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "userActivity",
      headerName: "User Activity",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => (
        <RowBox sx={{ my: 2 }}>
          <ProfileImage image={row?.user?.profileUrl} />
          <Typography>{row?.user?.fullname}</Typography>
          <Typography sx={{ ml: 0.5 }}>{ActivityText[row?.type]}</Typography>
        </RowBox>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      valueFormatter: (param) => dateTimeString(param?.value),
    },
    {
      field: "reference",
      headerName: "Reference To",
      sortable: false,
      disableColumnMenu: true,
      width: 300,
      renderCell: ({ row }) => {
        if (row?.type === "comment") {
          return (
            <RowBox sx={{ my: 2 }}>
              <ProfileImage image={row?.post?.parent?.author?.profileUrl} />
              <Typography>{row?.post?.parent?.author?.fullname}</Typography>
              <Typography sx={{ ml: 0.5 }}>Post</Typography>
            </RowBox>
          );
        }
        if (row?.type === "joinGroup" || row?.type === "leaveGroup") {
          return (
            <RowBox sx={{ my: 2 }}>
              <ProfileImage image={row?.group.profileUrl} />
              <Typography>{row?.group?.name}</Typography>
            </RowBox>
          );
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          <Action onClick={() => props?.onView(row)}>View Activity</Action>
          {["imagePost", "textPost", "videoPost", "comment"].includes(
            row?.type
          ) && <Action onClick={() => props?.onDelete(row)}>Delete</Action>}
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
    />
  );
};

export const ConversationsTable = (props) => {
  const { data, page } = props;

  const rows = data?.results || [];
  const cols = [
    {
      field: "lastMessageTime",
      headerName: "Last Chat",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.2,
      valueFormatter: (param) => dateTimeString(param?.value),
    },
    {
      field: "sender",
      headerName: "From",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.4,
      renderCell: ({ row }) => <UserProfile user={row?.sender} />,
    },
    {
      field: "receiver",
      headerName: "To",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.4,
      renderCell: ({ row }) => <UserProfile user={row?.receiver} />,
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      csx={{ height: "100%", width: "40%" }}
      onRowClick={({ row }) => props?.onSelect(row)}
      disableSelectionOnClick={false}
    />
  );
};

export const MessageTable = (props) => {
  const { data, page, conversation } = props;

  const rows = data?.results || [];
  const cols = [
    {
      renderCell: ({ row }) =>
        row?.sectionDate ? (
          <MessageDateHeader date={chatHeaderFormattedDate(row?.sectionDate)} />
        ) : (
          <Message conversation={conversation} message={row} />
        ),
      flex: 1,
    },
  ];

  return (
    <StyledGrid
      rows={collateMessagesByDate(rows)}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      csx={{ height: "100%", width: "60%" }}
      sx={{
        "& .MuiDataGrid-columnHeaders": {
          display: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          marginTop: "0 !important",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
      }}
      
    />
  );
};

export const ServicesTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "service",
      headerName: "Service",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: ({ row }) => (
        <RowBox>
          <ProductImage service={row} />
          <Typography sx={{ ml: 0.8 }}>{row?.title}</Typography>
        </RowBox>
      ),
    },
    {
      field: "seller",
      headerName: "Seller",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: ({ row }) => <UserProfile user={row?.seller} />,
    },
    {
      field: "category",
      headerName: "Category",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }) => (
        <ColBox center fullWidth>
          <Typography>{row?.mainCategoryName}</Typography>
          {row?.categoryName && <Typography>{row?.categoryName}</Typography>}
          {row?.subCategoryName && (
            <Typography>{row?.subCategoryName}</Typography>
          )}
        </ColBox>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }) => (
        <ColBox center fullWidth>
          <Typography>{serviceStatus[row?.status]} </Typography>
          {row?.statusDate && (
            <Typography>{dateString(row?.statusDate)}</Typography>
          )}
        </ColBox>
      ),
    },
    {
      field: "prices",
      headerName: "Price",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }) => (
        <Typography>{`$${row?.minPrice} - $${row?.maxPrice}`}</Typography>
      ),
    },
    {
      field: "isEnabled",
      headerName: "Availability",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      valueFormatter: ({ value }) => (value ? "Available" : "Unavailable"),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          {row?.status !== "pending" ? (
            <Action onClick={() => props?.onReview(row)}>View/Edit</Action>
          ) : (
            <Action onClick={() => props?.onReview(row)}>Review</Action>
          )}
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
    />
  );
};

export const SellerServicesTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "service",
      headerName: "Service",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: ({ row }) => (
        <RowBox>
          <ProductImage service={row} />
          <Typography sx={{ ml: 0.8 }}>{row?.title}</Typography>
        </RowBox>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }) => (
        <ColBox center>
          <Typography>{row?.mainCategoryName}</Typography>
          {row?.categoryName && <Typography>{row?.categoryName}</Typography>}
          {row?.subCategoryName && (
            <Typography>{row?.subCategoryName}</Typography>
          )}
        </ColBox>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }) => (
        <RowBox>
          <Typography>{serviceStatus[row?.status]} </Typography>
          {row?.serviceDate && (
            <Typography>{dateString(row?.serviceDate)}</Typography>
          )}
        </RowBox>
      ),
    },
    {
      field: "prices",
      headerName: "Price",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: ({ row }) => (
        <Typography>{`$${row?.minPrice} - $${row?.maxPrice}`}</Typography>
      ),
    },
    {
      field: "isEnabled",
      headerName: "Availability",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      valueGetter: ({ value }) => (value ? "Available" : "Unavailable"),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          {row?.status !== "pending" ? (
            <Action onClick={() => props?.onReview(row)}>View/Edit</Action>
          ) : (
            <Action onClick={() => props?.onReview(row)}>Review</Action>
          )}
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
    />
  );
};

export const OrdersTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "orderId",
      headerName: "Order Number",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
    },
    {
      field: "service",
      headerName: "Service",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: ({ row }) => {
        const product = row?.items?.[0]?.product;
        return (
          <ColBox>
            <Typography>{product?.title}</Typography>
            <Typography
              sx={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => props?.onSellerClick(product?.seller)}
            >
              {product?.seller?.fullname}
            </Typography>
          </ColBox>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      valueGetter: ({ row }) => row?.items?.[0]?.quantity,
    },
    {
      field: "orderDate",
      headerName: "Date",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      valueFormatter: ({ value }) => dateTimeString(value),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      valueGetter: ({ row }) => OrderStatus[row?.items?.[0]?.status],
    },
    {
      field: "totalPrice",
      headerName: "Price",
      sortable: false,
      disableColumnMenu: true,
      width: 70,
      valueFormatter: ({ value }) => `$${value || 0}`,
    },
    {
      field: "customer",
      headerName: "Customer",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: ({ row }) => <UserProfile user={row?.buyer} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Action onClick={() => props?.onView(row)}>View Details</Action>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      onRowClick={props?.onView}
    />
  );
};

export const SellerTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "seller",
      headerName: "Seller",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: ({ row }) => <UserProfile user={row} />,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      disableColumnMenu: true,
      width: 100,
      valueFormatter: ({ value }) => SellerType[value],
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      width: 140,
      renderCell: ({ row }) => (
        <ColBox>
          <Typography>{SellerStatus[row?.status]}</Typography>
          {row?.statusDate && (
            <Typography>{dateString(row?.statusDate)}</Typography>
          )}
        </ColBox>
      ),
    },
    {
      field: "productCount",
      headerName: "Total Services",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      valueGetter: ({ row }) => row?.user?.productCount,
    },
    {
      field: "completedOrders",
      headerName: "Total Completed Orders",
      sortable: false,
      disableColumnMenu: true,
      width: 180,
      valueGetter: ({ row }) => row?.user?.completeOrderCount,
    },
    {
      field: "totalEarnings",
      headerName: "Total Earnings",
      sortable: false,
      disableColumnMenu: true,
      width: 120,
      valueGetter: ({ row }) => row?.user?.totalEarnings,
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      //sortingMode="server"
      // onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      onRowClick={({ row }) => props?.onView(row)}
    />
  );
};

export const CustomerTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "customer",
      headerName: "Customer",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      renderCell: ({ row }) => <UserProfile user={row} />,
    },
    {
      field: "email",
      headerName: "Email id",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
    },
    {
      field: "orderCount",
      headerName: "Total orders placed",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      valueGetter: ({ row }) => row?.buyer?.orderCount,
    },
    {
      field: "orderValue",
      headerName: "Total value of Orders placed",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      valueGetter: ({ row }) => row?.buyer?.orderValue,
      valueFormatter: ({ value }) => `$ ${value}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Action onClick={() => props?.onView(row)}>View all Orders</Action>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      //sortingMode="server"
      // onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      onRowClick={({ row }) => props?.onView(row)}
    />
  );
};

export const CustomerOrdersTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "orders",
      headerName: "Orders",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        const product = row?.items?.[0]?.product;
        return (
          <ColBox>
            <Typography>{product?.title}</Typography>
            <Typography sx={{ cursor: "pointer", textDecoration: "underline" }}>
              {product?.seller?.fullname}
            </Typography>
          </ColBox>
        );
      },
    },
    {
      field: "orderDate",
      headerName: "Date",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: ({ value }) => dateTimeString(value),
    },
    {
      field: "totalPrice",
      headerName: "Price",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: ({ value }) => `$${value || 0}`,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      valueGetter: ({ row }) => row?.items?.[0]?.status,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Action onClick={() => props?.onView(row)}>View Details</Action>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      onRowClick={props?.onView}
    />
  );
};

export const ReviewTable = (props) => {
  const { data, page } = props;

  const Action = (props) => (
    <Button variant="outlined" sx={{ height: 24, mr: 1 }} {...props}>
      {props?.children}
    </Button>
  );

  const rows = data?.results || [];
  const cols = [
    {
      field: "customer",
      headerName: "Customer",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return <UserProfile user={row?.buyer} />;
      },
    },
    {
      field: "service",
      headerName: "Service",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => {
        return <ProductProfile product={row?.product} />;
      },
    },
    {
      field: "rating",
      headerName: "Star Rating",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ row }) => <Rating value={row?.rating} readOnly />,
    },
    {
      field: "comment",
      headerName: "Review",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Date",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: ({ value }) => dateTimeString(value),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: ({ value }) => ReviewStatus[value],
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <RowBox>
          {row?.status !== "pending" ? (
            <Action onClick={() => props?.onReview(row)}>View/Edit</Action>
          ) : (
            <Action onClick={() => props?.onReview(row)}>Review</Action>
          )}
        </RowBox>
      ),
    },
  ];

  return (
    <StyledGrid
      rows={rows}
      columns={cols}
      getRowHeight={() => "auto"}
      rowCount={data?.totalResults || 0}
      pagination
      paginationMode="server"
      page={page}
      pageSize={data?.limit || 10}
      onPageChange={props?.onPageChange}
      sortingMode="server"
      onSortModelChange={(m) => props?.onSort(sortModelToString(m))}
      onRowClick={props?.onView}
    />
  );
};
