export const reportCategories = [
  { name: "Nudity", value: "nudity" },
  { name: "Violence", value: "violence" },
  { name: "Harassment", value: "harassement" },
  { name: "Unauthorized Sales", value: "unauthorized" },
  { name: "Terrorism", value: "terrorism" },
];

export const ActivityTypes = [
  { name: "Text Post", value: "textPost" },
  { name: "Image Post", value: "imagePost" },
  { name: "Video Post", value: "videoPost" },
  { name: "Comment", value: "comment" },
  { name: "Profile Edit", value: "editProfile" },
  { name: "Group Joins", value: "joinGroup" },
  { name: "Group Leave", value: "leaveGroup" },
];

export const ActivityText = {
  textPost: 'posted a "text post"',
  imagePost: 'posted a "image"',
  videoPost: 'posted a "video"',
  comment: "commented on a post",
  editProfile: "updated their profile",
  joinGroup: "joined a group",
  leaveGroup: "left a group",
};

export const DurationOptions = [
  { name: "1 Week", value: "1week" },
  { name: "1 Month", value: "1month" },
  { name: "6 Months", value: "6month" },
  { name: "1 Year", value: "1year" },
  { name: "All time", value: "all" },
];

export const serviceStatus = {
  pending: "Pending Approval",
  published: "Published",
  rejected: "Rejected",
  suspended: "Suspended",
};

export const OrderStatusOptions = [
  { value: "completed", name: "Completed" },
  { value: "pending", name: "Under Process" },
  { value: "cancelled", name: "Cancelled" },
];

export const OrderStatus = {
  pending: "Under Process",
  completed: "Completed",
  cancelled: "Cancelled/Refunded",
  failed: "Failed",
};

export const SellerTypeOptions = [
  { value: "individual", name: "Individual" },
  { value: "business", name: "Business" },
  { value: "teacher", name: "Preceptor" },
];

export const SellerType = {
  individual: "Individual",
  business: "Business",
  teacher: "Preceptor",
};

export const SellerStatus = {
  pending: "Pending Approval",
  approved: "Approved",
  rejected: "Rejected",
  suspended: "Suspended",
};

export const ReviewStatus = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
};

export const settingsMenu = [
  { name: "Category Settings", link: "/settings/category" },
  { name: "Tax Settings", link: "/settings/tax" },
  { name: "Paswword Settings", link: "/settings/password" },
  { name: "User Role and Permissions", link: "/settings/role" },
  { name: "Website Settings", link: "/settings/site" },
];

export const deliveryTimes = ["1-2", "2-3", "3-4", "4-5"]
export const rushDeliveryTimes = ["1-2", "2-3", "3-4", "4-5"]
export const revisions = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
export const affliationTypes = ["Type1", "Type2", "Type3"]
export const exposureTypes = ["Exposure1", "Exposure2", "Exposure3"]
export const letterTypes = ["Type1", "Type2", "Type3"]
export const studentStatus = ["Status1", "Status2", "Status3"]