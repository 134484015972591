import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  useTheme,
  Typography,
  TextField,
} from "@mui/material";
import { useLocation, useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { toast } from "react-toastify";

import { ProfileImage } from "../../components/app-ui";

import {
  PageTitle,
  RowBox,
  ColBox,
  ProgressIndicator,
  FileButton,
} from "../../components/ui";

import {
  CatSelect,
  MainCatSelect,
  SubCatSelect,
} from "../../components/ecommerce-ui";

import {
  getNode,
} from "../../utils/utils";
import { useCategory } from "../../components/hooks";
import {useCrop} from '../../components/alert'

export default function CategoryInfo(props) {
  const theme = useTheme();

  const [image, setImage] = useState();
  const [title, setTitle] = useState();

  const [mainCategory, setMainCategory] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();

  const { updateCategory } = useContext(APIContext);
  const queryClient = useQueryClient()
  const cropImage = useCrop()

  const node = subCategory || category || mainCategory

  const updateMutation = useMutation(
    (data) => updateCategory(node?.id, data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["category"]);
        toast.success("Successfully updated Category");
      },
    }
  );

  useEffect(() => {
    if (node) {
      setTitle(node?.displayName);
    }
  }, [node]);

  const imageSelectHandler = (image) => {
    cropImage(image, (cropped) => {
      setImage(cropped)
    })
  }

  const submitHandler = () => {
    if (!image && title === node?.displayName) {
        toast.error("No changes to save")
        return
    }
    updateMutation.mutate({
        photo: image,
        displayName: title
    })
  }

  return (
    <Box sx={{ mt: 1.25, backgroundColor: "white" }}>
      <PageTitle title="Edit Categories" back />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid
          item
          xs={2}
          sx={{
            borderRight: `1px solid ${theme?.palette?.info?.light}`,
            display: "flex",
            flex: 1,
          }}
        ></Grid>
        <Grid item xs={10}>
          <RowBox sx={{ py: 3, px: 3, boxSizing: "border-box" }} fullWidth>
            <MainCatSelect
              value={mainCategory}
              onChange={setMainCategory}
              selectObject
              title="Main Category"
              wsx={{ flex: 1, maxWidth: 300 }}
            />
            <CatSelect
              value={category}
              onChange={setCategory}
              mainCategory={mainCategory?.id}
              selectObject
              title="Category"
              wsx={{ flex: 1, ml: 2, maxWidth: 300 }}
            />
            <SubCatSelect
              value={subCategory}
              onChange={setSubCategory}
              mainCategory={mainCategory?.id}
              category={category?.id}
              title="Sub Category"
              selectObject
              wsx={{ flex: 1, ml: 2, maxWidth: 300 }}
            />
          </RowBox>
          {mainCategory && (
            <RowBox sx={{ px: 3, py: 3 }}>
              <ColBox center>
                <ProfileImage image={image || node?.image} variant="banner" />
                <FileButton onSelect={imageSelectHandler}>
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: "underLine", cursor: "pointer" }}
                  >
                    Change Image
                  </Typography>
                </FileButton>
              </ColBox>
              <ColBox sx={{ ml: 2 }}>
                <Typography variant="h3" sx={{ mb: 1 }}>
                  Category Name
                </Typography>
                <TextField
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  sx={{ minWidth: 300 }}
                />
              </ColBox>
            </RowBox>
          )}
          <RowBox fullWidth center sx={{mb: 4}}>
            <Button variant="contained" onClick={submitHandler}>Save Changes</Button>
          </RowBox>
        </Grid>
      </Grid>
      {updateMutation.isLoading && <ProgressIndicator />}
    </Box>
  );
}
