import React, { useContext, useState } from "react";
import { Container, ProgressIndicator } from "../../components/ui";
import { Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router";

//API related imports
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { ReviewTable, SellerTable } from "../../components/tables";
import { ReviewContext } from "../../services/context";

export default function ReviewList(props) {
  const [page, setPage] = useState(0);
  const { status } = props;
  const { q } = useContext(ReviewContext);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { fetchReviews } = useContext(APIContext);
  const { isLoading, data: reviews } = useQuery(
    ["reviews", { page, q, status }],
    () => fetchReviews({ page, q, status }),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  console.log(reviews)

  const viewHandler = (review) => {
    console.log("Review ", review)
    navigate(`/ecommerce/review-detail/${review?.id}`, { state: review });
  };

  return (
    <Container sx={{ p: 2 }}>
      <Typography variant="h4Black" sx={{ mb: 2 }}>{`Total Reviews: ${
        reviews?.totalResults || 0
      }`}</Typography>
      <ReviewTable
        data={reviews}
        onReview={viewHandler}
        page={page}
        onPageChange={setPage}
        //onSort={(s) => {setSort(s); setPage(0)}}
      />
      {isLoading && <ProgressIndicator />}
    </Container>
  );
}
