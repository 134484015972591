import React, { useContext, useState } from "react";
import { Outlet, useNavigate } from "react-router";

import { Box, Button, Typography } from "@mui/material";

import {
  RowBox,
  ColBox,
  SearchBar,
  SplitTabs,
  Container,
  InputSelect,
  CountryPick,
} from "../../components/ui";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../../services/api-provider";
import { OrderContext, ReviewContext } from "../../services/context";

export default function Reviews() {
  const [q, setQuery] = useState();

  const { fetchReviews } = useContext(APIContext);

  const { data: allReviews } = useQuery(["reviews"], fetchReviews);

  const { data: pending } = useQuery(["reviews", { status: "pending" }], () =>
    fetchReviews({ status: "pending" })
  );

  const { data: approved } = useQuery(["reviews", { status: "approved" }], () =>
    fetchReviews({ status: "approved" })
  );

  const menu = [
    {
      name: `All Reviews (${allReviews?.data?.totalResults || 0})`,
      link: "all",
    },
    {
      name: `Approved Reviews (${approved?.data?.totalResults || 0})`,
      link: "approved",
    },
    {
      name: `Pending Reviews (${pending?.data?.totalResults || 0})`,
      link: "pending",
    },
  ];

  return (
    <Container sx={{ mt: 1.25 }}>
      <RowBox
        sx={{
          pr: 2,
          pb: 2,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
        fullWidth
      >
        <SplitTabs
          menu={menu}
          hsx={{
            width: "auto",
            borderBottom: "none",
          }}
        />
        <SearchBar
          placeholder="Search customer"
          sx={{ ml: "auto" }}
          value={q}
          onChange={setQuery}
        />
      </RowBox>

      <ReviewContext.Provider
        value={{
          q,
        }}
      >
        <Outlet />
      </ReviewContext.Provider>
    </Container>
  );
}
