import React, { useState, useContext } from "react";
import { Box, Typography } from "@mui/material";
import {
  RowBox,
  SplitTabs,
  SearchBar,
  CountryPick,
  StatePick,
  ColBox,
  ProfessionPick,
  Container,
} from "../components/ui";

import { Outlet } from "react-router";
import { UserSearchContext } from "../services/context";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";

export default function User() {
  const [q, setQuery] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [profession, setProfession] = useState();

  const { fetchUsers } = useContext(APIContext);
  const { data: allUsers } = useQuery(
    ["users", { page: 0, type: "all" }],
    () => fetchUsers(0, "all"),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const { data: suspendedUsers } = useQuery(
    ["users", { page: 0, type: "suspended" }],
    () => fetchUsers(0, "suspended"),
    {
      keepPreviousData: true,
      select: (data) => data?.data,
    }
  );

  const menu = [
    {
      name: `All Users (${allUsers?.totalResults || 0})`,
      link: "all",
    },
    {
      name: `Suspended (${suspendedUsers?.totalResults || 0})`,
      link: "suspended",
    },
  ];

  const clearFilterHandler = () => {
    setCountry();
    setState(null);
    setProfession(null);
  };

  return (
    <Container sx={{ mt: 1.25 }}>
      <RowBox
        sx={{
          pr: 2,
          borderBottom: "1px solid lightGray",
          boxSizing: "border-box",
        }}
      >
        <SplitTabs
          menu={menu}
          hsx={{
            justifyContent: "flex-start",
            borderBottom: "none",
            width: "auto",
          }}
        />
        <Typography sx={{ ml: "auto", mr: 2 }}>Filter By:</Typography>

        <ColBox sx={{ alignSelf: "flex-start", pt: 3 }}>
          <CountryPick
            value={country}
            onChange={(c) => {
              setCountry(c);
            }}
            fullWidth={false}
            sx={{ minWidth: 150 }}
          />
          {country && (
            <StatePick
              country={country}
              value={state}
              onChange={setState}
              fullWidth={false}
              sx={{ mt: 1, minWidth: 150 }}
            />
          )}
        </ColBox>

        <ColBox center sx={{ alignSelf: "flex-start", pt: 3 }}>
          <ProfessionPick
            value={profession}
            onChange={setProfession}
            fullWidth={false}
            sx={{ mx: 2, minWidth: 150 }}
          />
          <Typography
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={clearFilterHandler}
          >
            Clear Filters
          </Typography>
        </ColBox>

        <SearchBar
          placeholder="Search Users"
          sx={{ mx: 2 }}
          value={q}
          onChange={setQuery}
        />
      </RowBox>
      <UserSearchContext.Provider
        value={[q?.length > 2 ? q : null, country, state, profession]}
      >
        <Outlet />
      </UserSearchContext.Provider>
    </Container>
  );
}
