import { Grid } from "@mui/material";
import { useLocation } from "react-router";
import { PostItem } from "../components/app-ui";
import { Container, PageTitle } from "../components/ui";

export default function PostDetail() {
  const location = useLocation();
  const post = location.state;
  return (
    <Container>
      <PageTitle title="Post" back />
      <Grid container>
        <Grid item sm={12} md={7}>
          <PostItem post={post} />
        </Grid>
      </Grid>
    </Container>
  );
}
