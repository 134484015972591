import React, { useContext } from "react";
import _, { times } from "lodash";
import { Controller, get, useFormContext } from "react-hook-form";

import { NavLink } from "react-router-dom";

import {
  Box,
  ImageListItem,
  ImageListItemBar,
  styled,
  Typography,
  TextField,
  Rating,
  Button,
  IconButton,
  Divider,
  useTheme,
  List,
  ListItem,
} from "@mui/material";

import {
  ColBox,
  RowBox,
  InputField,
  InputSelect,
  SelectController,
  Error,
  FileButton,
  FileSelect,
  ImageBox,
  Title,
  MultiLineInputControl,
  StatePicker,
  CityPicker,
} from "./ui";

import { ProfileImage, Badge } from "./app-ui";

import ClearIcon from "@mui/icons-material/Clear";

import {
  timeIntervalsInaDay,
  toUTCString,
  timeFormat,
  CattoOptions,
  applyController,
  dateString,
  dateTimeString,
} from "../utils/utils";
import { useCategory } from "./hooks";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import { useQuery } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { affliationTypes, exposureTypes, letterTypes } from "../config";

/* Components related to Category - BEGIN */

export const CategoryItem = (props) => {
  const catImage = require("../assets/ecuser.png");
  const { item } = props;

  return (
    <ImageListItem sx={{ borderRadius: 5 }}>
      <img
        src={catImage}
        loading="lazy"
        style={{ maxHeight: "100%", borderRadius: 10, cursor: "pointer" }}
        onClick={props?.onClick}
      />
      <ImageListItemBar title={item.displayName} />
    </ImageListItem>
  );
};

export const MainCatSelect = (props) => {
  const { value, onChange, selectObject, sx, wsx, ...params } = props;

  const { fetchCategory } = useContext(APIContext);
  const { data: categories } = useQuery(["categories"], () => fetchCategory(), {
    select: (data) => data?.data,
  });

  return (
    <ColBox sx={wsx}>
      <Title title={props?.title} />
      <InputSelect
        options={CattoOptions(categories, selectObject)}
        placeholder="Select Main Category"
        value={props?.value}
        onChange={props?.onChange}
        sx={sx}
        {...params}
      />
    </ColBox>
  );
};

export const MainCatSelectController = applyController(
  MainCatSelect,
  "MainCatSelectController"
);

export const CatSelect = (props) => {
  const { value, onChange, selectObject, wsx, sx, mainCategory, ...params } =
    props;

  const { fetchCategory } = useContext(APIContext);
  const { data: categories } = useQuery(
    ["categories", { id: mainCategory }],
    () => fetchCategory(mainCategory),
    {
      select: (data) => data?.data,
      enabled: !!mainCategory,
    }
  );

  return categories?.length > 0 ? (
    <ColBox sx={wsx}>
      <Title title={props?.title} />
      <InputSelect
        options={CattoOptions(categories, selectObject)}
        placeholder="Select Category"
        value={props?.value}
        onChange={props?.onChange}
        sx={sx}
        {...params}
      />
    </ColBox>
  ) : null;
};

export const CatSelectController = applyController(
  CatSelect,
  "CatSelectController"
);

export const SubCatSelect = (props) => {
  const {
    value,
    onChange,
    mainCategory,
    category,
    selectObject,
    sx,
    wsx,
    ...params
  } = props;

  const { fetchCategory } = useContext(APIContext);
  const { data: categories } = useQuery(
    ["categories", { id: category }],
    () => fetchCategory(category),
    {
      select: (data) => data?.data,
      enabled: !!category,
    }
  );

  return categories?.length > 0 ? (
    <ColBox sx={wsx}>
      <Title title={props?.title} />
      <InputSelect
        options={CattoOptions(categories, selectObject)}
        placeholder="Select Sub Category"
        value={props?.value}
        onChange={props?.onChange}
        sx={sx}
        {...params}
      />
    </ColBox>
  ) : null;
};

export const SubCatSelectController = applyController(
  SubCatSelect,
  "SubCatSelectController"
);

/* Components related to Category - END */

export const ProductItem = (props) => {
  const { product } = props;
  const Image = styled("img")(({ theme }) => ({
    width: 114,
    height: 107,
    borderRadius: 15,
  }));

  const Containter = styled("div")(({ theme }) => ({
    display: "flex",
    backgroundColor: "white",
    borderRadius: 15,
    padding: 14,
    marginBottom: 14,
    alignItems: "flex-start",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.info.light,
    },
  }));

  const Content = styled("div")(({ theme }) => ({
    marginLeft: 14,
    marginRight: "auto",
    height: 107,
    display: "flex",
    flexDirection: "column",
  }));

  const Rating = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  }));

  return (
    <Containter onClick={props?.onClick}>
      <Image src={product?.images[0]}></Image>
      <Content>
        <Typography variant="h3">{product?.title}</Typography>
        <Typography variant="h4" sx={{ mt: 0.5 }}>
          {product?.shortDesc}
        </Typography>
        <Typography
          variant="h3"
          sx={{ mt: "auto" }}
        >{`$${product.minPrice} - $${product.maxPrice}`}</Typography>
      </Content>
      <Rating>
        <Typography variant="h4Black">{`${product?.rating.toFixed(1)} (${
          product?.reviewCount
        })`}</Typography>
        <Rating value={product?.rating} readOnly />
      </Rating>
    </Containter>
  );
};

export const ProductDetail = (props) => {
  const { product } = props;
  const schema = product?.productSchema;

  const Row = (props) => (
    <RowBox sx={{ mt: 1 }}>
      <Typography variant="h4Black">{props?.name}:</Typography>
      <Typography variant="h5Gray" sx={{ ml: 1 }}>
        {props?.value}
      </Typography>
    </RowBox>
  );

  const AvailRow = ({ availability }) => (
    <RowBox sx={{ mt: 1, alignItems: "flex-start" }}>
      <Typography variant="h4Black">Availability:</Typography>
      <ColBox sx={{ ml: 1 }}>
        <Typography variant="h5Gray">{` Mon to Fri - ${timeFormat(
          availability?.weekday?.from
        )} to ${timeFormat(availability?.weekday?.to)}`}</Typography>
        <Typography variant="h5Gray">{` Mon to Fri - ${timeFormat(
          availability?.weekday?.from
        )} to ${timeFormat(availability?.weekday?.to)}`}</Typography>
      </ColBox>
    </RowBox>
  );

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      {product?.country && (
        <Row name="Location" value={`${product?.state}, ${product?.country}`} />
      )}
      {product?.languages?.length > 0 && (
        <Row name="Languages" value={product?.languages.join(", ")} />
      )}
      {schema?.availability && (
        <AvailRow availability={product?.availability} />
      )}
    </Box>
  );
};

/* Components related to Products - BEGIN */

export const ProductImage = (props) => {
  return (
    <ImageBox
      src={props?.service?.images?.[0]}
      sx={{ width: 65, height: 67, borderRadius: 2 }}
    />
  );
};

export const ProductProfile = (props) => {
  const { product } = props;
  return (
    <RowBox>
      <ImageBox
        src={product?.images?.[0]}
        sx={{ width: 65, height: 67, borderRadius: 2 }}
      />
      <ColBox sx={{ ml: 1 }}>
        <Typography>{product?.title}</Typography>
        <Typography sx={{ cursor: "pointer", textDecoration: "underline" }}>
          {`${product?.sellerInfo?.firstname} ${product?.sellerInfo?.lastname}`}
        </Typography>
      </ColBox>
    </RowBox>
  );
};

export const ProductSummaryImage = (props) => {
  const theme = useTheme();
  const s = props?.small ? 18 : 24;
  const h = props?.small ? 48 : 167;
  const r = props?.small ? 1 : 2;
  const d = -16;

  const Wrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    position: "relative",
    height: h,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: theme?.palette?.border?.main,
    borderStyle: "solid",
    borderRadius: 8,
    cursor: "pointer",
  }));

  return (
    <Wrapper sx={props?.sx}>
      {props?.url ? (
        <>
          <ImageBox
            src={props?.url}
            sx={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
              borderRadius: 1,
            }}
            onClick={() => props?.onShow(props?.url)}
          />
          <IconButton
            sx={{ position: "absolute", top: d, right: d }}
            onClick={() => props?.onRemove(props?.index)}
          >
            <ImageBox
              src={require("../assets/icons/cancel.png")}
              width={s}
              height={s}
            />
          </IconButton>
        </>
      ) : (
        <FileButton onSelect={props?.onAddImage}>
          <IconButton>
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        </FileButton>
      )}
    </Wrapper>
  );
};

export const ProductSummary = (props) => {
  const { product } = props;
  return (
    <ColBox center fullWidth sx={{ p: 1 }}>
      <ProductSummaryImage
        index={0}
        url={product?.images?.[0]}
        onAddImage={props?.onAddImage}
        onShow={props?.onShow}
        onRemove={props?.onRemove}
      />
      <RowBox sx={{ mt: 1 }}>
        {[1, 2, 3].map((i) => (
          <ProductSummaryImage
            index={i}
            url={product?.images?.[i]}
            sx={{ mr: i === 3 ? 0 : 1 }}
            small
            onAddImage={props?.onAddImage}
            onShow={props?.onShow}
            onRemove={props?.onRemove}
          />
        ))}
      </RowBox>
      <ColBox sx={{ mt: 4, mb: 2 }} fullWidth center>
        <Typography variant="h3Bold">{product?.title}</Typography>
        <Typography variant="h5Grey" sx={{ mt: 2 }}>
          {product?.mainCategoryName}
        </Typography>
        <Typography variant="h5Grey">{product?.categoryName}</Typography>
        <Typography variant="h5Grey">{product?.subCategoryName}</Typography>
      </ColBox>

      <Divider />
      <Typography sx={{ my: 1 }} variant="body1">
        Seller
      </Typography>
      <Typography variant="body0">
        {`${product?.sellerInfo?.firstname} ${product?.sellerInfo?.lastname}`}
      </Typography>

      {product?.status === "published" && (
        <Button variant="outlined" sx={{ mt: 2 }} onClick={props?.onSuspend}>
          Suspend
        </Button>
      )}

      {product?.status === "suspended" && (
        <Button variant="outlined" sx={{ mt: 2 }} onClick={props?.onPublish}>
          Unsuspend
        </Button>
      )}

      {(product?.status === "pending" ||
        product?.status === "published" ||
        product?.status === "suspended") && (
        <Button variant="outlined" sx={{ mt: 2 }} onClick={props?.onReject}>
          Move to Rejected
        </Button>
      )}

      {(product?.status === "pending" || product?.status === "rejected") && (
        <Button variant="outlined" sx={{ mt: 2 }} onClick={props?.onPublish}>
          Move to Published
        </Button>
      )}
    </ColBox>
  );
};

export const AvailabilityPicker = (props) => {
  const theme = useTheme();
  const { control, defaultValue } = useFormContext();
  const { name, title, wsx } = props;
  const timeIntervals = timeIntervalsInaDay();
  const na = { name: "Not Available", value: "na" };
  const intervals = [na, ...timeIntervals];

  const TimeSelect = (props) => {
    const { newValue, onChange } = props;
    return (
      <InputSelect
        options={intervals}
        value={newValue || timeIntervals[0]?.value}
        onChange={onChange}
        fullWidth={false}
        sx={{ width: theme.typography.pxToRem(127), textAlign: "center" }}
      />
    );
  };

  const Label = ({ title }) => (
    <Typography variant="h3" sx={{ mr: 3 }}>
      {title}
    </Typography>
  );

  const To = () => (
    <Typography variant="h3" sx={{ mx: 3.75 }}>
      to
    </Typography>
  );

  const changeHandler = (isWeekday, isFrom, v, value, onChange) => {
    if (v === "na") {
      onChange(
        _.merge({}, value, {
          [isWeekday ? "weekday" : "weekend"]: { isAvailable: false },
        })
      );
    } else {
      onChange(
        _.merge({}, value, {
          [isWeekday ? "weekday" : "weekend"]: {
            [isFrom ? "from" : "to"]: v,
            isAvailable: true,
          },
        })
      );
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <ColBox sx={wsx}>
            {title && (
              <Typography variant="h3" sx={{ mb: 1.25 }}>
                {title}
              </Typography>
            )}
            <RowBox sx={{ mb: 1.25 }}>
              <Label title="Mon-Fri" />
              <TimeSelect
                newValue={
                  value?.weekday?.isAvailable === false
                    ? "na"
                    : toUTCString(value?.weekday?.from)
                }
                onChange={(t) => changeHandler(true, true, t, value, onChange)}
              />
              {value?.weekday?.isAvailable !== false && (
                <>
                  <To />
                  <TimeSelect
                    newValue={toUTCString(value?.weekday?.to)}
                    onChange={(t) => changeHandler(true, false, t, value, onChange)}
                  />
                </>
              )}
            </RowBox>
            <RowBox>
              <Label title="Sat-Sun" />
              <TimeSelect
                newValue={
                  value?.weekend?.isAvailable === false
                    ? "na"
                    : toUTCString(value?.weekend?.from)
                }
                onChange={(t) => changeHandler(false, true, t, value, onChange)}
              />
              {value?.weekend?.isAvailable !== false && (
                <>
                  <To />
                  <TimeSelect
                    newValue={toUTCString(value?.weekend?.to)}
                    onChange={(t) => changeHandler(false, false, t, value, onChange)}
                  />
                </>
              )}
            </RowBox>
            <Error error={error} />
          </ColBox>
        );
      }}
    />
  );
};

export const DurationItem = (props) => {
  const theme = useTheme();
  const { control, defaultValue } = useFormContext();
  const { schema, name, title, wsx } = props;

  const Dollar = () => (
    <Typography variant="h3" sx={{ mr: 2.75 }}>
      $
    </Typography>
  );

  const For = () => (
    <Typography variant="h3" sx={{ mx: 3.75 }}>
      for
    </Typography>
  );

  const Label = (props) => (
    <Typography
      variant="h4"
      sx={{
        width: theme.typography.pxToRem(167),
        borderRadius: theme.typography.pxToRem(4),
        borderWidth: 1,
        borderColor: theme.palette.border.main,
        borderStyle: "solid",
        textAlign: "center",
        paddingTop: theme.typography.pxToRem(7),
        paddingBottom: theme.typography.pxToRem(7),
      }}
    >
      {props.name}
    </Typography>
  );

  return (
    <ColBox sx={wsx}>
      {title && (
        <Typography variant="h3" sx={{ mb: 1.25 }}>
          {title}
        </Typography>
      )}
      {schema.map((s, index) => (
        <Controller
          control={control}
          name={`duration.${index}`}
          defaultValue={defaultValue}
          key={index}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <ColBox sx={{ mb: 1.25 }}>
                <RowBox>
                  <Dollar />
                  <TextField
                    autoComplete="off"
                    fullWidth={false}
                    inputProps={{ style: { textAlign: "center" } }}
                    type="number"
                    sx={{ width: 184 }}
                    value={value?.amount.toString()}
                    onChange={(e) =>
                      onChange({ type: s?.type, amount: e.target.value })
                    }
                  />
                  <For />
                  <Label name={s.name} />
                </RowBox>
                <Error error={error} />
              </ColBox>
            );
          }}
        />
      ))}
    </ColBox>
  );
};

export const LanguageItem = (props) => {
  const theme = useTheme();
  return (
    <RowBox
      sx={{
        backgroundColor: theme.palette.neutral.light,
        width: "auto",
        px: 2,
        py: 1,
        borderRadius: 3,
        ...props.sx,
      }}
    >
      <Typography variant="h4" sx={{ mr: 1 }}>
        {props.name}
      </Typography>
      <ClearIcon color="info" sx={{ fontSize: 14 }} onClick={props?.onDelete} />
    </RowBox>
  );
};

export const LanguagePicker = (props) => {
  const theme = useTheme();
  const { control, defaultValue } = useFormContext();
  const { name, title, wsx } = props;

  const selectHandler = (l, prev, callback) => {
    if (!l) return; //Bug: Placeholder is called with null value
    if (!prev) {
      callback([l]);
      return;
    }
    if (prev?.indexOf(l) < 0) {
      callback([...prev, l]);
    }
  };

  const deleteHandler = (l, prev, callback) => {
    callback(prev.filter((p) => p !== l));
  };

  return (
    <Controller
      control={control}
      name="languages"
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <ColBox sx={wsx}>
            {title && (
              <Typography variant="h3" sx={{ mb: 1.25 }}>
                {title}
              </Typography>
            )}
            <ColBox sx={{ mb: 1.25 }}>
              <InputSelect
                options={languages}
                placeholder="Select Language"
                value={value?.length > 0 ? value[value.length - 1] : null}
                onChange={(l) => selectHandler(l, value, onChange)}
              />
              <Error error={error} />
            </ColBox>
            {value?.length > 0 && (
              <RowBox>
                {value.map((l) => (
                  <LanguageItem
                    name={l}
                    onDelete={() => deleteHandler(l, value, onChange)}
                    sx={{ mr: 1 }}
                  />
                ))}
              </RowBox>
            )}
          </ColBox>
        );
      }}
    />
  );
};

export const ProductFile = (props) => {
  const form = useFormContext();
  const file = form.watch("file");

  const fileViewHandler = () => {
    window.open(file?.url, "_blank");
  };

  return (
    <ColBox sx={props?.sx} fullWidth>
      <Typography variant="h3" sx={{ mb: 1 }}>
        File Upload
      </Typography>
      <Typography variant="body0" sx={{ mb: 0.8 }}>
        {file?.name}
      </Typography>
      <RowBox fullWidth>
        <Button
          variant="secondary"
          sx={{ mr: 2, flex: 1 }}
          onClick={fileViewHandler}
        >
          View File
        </Button>
        <FileSelect
          name="file"
          type={["application/pdf"]}
          wsx={{ flex: 1 }}
          sx={{ width: "100%" }}
          single
        >
          <Button variant="secondary">ReUpload File</Button>
        </FileSelect>
      </RowBox>
    </ColBox>
  );
};

export const SinglePrice = (props) => {
  return (
    <ColBox sx={props?.sx}>
      {props?.title && <Typography variant="h3">{props?.title}</Typography>}
      <RowBox sx={{ mt: 1 }}>
        <Typography sx={{ mx: 1 }}>$</Typography>
        <InputField
          name={props?.name}
          placeholder="Select Price"
          wsx={{ width: 150 }}
        />
      </RowBox>
    </ColBox>
  );
};

export const PackageItem = (props) => {
  const { schema, name } = props;
  const form = useFormContext();

  form.register(`${name}.type`);
  form.setValue(`${name}.type`, schema?.type);
  const error = get(form.formState?.errors, name)

  return (
    <ColBox sx={props?.sx}>
      <Typography>{schema?.name}</Typography>
      <InputField name={`${name}.title`} title="Title" />
      <MultiLineInputControl
        title="Description"
        subtitle="(Introduce briefly about yourself and your offering)"
        name={`${name}.desc`}
        placeholder="Briefly describe the service offered"
        rows={5}
        totalChars={1000}
        wsx={{ mt: 3, width: "100%" }}
      />
      <SinglePrice name={`${name}.amount`} />
      <Error error={error} />
    </ColBox>
  );
};

export const WordLimitItem = (props) => {
  const theme = useTheme();
  const form = useFormContext();
  const { schema, name, wsx } = props;

  form.register(`${name}.type`);
  form.setValue(`${name}.type`, schema?.type);

  const Label = (props) => (
    <Typography
      variant="h4"
      sx={{
        width: theme.typography.pxToRem(150),
        borderRadius: theme.typography.pxToRem(4),
        borderWidth: 1,
        borderColor: theme.palette.border.main,
        borderStyle: "solid",
        textAlign: "center",
        paddingTop: theme.typography.pxToRem(7),
        paddingBottom: theme.typography.pxToRem(7),
      }}
    >
      {props.children}
    </Typography>
  );

  return (
    <RowBox sx={{ mb: 2, alignItems: "flex-start", ...wsx }}>
      <InputField name={`${name}.amount`} wsx={{ width: 150, mr: 3 }} />
      <Label>{schema?.name}</Label>
    </RowBox>
  );
};

export const ApplicationForm = (props) => {
  return (
    <ColBox>
      <RowBox>
        <ColBox sx={{ width: 150, mr: 3 }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            State
          </Typography>
          <StatePicker
            name="state"
            placeholder="Select State"
            wsx={{ width: "100%" }}
          />
        </ColBox>
        <ColBox sx={{ width: 150, mr: 3 }}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            City
          </Typography>
          <CityPicker
            name="city"
            placeholder="Select City"
            wsx={{ width: "100%" }}
          />
        </ColBox>

        {/* <CityPicker placeholder="Select City"/> */}
      </RowBox>
      <InputField
        name="affiliation"
        title="Affiliation"
        wsx={{ mt: 3, width: "100%" }}
      />
      <RowBox sx={{ mt: 3 }}>
        <SelectController
          name="affiliationType"
          title="Type"
          options={affliationTypes}
          wsx={{ width: 150, mr: 3 }}
        />
        <SelectController
          name="exposureType"
          title="Exposure Type"
          options={exposureTypes}
          wsx={{ width: 150 }}
        />
      </RowBox>
      <SelectController
        name="letterType"
        title="Letter"
        options={letterTypes}
        wsx={{ width: 150, mt: 3 }}
      />
    </ColBox>
  );
};

/* Components related to Services - END */

/* Components related to Orders - BEGIN */

export const OrderSummary = (props) => {
  const { order } = props;
  const customer = order?.buyer;

  return (
    <ColBox fullWidth center sx={{ my: 3 }}>
      <Typography variant="h5" sx={{ textDecoration: "underline" }}>
        Customer Details
      </Typography>
      <ColBox sx={{ py: 2, px: 0.8 }} fullWidth>
        <Typography>{`Name: ${customer?.firstname} ${customer?.lastname}`}</Typography>
        <Typography>{`Email: ${customer?.email}`}</Typography>
      </ColBox>

      <Divider />
      <Typography variant="h5" sx={{ textDecoration: "underline", mt: 2 }}>
        Billing Information
      </Typography>
      <ColBox fullWidth sx={{ py: 2, px: 0.8 }}>
        <Typography>{`${customer?.firstname} ${customer?.lastname}`}</Typography>
        <Typography>{`${customer?.email}`}</Typography>
      </ColBox>
      <Divider />
      <Button variant="outlined" sx={{ my: 2 }}>
        Resend Invoice
      </Button>
    </ColBox>
  );
};

/* Components related to Orders - END */

/* Components related to Sellers - BEGIN */
export const SellerSummary = (props) => {
  const theme = useTheme();
  const {
    profileUrl,
    fullname,
    username,
    state,
    country,
    role,
    lastSeen,
    createdAt,
    status,
    isVerified,
  } = props?.user;

  console.log(props?.user);

  const Text = styled(Typography)(() => ({
    textAlign: "center",
  }));

  return (
    <ColBox sx={{ px: 1, ...props?.sx }} center fullWidth>
      <ProfileImage variant="free" image={profileUrl} curved={2} />
      {profileUrl && (
        <Typography
          variant="subtitle1"
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={props?.onRemoveImage}
        >
          Change to default profile Image
        </Typography>
      )}
      <Text variant="h3" sx={{ mt: 3 }}>
        {fullname} {isVerified && <Badge />}
      </Text>
      <Text variant="h4" sx={{ color: theme.palette.info.main }}>
        {username}
      </Text>
      <Text variant="h4">{`${state}, ${country}`}</Text>
      <Text
        variant="h5Gray"
        sx={{ textAlign: "center", mt: 1, mb: 2 }}
      >{`Last Seen: ${dateTimeString(lastSeen)}`}</Text>
      <Divider />
      <Box sx={{ py: 2 }}>
        {status === "approved" && (
          <Typography variant="body1">{`Seller since: ${dateString(
            props?.createdAt
          )}`}</Typography>
        )}
        <Typography
          variant="body1"
          sx={{ mt: 0.4 }}
        >{`Member since: ${dateString(createdAt)}`}</Typography>
      </Box>
      <Divider />
      <Button variant="outlined" sx={{ mt: 2 }} onClick={props?.onView}>
        View User Information
      </Button>
      <Button variant="outlined" sx={{ mt: 1 }} onClick={props?.onBadge}>
        {isVerified ? "Remove Verified Badge" : "Assign Verified Badge"}
      </Button>

      {props?.status === "pending" && (
        <ColBox>
          <Button variant="outlined" sx={{ mt: 4 }} onClick={props?.onApprove}>
            Approve
          </Button>
          <Button
            variant="outlined"
            color="warning"
            sx={{ mt: 1 }}
            onClick={props?.onReject}
          >
            Reject
          </Button>
        </ColBox>
      )}

      {props?.status === "approved" && (
        <Button
          variant="outlined"
          color="warning"
          sx={{ mt: 4 }}
          onClick={props?.onSuspend}
        >
          Suspend Seller
        </Button>
      )}

      {props?.status === "suspended" && (
        <Button variant="outlined" sx={{ mt: 4 }} onClick={props?.onSuspend}>
          Unsuspend Seller
        </Button>
      )}
    </ColBox>
  );
};
/* Components related to Sellers - END */

/* Components related to Reviews - BEGIN */

export const ReviewSummary = (props) => {
  const theme = useTheme();
  const { review } = props;
  const customer = review?.buyer;

  const Text = styled(Typography)(() => ({
    textAlign: "center",
  }));

  return (
    <ColBox sx={{ px: 1, ...props?.sx }} center fullWidth>
      <Typography variant="h4" sx={{ my: 3 }}>
        Customer
      </Typography>
      <ProfileImage variant="free" image={customer?.profileUrl} curved={2} />

      <Text variant="h3" sx={{ mt: 3 }}>
        {customer?.fullname}
      </Text>
      <Text variant="h4" sx={{ color: theme.palette.info.main }}>
        {customer?.username}
      </Text>
      <Text
        variant="h4"
        sx={{ mb: 2 }}
      >{`${customer?.state}, ${customer?.country}`}</Text>
      <Divider />
      <Typography variant="body1" sx={{ my: 2 }}>{`Member since: ${dateString(
        customer?.createdAt
      )}`}</Typography>
      <Divider />
      <Button variant="outlined" sx={{ mt: 2 }} onClick={props?.onView}>
        View User Information
      </Button>

      {review?.status === "pending" && (
        <ColBox>
          <Button variant="outlined" sx={{ mt: 4 }} onClick={props?.onApprove}>
            Approve
          </Button>
          <Button
            variant="outlined"
            color="warning"
            sx={{ mt: 1 }}
            onClick={props?.onDelete}
          >
            Delete
          </Button>
        </ColBox>
      )}
    </ColBox>
  );
};

/* Components related to Reviews - BEGIN */

/* Components related to Settings - BEGIN */

export const SettingsSideBar = (props) => {
  const theme = useTheme();
  const items = props?.items;

  const Container = styled("nav")(() => ({
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
  }));

  const StyledList = styled(List)(({ theme }) => ({
    backgroundColor: theme.palette.neutral.main,
    height: "100%",
    boxSizing: "border-box",
  }));

  const StyledListItem = styled(ListItem)(({ isActive, theme }) => ({
    "&:hover": { backgroundColor: theme.palette.info.light },
    backgroundColor: isActive ? theme.palette.info.light : "white",
    paddingTop: 12,
    paddingBottom: 12,
  }));

  return (
    <Container sx={props?.sx}>
      <StyledList>
        {items.map((i) => {
          return (
            <Box key={i.name}>
              <NavLink
                to={i.link}
                key={i.name}
                style={{ textDecoration: "none" }}
              >
                {({ isActive }) => (
                  <StyledListItem isActive={isActive}>
                    <RowBox sx={{ width: "100%", justifyContent: "center" }}>
                      <Typography
                        variant="h4Black"
                        style={{ textAlign: "center" }}
                      >
                        {i.name}
                      </Typography>
                    </RowBox>
                  </StyledListItem>
                )}
              </NavLink>

              <Divider />
            </Box>
          );
        })}
      </StyledList>
    </Container>
  );
};

/* Components related to Settings - END */

const languages = [
  "Arabic",
  "Chinese",
  "English",
  "French",
  "German",
  "Gujarati",
  "Hindi",
  "Italian",
  "Japanese",
  "Javanese",
  "Korean",
  "Marathi",
  "Persian",
  "Portuguese",
  "Punjabi",
  "Russian",
  "Spanish",
  "Tamil",
  "Telugu",
  "Turkish",
  "Urdu",
  "Vietnames",
];
