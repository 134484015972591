import React, { useContext, useEffect, useState } from "react";
import { Button, styled, Typography, Grid, Input } from "@mui/material";

import { useParams } from "react-router";

//API related imports
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { APIContext } from "../services/api-provider";
import { toast } from "react-toastify";
import { useAlert } from "../components/alert";

//Form Related
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userSchema } from "../utils/validation";
import {
  RowBox,
  ColBox,
  InputField,
  ProgressIndicator,
  SelectController,
  ProfessionPick,
  CountryPicker,
  StatePicker,
  ProfessionPicker,
  GenderPicker
} from "../components/ui";

import { getDirtyFields } from "../utils/utils";

const LabelGrid = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const ContentGrid = styled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
}));

const Row = (props) => (
  <>
    <LabelGrid item md={2} sm={2} xs={2} sx={{ mb: 2 }}>
      {props?.children?.[0]}
    </LabelGrid>
    <ContentGrid item md={4} sm={4} xs={10} sx={{ mb: 2 }}>
      {props?.children?.[1]}
    </ContentGrid>
    <LabelGrid item md={2} sm={2} xs={2}>
      {props?.children?.[2]}
    </LabelGrid>
    <ContentGrid item md={4} sm={4} xs={10}>
      {props?.children?.[3]}
    </ContentGrid>
  </>
);

export default function UserInfo() {
  const { userId } = useParams();

  const queryClient = useQueryClient();
  const { fetchUser, updateUser } = useContext(APIContext);
  const alert = useAlert()

  const { isLoading, data: user } = useQuery(
    ["user", { id: userId }],
    () => fetchUser(userId),
    {
      select: (data) => data?.data,
    }
  );

  const updateMutation = useMutation((data) => updateUser(userId, data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user", { id: userId }]);
      toast.success("Successfully saved changes");
    },
  });

  const methods = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: {
      ...user,
      privacy: user?.isProfilePrivate ? "Private" : "Public",
    },
  });

  useEffect(() => {
    /* Some timing issue, form values are not loading after page reload. */
    methods.reset({
      ...user,
      privacy: user?.isProfilePrivate ? "Private" : "Public",
    });
  }, [user]);

  const dirtyFields = methods.formState.dirtyFields;
  const submitHandler = (values) => {
    const data = getDirtyFields(values, dirtyFields);
    if (data) {
      alert("Confirmation", "Are you sure to update the user profile?", () => {
        updateMutation.mutate(data);
      })
    } else {
      toast.error("No changes to save");
    }
  };

  const Label = styled(Typography)(() => ({
    textAlign: "end",
    marginRight: 20,
    justifySelf: "center",
  }));

  const inputStyles = {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      paddingTop: 1,
      paddingBottom: 1,
    },
  };

  return (
    <ColBox fullWidth>
      <FormProvider {...methods}>
        <Grid container sx={{ my: 2 }} spacing={1}>
          <Row>
            <Label>First Name</Label>
            <InputField name="firstname" wsx={inputStyles} />
            <Label>Last Name</Label>
            <InputField name="lastname" wsx={inputStyles} />
          </Row>
          <Row>
            <Label>User Name</Label>
            <InputField name="username" wsx={inputStyles} />
          </Row>
          <Row>
            <Label>Gender</Label>
            <GenderPicker name="gender" fullWidth />
          </Row>
          <Row>
            <Label>Email Id</Label>
            <InputField name="email" wsx={inputStyles} />
          </Row>
          <Row>
            <Label>Profession</Label>
            <ProfessionPicker name="profession" />
          </Row>
          <Row>
            <Label>Country</Label>
            <CountryPicker variant="outlined" fullWidth />
            <Label>State</Label>
            <StatePicker variant="outlined" fullWidth />
          </Row>
          <Row>
            <Label>User role</Label>
            <SelectController
              name="userRole"
              options={["freelancer", "professional"]}
              variant="outlined"
              placeholder="Select User Role"
              fullWidth
            />
            <Label>Privacy</Label>
            <SelectController
              name="privacy"
              options={["Public", "Private"]}
              fullWidth
            />
          </Row>
        </Grid>
        <Button
          variant="contained"
          sx={{ mt: 8, mb: 6, px: 5, alignSelf: "center" }}
          onClick={methods.handleSubmit(submitHandler)}
        >
          Save Changes
        </Button>
      </FormProvider>
      {(isLoading || updateMutation.isLoading) && <ProgressIndicator />}
    </ColBox>
  );
}
